<template>
  <v-icon v-if="value" color="success" right>mdi-check-circle</v-icon>
  <v-icon right v-else>mdi-alert-circle</v-icon>
</template>

<script>
export default {
  name: "BooleanIcon",
  props: {value: {type: Boolean, default: false}}
};
</script>

<style scoped>

</style>