import {
  getState,
  getActions,
  getMutations,
  getGetters,
  http
} from "@admin100/djvue";

const actions = getActions("account/profiles");
const state = getState();
const getters = getGetters();
const mutations = getMutations();

actions.emailComplianceNotifications = (state, selected ) => {
  return http
    .post("/account/admin/profiles/email-compliance-notifications/", {
      selected: selected
    })
    .then(response => {
      return Promise.resolve(response);
    })
    .catch(e => {
      return Promise.reject(e);
    });
};

const profile = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};



export default profile;
