import {
  getActions,
  getGetters,
  getMutations,
  getState
} from "@admin100/djvue";

const actions = getActions("inspection/disciplinary-logs");
const state = getState();
const getters = getGetters();
const mutations = getMutations();


const disciplinaryLog = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};



export default disciplinaryLog;
