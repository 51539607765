<template>
  <v-dialog
      v-model="open"
      persistent
      width="300"
  >
    <v-card
        class="pa-4"
        color="primary"
        dark
    >
      <v-card-text>
        {{ message }}
        <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "LoadingDialog",
  props: {message: {type: String, default: "Loading..."}},
  data(){
    return {
      open: true
    };
  }
};
</script>

<style scoped>

</style>
