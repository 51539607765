<template>
  <responsive-dialog ref="responsive_dialog" :dialog-title="formTitle"
                     :show-actions="false">
    <v-row>
      <v-col>
        <v-alert
            outlined
            border="left"
            color="primary"
            type="info"
            class="mt-3"
        >
          {{ alertMessage }}

        </v-alert>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn text @click="refresh">
          <v-icon left>mdi-refresh</v-icon>
          Refresh
        </v-btn>
      </v-col>
    </v-row>
    <v-progress-linear v-if="isLoading" indeterminate/>
    <v-container>
      <media-field @uploadPercentage="onUploadPercentage" @updated="onMediaUpdated">
        <media-drop-zone class="text-center">
          Drag and drop files or click here to add media.
        </media-drop-zone>
      </media-field>
    </v-container>
    <v-list dense>
      <v-list-item v-if="isUploading">
        <v-progress-linear :value="uploadPercentage" :indeterminate="uploadPercentage === 100"></v-progress-linear>
      </v-list-item>
      <v-data-iterator
          :items="items"
          :loading="isLoading"
          :footer-props="{
          'items-per-page-options': itemsPerPageOptions
        }"
          :options.sync="tableFilters"
          :server-items-length="totalItems"
          @update:options="updateFilters"
          disable-filtering
          disable-sort
          class="pa-2"
      >
        <v-list-item v-for="item in items" :key="item.id" link @click.stop="onClickItem(item)">
          <v-list-item-avatar tile>
            <v-img v-if="isImageFile(item.filename)" size="50" :src="item.file">
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular width="3" indeterminate color="primary"></v-progress-circular>
                </v-row>
              </template>
            </v-img>
            <v-icon v-else large>{{ getMediaIcon(item.filename) }}</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title> {{ item.description }}</v-list-item-title>
            <v-list-item-subtitle> {{ item.size / 1000 }} KB</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-menu bottom left>
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on" @click.native.stop>
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item link :href="item.file" target="_blank">
                  <v-list-item-title>
                    <v-icon left>mdi-eye</v-icon>
                    View
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click.stop="onClickDeleteButton(item)">
                  <v-list-item-title>
                    <v-icon left>mdi-delete</v-icon>
                    Delete
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-list-item-action>
        </v-list-item>
      </v-data-iterator>
    </v-list>

    <delete-dialog
        object-name="media"
        :open="deleteConfirmOpen"
        @cancelled="onConfirmCancelled"
        @deleted="onConfirmDeleteDeleted"
    />
    <media-form></media-form>
  </responsive-dialog>
</template>

<script>

import {ADD_MESSAGE} from "@/store/mutation-types";
import MediaForm from "@/components/media/MediaForm.vue";
import MediaField from "@/components/media/MediaField.vue";
import MediaDropZone from "@/components/media/MediaDropZone.vue";
import DeleteDialog from "@/components/DeleteDialog.vue";
import ResponsiveDialog from "@/components/ResponsiveDialog.vue";
import DateMixin from "@/mixins/date";
import dataTableMixin from "@/mixins/dataTable";
import MediaMixin from "@/mixins/media";


export default {
  name: "MediaPopup",

  mixins: [DateMixin, dataTableMixin, MediaMixin],

  components: {DeleteDialog, MediaForm, ResponsiveDialog, MediaField, MediaDropZone},

  props: {isSubmission: {type: Boolean, default: false, required: false}},

  data() {
    return {
      idDeleteItem: -1,
      deleteConfirmOpen: false,
      newForm: {},
      uploadPercentage: 0,
      tableOptions: {
        itemsPerPage: 5
      }
    };
  },

  computed: {
    isUploading() {
      return this.uploadPercentage > 0;
    },
    items() {
      return this.$store.state.media.list;
    },
    user() {
      return this.$store.state.auth.user;
    },
    isLoading() {
      return this.$store.getters["media/loading"]("list");
    },
    formTitle() {
      return this.isSubmission ? "Select a document" : "Shared Documents";
    },
    alertMessage() {
      return this.isSubmission ? "Please click on a document to attach it to this submission" : "These are your SHARED documents. This section allows you to share documents with other branches and they can also be used in any of your submissions. Please note that these documents are not submitted to the MRA unless you attach them to the document section of your submissions.";
    }
  },

  methods: {
    async fetchItems() {
      try {
        await this.$store.dispatch("media/list");
      } catch (error) {
        this.$store.commit(`theme/${ADD_MESSAGE}`, {type: "error", text: "Unable to fetch media."});
      }
    },
    onClickItem(item) {
      this.$emit("selected", item);
    },
    onClickDeleteButton(item) {
      this.deleteConfirmOpen = true;
      this.idDeleteItem = item.id;
    },
    onUploadPercentage(percent) {
      this.uploadPercentage = percent;
    },
    onMediaUpdated() {
      this.uploadPercentage = 0;
      this.fetchItems();
    },
    onConfirmCancelled() {
      this.idDeleteItem = -1;
      this.deleteConfirmOpen = false;
    },
    async onConfirmDeleteDeleted() {
      await this.sendDelete(this.idDeleteItem);
      await this.fetchItems();
      this.idDeleteItem = -1;
      this.deleteConfirmOpen = false;
      this.showSnackbarSuccess();
    },
    async sendDelete(id) {
      await this.$store.dispatch("media/delete", id);
    },
    showSnackbarSuccess() {
      this.$store.commit(`theme/${ADD_MESSAGE}`, {type: "success", text: "Success"});
    },
    close() {
      this.$refs.responsive_dialog.close();
    },
    async onClickEdit(item) {
      await this.$store.dispatch("media/detail", item.id);
    },
    open() {
      this.$refs.responsive_dialog.open();
    }

  }
};
</script>
