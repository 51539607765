<template>
  <v-container>
    <v-card>
      <change-notification-form :notification-id="$route.params.id"/>
    </v-card>
  </v-container>
</template>

<script>
import ChangeNotificationForm from "../../components/change-notification/ChangeNotificationForm";

export default {
  name: "ChangeNotificationDetail",
  components: {ChangeNotificationForm},
};
</script>

<style scoped>

</style>
