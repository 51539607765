<template>
  <v-navigation-drawer v-model="drawer" app>
    <template v-slot:prepend>
      <v-list-item>
        <v-list-item-avatar tile>
          <v-img :src="require('@/assets/logo.webp')" alt="mra"></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>MRA Admin</v-list-item-title>
        </v-list-item-content>
        <v-list-item-action></v-list-item-action>
      </v-list-item>
    </template>

    <v-divider></v-divider>

    <v-list dense>
      <!-- MENU ITEM: HOME -->
      <v-list-item link to="/admin" color="primary">
        <v-list-item-icon>
          <v-icon>mdi-account-multiple</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Members</v-list-item-title>
      </v-list-item>

      <!-- MENU ITEM: MEMBERS -->
      <v-list-item link to="/admin/submissions" color="primary">
        <v-list-item-icon>
          <v-icon>mdi-file-document-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Inspections</v-list-item-title>
      </v-list-item>

      <!-- MENU ITEM: CHANGE NOTIFICATIONS -->
      <v-list-item link to="/admin/change-notifications" color="primary">
        <v-list-item-icon>
          <v-icon>mdi-information-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Change Notifications</v-list-item-title>
      </v-list-item>

      <!-- MENU ITEM: DISCIPLINARY LOGS -->
      <v-list-item link to="/admin/disciplinary-logs" color="primary">
        <v-list-item-icon>
          <v-icon>mdi-gavel</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Disciplinary Logs</v-list-item-title>
      </v-list-item>

      <!-- MENU ITEM: SAPS REPORTING -->
      <v-list-item link to="/admin/saps-reports" color="primary">
        <v-list-item-icon>
          <v-icon>mdi-police-badge</v-icon>
        </v-list-item-icon>
        <v-list-item-title>SAPS Reporting</v-list-item-title>
      </v-list-item>
    </v-list>


    <!-- NEW INSPECTION BUTTON -->
    <v-list-item v-if="$route.name === 'Submissions'">
      <v-btn
          @click="$refs.new_submission_modal.open()"
          color="primary"
          small
          outlined
          block
      >
        <v-icon left>
          mdi-plus-circle-outline
        </v-icon>
        New Inspection
      </v-btn>
      <submission-form-modal ref="new_submission_modal"/>
    </v-list-item>

    <!-- NEW MEMBERS BUTTON -->
    <v-list-item v-if="$route.name === 'Members'">
      <v-btn to="/admin/members/edit/new/" color="primary" small outlined block>
        <v-icon left>
          mdi-plus-circle-outline
        </v-icon>
        New Member
      </v-btn>
    </v-list-item>

    <!-- MENU ITEM: SUBMISSION DETAIL -->
    <submission-menu v-if="submissionDetail" :submission="submissionDetail"/>

    <!-- MENU ITEM: MEMBER FILTERS-->
    <profile-filter v-if="$route.name === 'Members'" model-name="profile"/>

    <!-- MENU ITEM: SUBMISSION FILTERS-->
    <submission-filter
        v-if="$route.name === 'Submissions'"
        model-name="submission"
    />
  </v-navigation-drawer>
</template>

<script>
import eventHub from "@/event-hub";
import {SET_DRAWER} from "@/store/mutation-types";
import SubmissionMenu from "@/components/submission/SubmissionMenu";
import ProfileFilter from "@/components/profile/ProfileFilter";
import SubmissionFilter from "@/components/submission/SubmissionFilter";
import SubmissionFormModal from "@/components/submission/SubmissionFormModal";

export default {
  name: "AdminMenu",

  components: {
    SubmissionFormModal,
    SubmissionFilter,
    ProfileFilter,
    SubmissionMenu
  },

  data() {
    return {
      submissionDetail: null
    };
  },

  created() {
    eventHub.$on("toggle_drawer", this.toggleDrawer);
    eventHub.$on("Menu.setSubmissionDetail", this.setSubmissionDetail);
  },

  beforeDestroy() {
    eventHub.$off("toggle_drawer");
    eventHub.$off("Menu.setSubmissionDetail");
  },

  computed: {
    mini() {
      return this.$store.state.theme.miniMenu;
    },

    user() {
      return this.$store.state.auth.user;
    },

    route() {
      return this.$route.path;
    },

    drawer: {
      get() {
        return this.$store.state.theme.drawer;
      },
      set(value) {
        this.$store.commit(`theme/${SET_DRAWER}`, value);
      }
    }
  },

  methods: {
    toggleDrawer() {
      this.$store.commit(`theme/${SET_DRAWER}`, !this.drawer);
    },
    setSubmissionDetail(submission) {
      this.submissionDetail = submission;
    }
  }
};
</script>

<style scoped></style>
