<template>
  <v-dialog v-model="isOpen" max-width="500px" persistent>
    <v-card tile>
      <v-card-title class="headline">
        <slot name="title">
          <v-icon left>mdi-alert-circle-outline</v-icon> Attention
        </slot>
      </v-card-title>
      <v-card-text>
        <slot>

        </slot>
      </v-card-text>
      <v-card-actions>
        <v-btn text @click="isOpen = false">
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "MessageDialog",
  data() {
    return {
      isOpen: false
    };
  },
  methods: {
    open() {
      this.isOpen = true;
    },
    close() {
      this.isOpen = false;
    }
  }
};
</script>

<style scoped>

</style>