const exampleDocumentsMixin = {
    data() {
        return {
            examples: {
                "Processing Area": "/img/examples/processing_area.jpg",
                "Gate leading into the premises": "/img/examples/gate_leading_into_premises.jpg",
                "Office Area": "/img/examples/office_area.jpg",
                "Storage Area": "/img/examples/storage_area.jpg",
                "Weigh Area": "/img/examples/weigh_area.jpg"
            }
        };
    },
    methods: {
        getExampleDoc(description) {
            if (this.examples[description])
                return window.location.protocol + "//" + window.location.host + this.examples[description];
            return null;
        }
    }

};

export default exampleDocumentsMixin;
