<template>
  <v-container>
    <!-- MESSAGES -->
    <right-flyout router-param="submission_id" />

    <one-column-row v-if="this.item">
      <v-stepper v-model="step" style="overflow: visible;">
        <v-stepper-header class="sticky-top" style="background-color: white">
          <v-stepper-step
            :step="1"
            :editable="steps[1].editable"
            :complete="steps[1].complete"
            :rules="[() => !stepsWithErrors.includes(1)]"
          >
            Check List
            <small
              >{{ formatPercentage(item.questions_completed * 100) }} %</small
            >
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step
            :step="2"
            :editable="steps[2].editable || this.maxStepCompleted > 2"
            :complete="steps[2].complete"
            :rules="[() => !stepsWithErrors.includes(2)]"
          >
            Documents
            <small
              >{{ formatPercentage(item.documents_uploaded * 100) }} %</small
            >
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step
            :step="3"
            :editable="steps[3].editable"
            :complete="steps[3].complete"
          >
            Regulatory
            <small v-if="stepsCompleted.includes(3)">Completed</small>
            <small v-else>Incomplete</small>
          </v-stepper-step>

          <v-stepper-step
            v-if="user.is_staff"
            :step="4"
            :editable="steps[4].editable"
            :complete="steps[4].complete"
          >
            Office Use
            <small v-if="officeUseComplete">Completed</small>
            <small v-else>Incomplete</small>
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content :step="1">
            <component
              v-if="step === 1"
              :submission-id="submissionId"
              ref="question_sheet"
              :read-only="readOnly"
              :is="questionSheet"
              class="overflow-y-auto stepper-scrollable"
            />
          </v-stepper-content>

          <v-stepper-content :step="2">
            <document-sheet
              v-if="step === 2"
              :submission="item"
              ref="document_sheet"
              class="overflow-y-auto stepper-scrollable"
              :read-only="readOnly"
            />
          </v-stepper-content>

          <v-stepper-content :step="3">
            <declaration-sheet
              v-if="step === 3"
              :submission="item"
              ref="declaration_sheet"
              class="overflow-y-auto stepper-scrollable"
              :read-only="readOnly"
            />
          </v-stepper-content>

          <v-stepper-content v-if="user.is_staff" :step="4">
            <office-use-sheet
              v-if="step === 4"
              :submission="item"
              ref="office_use_sheet"
              class="overflow-y-auto stepper-scrollable"
              :read-only="readOnly"
            />
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>

      <v-card tile class="sticky-bottom">
        <v-divider />
        <v-card-actions>
          <v-btn
            v-if="step === 1 && !readOnly"
            color="info"
            tile
            @click="saveQuestions"
            >Save Check list
          </v-btn>
          <v-btn
            v-if="user.is_staff"
            color="primary"
            @click="reopenInspection"
            tile
            :disabled="!canBeReopened"
            :loading="loading"
          >
            Re-open inspection
          </v-btn>

          <v-spacer />

          <v-btn
            v-if="!user.is_staff"
            :loading="loading"
            color="info"
            tile
            :disabled="!canSubmit"
            @click="$refs.confirm_submission.open()"
          >
            Submit Inspection
          </v-btn>
          <v-btn
            v-else
            color="success"
            @click="completeInspection"
            tile
            :disabled="item.status !== 'submitted' || !officeUseComplete"
            :loading="loading"
          >
            Complete Inspection
          </v-btn>
        </v-card-actions>
      </v-card>
    </one-column-row>

    <confirm-dialog ref="confirm_submission" @confirmed="submitInspection">
      <template v-slot:title>
        Submit inspection?
      </template>
      Your inspection is now ready to submit. You can no longer edit this
      inspection once it is submitted. Click YES to submit or NO to continue
      editing this submission.
    </confirm-dialog>

    <confirm-dialog ref="confirm_reopen_message" @confirmed="openMessages">
      <template v-slot:title>
        Send user message?
      </template>
      This submission has been re-opened, the user can now edit the submission.
      Would you like to send a message telling the user why this was reopened?
    </confirm-dialog>

    <message-dialog ref="incomplete_checklist">
      Your checklist is not yet complete. Your progress has been saved and you
      can continue at any time. Any questions that require your attention have
      been highlighted in red. You cannot advance to the next step (document
      upload) until this checklist is complete.
    </message-dialog>

    <message-dialog ref="is_not_first_submission">
      <p>
        Our records show that you have submitted an inspection in the past. For
        your convenience we have populated this inspection with data from your
        previous inspection.<br />
        Please double check and update any data or documents that have changed
        since your last submission before you submit.
      </p>
      <p>
        If you have submitted any 604 forms to the DSO since your last inspection, please
        <router-link :to="{ name: 'ChangeNotificationIndex' }"
          >update your MRA information.</router-link
        >
      </p>
    </message-dialog>
  </v-container>
</template>

<script>
import { ADD_MESSAGE, SET_PAGE_TITLE } from "@/store/mutation-types";
import DocumentSheet from "@/components/submission/DocumentSheet";
import DeclarationSheet from "@/components/submission/DeclarationSheet";
import eventHub from "@/event-hub";
import OfficeUseSheet from "@/components/submission/OfficeUseSheet";
import RightFlyout from "@/components/RightFlyout";
import routePrefixMixin from "@/mixins/routePrefix";
import ConfirmDialog from "@/components/ConfirmDialog";
import statusMixin from "@/mixins/status";
import downloadMixin from "@/mixins/download";
import numberMixin from "@/mixins/numbers";
import OneColumnRow from "@/layouts/components/OneColumnRow";
import MessageDialog from "@/components/MessageDialog";
import { ADD_REQUEST, REMOVE_REQUEST } from "@/store/mutation-types";
import DealerRecyclerQuestionSheet from "@/components/submission/DealerRecyclerQuestionSheet";
import TraderQuestionSheet from "@/components/submission/TraderQuestionSheet";

function getSteps() {
  return {
    1: {
      complete: false,
      editable: true
    },
    2: {
      complete: false,
      editable: false
    },
    3: {
      complete: false,
      editable: false
    },
    4: {
      complete: false,
      editable: false
    }
  };
}

export default {
  name: "SubmissionDetail",

  data() {
    return {
      item: {},
      loadingMessage: "Loading inspection form...",
      step: 0,
      steps: getSteps()
    };
  },

  components: {
    MessageDialog,
    ConfirmDialog,
    RightFlyout,
    OfficeUseSheet,
    DeclarationSheet,
    DocumentSheet,
    OneColumnRow
  },

  mixins: [routePrefixMixin, statusMixin, downloadMixin, numberMixin],

  created() {
    eventHub.$on("DeclarationSheet.updated", this.updateDeclaration);
    eventHub.$on("DocumentSheet.updated", this.fetchItem);
    eventHub.$on("QuestionSheet.updated", this.respondToQuestionUpdate);
    eventHub.$on("MessageList.updated", this.updateMessageCount);
    eventHub.$on("QuestionSheet.ready", this.updateQuestionErrors);
    eventHub.$on("OfficeUseSheet.updated", this.updateOfficeUse);
  },

  beforeDestroy() {
    eventHub.$emit("Menu.setSubmissionDetail", null);
    eventHub.$off("DeclarationSheet.updated");
    eventHub.$off("PDFViewer.accepted");
    eventHub.$off("DocumentSheet.updated");
    eventHub.$off("QuestionSheet.updated");
    eventHub.$off("MessageList.updated");
    eventHub.$off("Question.mounted");
    eventHub.$off("OfficeUseSheet.updated");
  },

  mounted() {
    this.$store.commit(`theme/${SET_PAGE_TITLE}`, "");
    this.fetchItem().then(response => {
      this.$store.commit(
        `theme/${SET_PAGE_TITLE}`,
        `${response.data.company} | ${response.data.inspection_period}`
      );

      this.displayNotFirstSubmissionDialog();
    });
  },

  computed: {
    loading() {
      return (
        this.step !== 2 &&
        (this.$store.getters["submission/loading"]("detail") ||
          this.$store.getters["submission/loading"]("update"))
      );
    },

    submissionId() {
      return Number(this.$route.params.id);
    },

    stepsWithErrors() {
      let steps = [];
      for (let i = 1; i < this.numberOfSteps; i++) {
        if (this.stepHasError(i)) steps.push(i);
      }
      return steps;
    },

    stepsStartedAndIncomplete() {
      let steps = [];
      if (
        Number(this.item.questions_completed) < 1 &&
        Number(this.item.questions_completed) > 0
      )
        steps.push(1);
      if (
        Number(this.item.documents_uploaded) < 1 &&
        Number(this.item.documents_uploaded) > 0 &&
        this.maxStepCompleted > 1
      )
        steps.push(2);
      if (
        0 < Number(this.item.regulation_requirements_completed) &&
        Number(this.item.regulation_requirements_completed) < 1
      )
        steps.push(3);
      return steps;
    },

    stepsCompleted() {
      let steps = [];

      if (this.item.questions_completed >= 1) steps.push(1);
      if (this.item.documents_uploaded >= 1) steps.push(2);
      if (this.item.regulation_requirements_completed >= 1) steps.push(3);

      return steps;
    },

    maxStepCompleted() {
      return Math.max(...this.stepsCompleted);
    },

    minStepWithError() {
      return Math.min(...this.stepsWithErrors);
    },

    numberOfSteps() {
      return this.user.is_staff ? 4 : 3;
    },

    nextStep() {
      const nextStep = this.maxStepCompleted + 1;
      return nextStep > this.numberOfSteps ? this.numberOfSteps : nextStep;
    },

    canSubmit() {
      return (
        !this.readOnly && this.stepsCompleted.length === this.numberOfSteps
      );
    },

    readOnly() {
      return (
        this.item.locked ||
        this.loading ||
        this.user.is_staff ||
        this.user.is_saps
      );
    },

    user() {
      return this.$store.state.auth.user;
    },

    messagesRoute() {
      return `${this.routePrefix}/submission/${this.submissionId}/messages`;
    },

    officeUseComplete() {
      return Number(this.item.office_use_completed) >= 1;
    },

    canBeReopened() {
      return (
        this.user.is_staff &&
        ["submitted", "complete"].includes(this.item.status)
      );
    },

    questionSheet() {
      return this.item.classification === "trader"
        ? TraderQuestionSheet
        : DealerRecyclerQuestionSheet;
    }
  },

  methods: {
    stepHasError(step) {
      return this.stepsStartedAndIncomplete.includes(step);
    },

    async fetchItem() {
      try {
        const response = await this.$store.dispatch(
          "submission/detail",
          this.submissionId
        );
        this.item = response.data;
        eventHub.$emit("Menu.setSubmissionDetail", response.data);
        eventHub.$emit("Submission.updated", response.data);
        this.loadSteps();
        return Promise.resolve(response);
      } catch (e) {
        this.$store.commit(`theme/${ADD_MESSAGE}`, {
          type: "error",
          text: "There was an error loading this submission"
        });
        return Promise.reject(e);
      }
    },

    loadSteps() {
      this.steps = getSteps();

      if (this.stepsCompleted.includes(1)) {
        this.steps[1]["complete"] = true;
        this.steps[2]["editable"] = true;
        this.step = 2;
      }

      if (this.stepsCompleted.includes(2) && this.stepsCompleted.includes(1)) {
        this.steps[2]["complete"] = true;
        this.steps[3]["editable"] = true;
        this.step = 3;
      }

      if (
        this.stepsCompleted.includes(3) &&
        this.stepsCompleted.includes(2) &&
        this.stepsCompleted.includes(1)
      ) {
        this.steps[3]["complete"] = true;
        this.steps[this.numberOfSteps]["editable"] = true;
        this.step = this.numberOfSteps;
      }

      const maxStep =
        this.minStepWithError <= this.numberOfSteps
          ? this.minStepWithError
          : this.nextStep;

      this.step = this.step === 0 ? 1 : maxStep;
    },

    async respondToQuestionUpdate() {
      await this.fetchItem();
      this.displayIncompleteQuestionDialog();
    },

    displayIncompleteQuestionDialog() {
      if (
        0 < this.item.questions_completed &&
        this.item.questions_completed < 1 &&
        this.step === 1
      ) {
        this.$refs.incomplete_checklist.open();
      }
    },

    displayNotFirstSubmissionDialog() {
      if (
        !this.item.is_first_submission &&
        !this.item.first_submitted_on &&
        !this.readOnly
      )
        this.$refs.is_not_first_submission.open();
    },

    async update(value) {
      try {
        const response = await this.$store.dispatch("submission/update", {
          id: this.submissionId,
          data: value
        });
        this.item = response.data;
        this.loadSteps();
        this.updateQuestionErrors();
        this.$store.commit(`theme/${ADD_MESSAGE}`, {
          type: "success",
          text: "This submission has been successfully updated."
        });
        eventHub.$emit("Submission.updated", response.data);
        if (this.canSubmit) {
          this.$refs.confirm_submission.open();
        }
      } catch (e) {
        this.$store.commit(`theme/${ADD_MESSAGE}`, {
          type: "error",
          text: "Submission update failed"
        });
        eventHub.$emit("Submission.updated", this.item);
      }
    },

    async updateStatus(status) {
      this.$store.commit(`submission/${ADD_REQUEST}`, "update");
      try {
        const response = await this.$http.patch(
          `/inspection/submissions/${this.submissionId}/status/`,
          { status: status }
        );
        this.item.status = response.data.status;
        return Promise.resolve(response);
      } catch (e) {
        this.$store.commit(`theme/${ADD_MESSAGE}`, {
          text: "Status update failed",
          type: "error"
        });
        return Promise.reject(e);
      } finally {
        this.$store.commit(`submission/${REMOVE_REQUEST}`, "update");
      }
    },

    updateDeclaration(data) {
      this.update(data);
    },

    updateOfficeUse(data) {
      let stepsComplete = 0;
      const stepsRequired = 2;
      if (data.fees_up_to_date) stepsComplete++;
      if (data.approved) stepsComplete++;
      this.item.office_use_completed =
        stepsComplete > 0 ? stepsComplete / stepsRequired : 0;
    },

    reopenInspection() {
      this.updateStatus("reopened").then(() => {
        this.$refs.confirm_reopen_message.open();
      });
    },

    completeInspection() {
      this.updateStatus("complete");
    },

    submitInspection() {
      this.update({ status: "submitted" });
    },

    saveQuestions() {
      eventHub.$emit("Submission.saveQuestions");
    },

    saveOfficeUse() {
      eventHub.$emit("Submission.save_office_use");
    },

    updateMessageCount(messages) {
      this.item.messages_received = messages.length;
    },

    openMessages() {
      this.$router.push(this.messagesRoute);
    },

    updateQuestionErrors() {
      if (this.item) {
        eventHub.$emit("Submission.updateQuestionErrors", this.item);
      }
    }
  }
};
</script>

<style scoped>
.v-stepper {
  border-radius: 0 !important;
}

.sticky-top {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 64px;
  z-index: 1;
}

.sticky-bottom {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  bottom: 0;
  z-index: 1;
}
</style>
