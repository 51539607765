import {getState, getActions, getMutations, getGetters} from "@admin100/djvue";

const actions = getActions("/media");
const state = getState();
const getters = getGetters();
const mutations = getMutations();

const media = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};

export default media;
