<template>
  <div>
    <v-form @submit.prevent="onFormSubmit()">
      <v-card-title
        >Disciplinary Action | REF: {{ form.reference_no }}
      </v-card-title>
      <v-card-subtitle v-if="form.user.profile">
        {{ form.user.profile.company }}
      </v-card-subtitle>
      <v-card-text>
        <v-progress-linear
          v-if="uploadPercentage > 0 || loading"
          :value="uploadPercentage"
          :indeterminate="uploadPercentage === 100 || loading"
        ></v-progress-linear>

        <v-textarea
          class="mt-5"
          v-model="form.description"
          label="Description"
          placeholder=""
          aria-required="true"
          :error-messages="errors.description"
          filled
          shaped
        ></v-textarea>
      </v-card-text>
      <v-card-title>
        Supporting documents
      </v-card-title>
      <v-card-text>
        <media-field @updated="addMedia" @uploadPercentage="onUploadPercentage">
          <media-drop-zone class="" style="min-height: 200px;" v-if="!readOnly">
            <v-icon x-large>mdi-cloud-upload</v-icon>
            <p class="caption">Click here or drag a file to upload</p>
          </media-drop-zone>
          <v-list dense>
            <v-list-item v-if="loading">
              <v-progress-linear
                :value="uploadPercentage"
                :indeterminate="uploadPercentage === 100"
              ></v-progress-linear>
            </v-list-item>
            <v-list-item
              v-for="item in form.documents"
              @click.stop="openImage(item.file)"
              :key="item.id"
              target="_blank"
            >
              <v-list-item-avatar tile>
                <v-img
                  v-if="isImageFile(item.filename)"
                  size="50"
                  :src="item.file"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        width="3"
                        indeterminate
                        color="primary"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
                <v-icon v-else large>{{ getMediaIcon(item.filename) }}</v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title> {{ item.filename }}</v-list-item-title>
                <v-list-item-subtitle>
                  {{ item.size / 1000 }} KB
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action @click.stop>
                <v-btn
                  v-if="!readOnly"
                  @click.stop="removeDocument(item)"
                  text
                  title="Remove Document/Image"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </media-field>
        <a
          v-if="!readOnly"
          color="primary"
          @click.prevent="$refs.media_popup.open()"
        >
          Select a document from your 'shared documents'
        </a>
      </v-card-text>
      <media-popup
        ref="media_popup"
        is-submission
        model-name="media"
        @selected="addMedia"
      />
    </v-form>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        v-if="!readOnly"
        color="info"
        block
        :loading="loading"
        x-large
        @click="onFormSubmit"
      >
        SAVE
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import {ADD_MESSAGE} from "@/store/mutation-types";
import MediaField from "../media/MediaField";
import MediaDropZone from "../media/MediaDropZone";
import MediaPopup from "@/components/media/MediaPopup.vue";
import downloadMixin from "../../mixins/download";
import mediaMixin from "../../mixins/media";
import listMixin from "../../mixins/list";
import eventHub from "../../event-hub";

export default {
  name: "DisciplinaryLogForm",
  components: { MediaDropZone, MediaField, MediaPopup },
  props: ["logId"],
  mixins: [downloadMixin, mediaMixin, listMixin],
  data() {
    return {
      loading: false,
      form: {
        description: null,
        documents: [],
        user: {},
        created_by: {}
      },
      uploadPercentage: 0,
      errors: {},
      branches: [],
      provinceChoices: require("@/data/provinces.json"),
      classificationChoices: require("@/data/classification.json")
    };
  },

  async mounted() {
    await this.fetchItem();
  },

  computed: {
    user() {
      return this.$store.state.auth.user;
    },
    url() {
      return `/inspection/disciplinary-logs/${this.logId}/`;
    },
    documentUrl() {
      return `${this.url}documents/`;
    },
    readOnly() {
      return !this.user.is_staff;
    },
    isApproved() {
      return this.form.status === "complete";
    }
  },

  methods: {
    fetchItem() {
      this.loading = true;
      this.$http
        .get(this.url)
        .then(response => {
          this.form = response.data;
        })
        .catch(() => {
          this.$store.commit(`theme/${ADD_MESSAGE}`, {
            type: "error",
            text: "Unable to fetch data"
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },

    async onFormSubmit() {
      this.loading = true;
      try {
        const response = await this.$http.put(this.url, this.form);
        this.form = response.data;
        this.errors = [];
        this.$store.commit(`theme/${ADD_MESSAGE}`, {
          type: "success",
          text: "Saved"
        });

        await this.$router.back();
      } catch (error) {
        this.errors = error.response.data;
        this.$store.commit(`theme/${ADD_MESSAGE}`, {
          type: "error",
          text: "Save failed."
        });
      } finally {
        this.loading = false;
      }
    },

    async saveDocuments() {
      eventHub.$emit("DocumentMedia.loading", true);
      this.uploadPercentage = 0;
      try {
        await this.$http.patch(this.documentUrl, this.form);
        eventHub.$emit("DocumentMedia.updated", this.documentMedia);
        this.$refs.media_popup.close();
      } catch (error) {
        const errorMessage = "Unable to upload the file at this time.";
        this.$store.commit(`theme/${ADD_MESSAGE}`, {
          text: errorMessage,
          type: "error"
        });
      } finally {
        eventHub.$emit("DocumentMedia.loading", false);
      }
    },

    addMedia(item) {
      this.addListItem(this.form.documents, item);
      this.saveDocuments();
    },

    onUploadPercentage(value) {
      this.uploadPercentage = value;
    },

    onClickDropArea() {
      eventHub.$emit("clickMediaUpload");
    },

    removeDocument(item) {
      this.deleteListItem(this.form.documents, item);
      this.saveDocuments();
    },

    openImage(url) {
      window.open(url);
    }
  }
};
</script>
