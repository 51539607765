<template>
  <v-card tile>
    <submission-locked-alert :locked="readOnly"/>
    <loading-dialog v-if="loading" :message="loadingMessage"/>
    <v-card-title>
      CHECK LIST (member / prospective member entity)
    </v-card-title>
    <v-card-subtitle>
      * indicates required fields.
    </v-card-subtitle>
    <v-card-text v-if="items.length > 0">

      <!-- Question 1 -->
      <question :question="items[0]">
        <template slot-scope="props">
          <v-radio-group
              v-model="hasVATNumber"
              row
              @change="handleVATQuestionChange"
              :disabled="formLocked"
          >
            <v-radio label="Yes" :value="true"/>
            <v-radio label="No" :value="false"/>
          </v-radio-group>
          <v-col cols="4">
            <v-text-field
                v-show="hasVATNumber"
                v-model="props.question.answer"
                filled
                shaped
                :rules="rules.vatNo"
                @update:error="setFieldErrors"
                placeholder="VAT Number"
                @change="props.updateErrorStatus"
                :disabled="formLocked"
            />
          </v-col>
        </template>
      </question>

      <!-- Question 2 -->
      <question :question="items[1]">
        <template slot-scope="props">
          <v-radio-group
              v-model="props.question.answer"
              row
              @change="props.updateErrorStatus"
              :disabled="formLocked"
          >
            <v-radio
                v-for="choice in props.question.choices"
                :key="choice.value"
                :label="choice.text"
                :value="choice.value"
            ></v-radio>
          </v-radio-group>
        </template>
      </question>

      <!-- Question 3 -->
      <question :question="items[2]">
        <template slot-scope="props">
          <i>*See document uploads section.</i>
          <v-radio-group
              v-show="false"
              v-model="props.question.answer"
              row
              @change="props.updateErrorStatus"
              :disabled="formLocked"
          >
            <v-radio
                v-for="choice in props.question.choices"
                :key="choice.value"
                :label="choice.text"
                :value="choice.value"
            ></v-radio>
          </v-radio-group>
        </template>
      </question>

      <!-- Question 4 -->
      <question :question="items[3]">
        <template slot-scope="props">
          <v-radio-group
              v-model="props.question.answer"
              row
              @change="props.updateErrorStatus"
              :disabled="formLocked"
          >
            <v-radio
                v-for="choice in props.question.choices"
                :key="choice.value"
                :label="choice.text"
                :value="choice.value"
            ></v-radio>
          </v-radio-group>
        </template>
      </question>

      <!-- Question 5 -->
      <question :question="items[4]">
        <template slot-scope="props">
          <v-radio-group
              v-model="props.question.answer"
              row
              @change="props.updateErrorStatus"
              :disabled="formLocked"
          >
            <v-radio
                v-for="choice in props.question.choices"
                :key="choice.value"
                :label="choice.text"
                :value="choice.value"
            ></v-radio>
          </v-radio-group>
        </template>
      </question>

      <!-- Question 6 -->
      <question :question="items[5]">
        <template slot-scope="props">
          <v-radio-group
              v-model="props.question.answer"
              row
              @change="props.updateErrorStatus"
              :disabled="formLocked"
          >
            <v-radio
                v-for="choice in props.question.choices"
                :key="choice.value"
                :label="choice.text"
                :value="choice.value"
            ></v-radio>
          </v-radio-group>
        </template>
      </question>

      <!-- Question 7 -->
      <question :question="items[6]">
        <template slot-scope="props">
          <v-radio-group
              v-model="props.question.answer"
              row
              @change="props.updateErrorStatus"
              :disabled="formLocked"
          >
            <v-radio
                v-for="choice in props.question.choices"
                :key="choice.value"
                :label="choice.text"
                :value="choice.value"
            ></v-radio>
          </v-radio-group>
        </template>
      </question>

      <!-- Question 8 -->
      <question :question="items[7]">
        <template slot-scope="props">
          <v-checkbox
              v-for="choice in props.question.choices"
              v-model="props.question.answer"
              :key="choice.value"
              :label="choice.text"
              :value="choice.value"
              @change="props.updateErrorStatus"
              multiple
              :disabled="formLocked"
          ></v-checkbox>
        </template>
      </question>


      <!-- Question 9 -->
      <question :question="items[8]">
        <template slot-scope="props">
          <v-radio-group
              v-model="props.question.answer"
              @change="props.updateErrorStatus"
              row
              disabled
          >
            <v-radio
                v-for="choice in props.question.choices"
                :key="choice.value"
                :label="choice.text"
                :value="choice.value"
            ></v-radio>
          </v-radio-group>
        </template>
      </question>

      <!-- Question 10 -->
      <question :question="items[9]">
        <template slot-scope="props">
          <v-checkbox
              v-for="choice in props.question.choices"
              v-model="props.question.answer"
              :key="choice.value"
              :label="choice.text"
              :value="choice.value"
              :multiple="true"
              @change="props.updateErrorStatus"
              :disabled="formLocked"
          ></v-checkbox>
        </template>
      </question>

      <question :question="items[12]" :show-number="false">
        <template slot-scope="props">
          <v-col cols="4">
            <v-text-field
                v-model="props.question.answer"
                filled
                shaped
                @change="props.updateErrorStatus"
                :disabled="formLocked"
            />
          </v-col>
        </template>
      </question>

      <!-- Question 11 -->
      <question :question="items[10]">
        <template slot-scope="props">
          <v-radio-group
              v-model="props.question.answer"
              row
              @change="props.updateErrorStatus"
              :disabled="formLocked"
          >
            <v-radio
                v-for="choice in props.question.choices"
                :key="choice.value"
                :label="choice.text"
                :value="choice.value"
            ></v-radio>
          </v-radio-group>
        </template>
      </question>
    </v-card-text>
  </v-card>
</template>

<script>
import Question from "@/components/Question";
import LoadingDialog from "@/components/LoadingDialog";
import {ADD_MESSAGE} from "@/store/mutation-types";
import eventHub from "@/event-hub";
import Vue from "vue";
import SubmissionLockedAlert from "@/components/SubmissionLockedAlert";

const defaultLoadingMessage = "Loading Check List";

export default {
  name: "TraderQuestionSheet",

  components: {SubmissionLockedAlert, Question, LoadingDialog},

  props: {submissionId: {type: Number, required: true}, readOnly: {type: Boolean, default: false}},

  data() {
    return {
      items: [],
      loading: false,
      loadingMessage: defaultLoadingMessage,
      hasVATNumber: false,
      fieldErrors: false,
      rules: {
        vatNo: [value => !this.hasVATNumber || (!!value && value.length === 10 && value.charAt(0) === "4") || "Please enter a valid VAT number"]
      }
    };
  },

  computed: {
    url() {
      return `/inspection/submissions/${this.submissionId}/questions/`;
    },
    formLocked() {
      return this.readOnly || this.loading;
    },
    user() {
      return this.$store.state.auth.user;
    }
  },

  async mounted() {
    await this.fetchItems();
    this.prePopulateAnswers();
    Vue.nextTick(() => {
      eventHub.$emit("QuestionSheet.ready");
    });
  },

  created() {
    eventHub.$on("Submission.saveQuestions", this.save);
  },

  beforeDestroy() {
    eventHub.$off("Submission.saveQuestions");
  },

  methods: {
    fetchItems() {
      this.loadingMessage = defaultLoadingMessage;
      this.loading = true;

      return this.$http.get(this.url).then((response) => {
        this.items = response.data;
        this.hasVATNumber = !!(this.items[0].answer);
        return Promise.resolve(response);
      }).catch(() => {
        this.$store.commit(
            `theme/${ADD_MESSAGE}`,
            {type: "error", text: "There was an error loading this submission"}
        );
        return Promise.reject();
      }).finally(() => {
        this.loading = false;
      });
    },
    save() {
      if (this.fieldErrors) {
        this.$store.commit(`theme/${ADD_MESSAGE}`, {
          text: "The form could not be saved, please check for errors.",
          type: "error"
        });
        return;
      }
      this.loadingMessage = "Saving Check List...";
      this.loading = true;

      // Set default question answers
      // TODO these assumptions should be forced at a database level
      this.items[2].answer = "yes";  // Q 3
      this.items[11].answer = "yes";  // Q 12

      this.$http.patch(this.url, this.items).then((response) => {

        this.items = response.data;
        this.$store.commit(`theme/${ADD_MESSAGE}`, {type: "success", text: "Submission saved."});
        eventHub.$emit("QuestionSheet.updated", this.items);

      }).catch(() => {

        this.$store.commit(
            `theme/${ADD_MESSAGE}`,
            {type: "error", text: "Unable to save your data."}
        );

      }).finally(() => {

        this.loading = false;

      });
    },
    getQuestion(questionNumber) {
      if (this.item.answers) {
        return this.item.answers.filter((answer) => {
          return answer.question_number === questionNumber;
        })[0];
      } else {
        return {};
      }
    },
    prePopulateAnswers() {
      const question9 = this.items[8];
      if (!question9.answer && this.user.profile) {
        question9.answer = this.user.profile.classification === "trader" ? "desk_trader" : "dealer_recycler";
      }
    },
    emitQuestionUpdate(item) {
      eventHub.$emit("QuestionSheet.questionUpdated", item);
    },
    handleVATQuestionChange(value) {
      if (!value) this.clearQuestion(0);
    },
    setFieldErrors(value) {
      this.fieldErrors = value;
    },
    clearQuestion(questionNumber) {
      const question = this.items[questionNumber];
      switch (question.type) {
        case "text":
          question.answer = "";
          break;
        case "bool":
          question.answer = "no";
          break;
        default:
          question.answer = null;
      }
    }
  }
};
</script>
