<template>
  <responsive-dialog ref="responsive_dialog" :dialog-title="dialogTitle" :show-actions="false">
    <submission-form ref="submission_form" :submission="submission" @updated="close"/>
  </responsive-dialog>
</template>

<script>
import ResponsiveDialog from "@/components/ResponsiveDialog";
import SubmissionForm from "@/components/submission/SubmissionForm";

export default {
  name: "SubmissionFormModal",

  components: {SubmissionForm, ResponsiveDialog},

  props: {submission: {type: Object, required: false, default: null}},

  data() {
    return {
      loading: false
    };
  },

  computed: {
    dialogTitle() {
      return this.submission && this.submission.id ? "Update inspection": "New inspection";
    }
  },

  methods: {
    open(submission=null) {
      this.submission = submission;
      this.$refs.responsive_dialog.open();
      this.$refs.submission_form && this.$refs.submission_form.clearErrors();
    },
    close() {
      this.$refs.responsive_dialog.close();
      this.submission = null;
    }
  }

};
</script>

<style scoped></style>
