import debounce from "lodash/debounce";
import {ADD_FILTERS, SET_LIST} from "@/store/mutation-types";

const initialFilters = () => {
    return {};
};
const dataTableMixin = {
    props: {modelName: {type: String, required: true}},
    data() {
        return {
          tableFilters: initialFilters(),
          itemsPerPageOptions: [5, 10, 20, 50, 100],
          selected: [],
          defaultItemsPerPage: 10
        };
    },

    computed: {
        totalItems() {
            return this.$store.state[this.modelName].meta.count;
        },
        filtersAreEmpty() {
            return JSON.stringify(this.$store.state[this.modelName].filters) === "{}";
        },
    },

    methods: {
        getOrdering(sortBy, sortDesc) {
            const orderingArray = sortBy.map((value, index) => {
                return sortDesc[index] ? `-${value}` : value;
            });
            return orderingArray.join(",").replace(".", "__");
        },

        refresh() {
            this.$store.commit(`${this.modelName}/${SET_LIST}`, []);
            this.fetchItems();
        },

        filtersHaveChanged(newData) {
            return JSON.stringify(this.$store.state[this.modelName].filters) !== JSON.stringify(newData);
        },

        updateFilters: debounce(function () {
            const data = {...this.tableFilters};
            this.replaceKey(data, "itemsPerPage", "page_size");
            data.page = data.page || 1;

            // this.$store.commit(`${this.modelName}/${SET_LIST}`, []);
            if (this.filtersHaveChanged(data)) {
                this.$store.commit(`${this.modelName}/${ADD_FILTERS}`, data);
                this.fetchItems().then(() => {
                    document.body.scrollTop = 0; // For Safari
                    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
                });
            }
        }, 500),
        replaceKey(obj, oldName, newName) {
            if (Object.prototype.hasOwnProperty.call(obj, oldName)) {
                obj[newName] = obj[oldName];
                delete obj[oldName];
            }
        }
    }
};

export default dataTableMixin;
