import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home";
import Members from "@/views/admin/Members";
import NotFound from "@/views/NotFound";
import {
  LOADED,
  SET_NESTED_TITLE,
  SET_PAGE_TITLE
} from "@/store/mutation-types";
import store from "@/store";
import Profile from "@/views/Profile";
import Submission from "@/views/Submission";
import Submissions from "@/views/admin/Submissions";
import ProfileForm from "@/components/profile/ProfileForm";
import DocumentMedia from "@/components/submission/DocumentMedia";
import MainLayout from "@/layouts/MainLayout";
import AdminLayout from "@/layouts/AdminLayout";
import { authEvent } from "@admin100/djvue";
import MessageList from "@/components/messages/MessageList";
import AuthLayout from "@/layouts/AuthLayout";
import Login from "@/views/Login";
import ResetPasswordRequest from "@/views/ResetPasswordRequest";
import ResetPasswordConfirm from "@/views/ResetPasswordConfirm";
import SAPSHome from "@/views/saps/SAPSHome";
import SAPSLayout from "@/layouts/SAPSLayout";
import SAPSMemberDetail from "@/views/saps/SAPSMemberDetail";
import AdminMemberDetail from "../views/admin/AdminMemberDetail";
import ChangeNotificationIndex from "../views/change-notification/ChangeNotificationIndex";
import ChangeNotificationDetail from "../views/change-notification/ChangeNotificationDetail";
import DisciplinaryLogDetail from "../views/disciplinary-log/DisciplinaryLogDetail";
import DisciplinaryLogIndex from "../views/disciplinary-log/DisciplinaryLogIndex";
import SapsReportIndex from "../views/saps-report/SapsReportIndex";
import SapsReportGenerate from "../views/saps-report/SapsReportGenerate";

Vue.use(VueRouter);

const routes = [
  /**
   * MAIN APPLICATION ROUTES
   */
  {
    path: "/",
    component: MainLayout,
    meta: { allowed: ["user"] },
    children: [
      {
        path: "",
        name: "Home",
        component: Home,
        meta: { pageTitle: "Home", allowed: ["user"] },
        children: [
          {
            path: "/profile/:profile_id",
            name: "ProfileEdit",
            component: ProfileForm,
            meta: {
              pageTitle: "Home",
              allowed: ["user"],
              nestedTitle: "Profile Details"
            }
          }
        ]
      },
      {
        path: "/change-notifications",
        name: "ChangeNotificationIndex",
        component: ChangeNotificationIndex,
        meta: {
          pageTitle: "Update branch information",
          allowed: ["user"]
        }
      },
      {
        path: "/change-notifications/:id",
        name: "ChangeNotificationDetail",
        component: ChangeNotificationDetail,
        meta: {
          pageTitle: "Update branch information",
          allowed: ["user"]
        }
      },
      {
        path: "/profile",
        name: "Profile",
        component: Profile,
        meta: { pageTitle: "Company Profile", allowed: ["user"] }
      },
      {
        path: "/submission/:id",
        name: "Submission",
        component: Submission,
        meta: { pageTitle: "Inspection Submission", allowed: ["user"] },
        children: [
          {
            path: "/submission/:id/document/:document_id",
            name: "DocumentMedia",
            component: DocumentMedia,
            meta: { pageTitle: "Inspection Submission", allowed: ["user"] }
          },
          {
            path: "/submission/:submission_id/messages",
            name: "SubmissionMessageList",
            component: MessageList,
            meta: {
              pageTitle: "Inspection Submission",
              allowed: ["user"],
              nestedTitle: "Submission Messages"
            }
          }
        ]
      }
    ]
  },

  /**
   * ADMIN ROUTES
   */
  {
    path: "/admin",
    component: AdminLayout,
    meta: { allowed: ["admin"] },
    children: [
      {
        path: "/admin/submissions",
        name: "Submissions",
        component: Submissions,
        meta: { pageTitle: "Inspections", allowed: ["admin"] }
      },
      {
        path: "",
        name: "Members",
        component: Members,
        meta: { pageTitle: "Members", allowed: ["admin"] },
        props: { modelName: "profile" }
      },
      {
        path: "/admin/members/new",
        name: "ProfileNew",
        component: Profile,
        meta: {
          pageTitle: "New Member",
          allowed: ["admin"]
        }
      },
      {
        path: "/admin/members/:profile_id",
        name: "AdminMemberDetail",
        component: AdminMemberDetail,
        meta: {
          pageTitle: "Members",
          allowed: ["admin"],
          nestedTitle: "Profile Details"
        }
      },
      {
        path: "/admin/members/edit/:profile_id",
        name: "AdminMemberEdit",
        component: Profile,
        meta: {
          pageTitle: "Members",
          allowed: ["admin"],
          nestedTitle: "Profile Details"
        }
      },
      {
        path: "/admin/change-notifications",
        name: "AdminChangeNotificationIndex",
        component: ChangeNotificationIndex,
        meta: {
          pageTitle: "Change notifications",
          allowed: ["admin"]
        }
      },
      {
        path: "/admin/change-notifications/:id",
        name: "AdminChangeNotificationDetail",
        component: ChangeNotificationDetail,
        meta: {
          pageTitle: "Change notifications",
          allowed: ["admin"]
        }
      },
      {
        path: "/admin/disciplinary-logs",
        name: "AdminDisciplinaryLogIndex",
        component: DisciplinaryLogIndex,
        meta: {
          pageTitle: "Disciplinary Logs",
          allowed: ["admin"]
        }
      },
      {
        path: "/admin/disciplinary-logs/:id",
        name: "AdminDisciplinaryLogDetail",
        component: DisciplinaryLogDetail,
        meta: {
          pageTitle: "Disciplinary Logs",
          allowed: ["admin"]
        }
      },
      {
        path: "/admin/saps-reports/generate",
        name: "AdminSapsReportGenerate",
        component: SapsReportGenerate,
        meta: {
          pageTitle: "SAPS Report",
          allowed: ["admin"]
        }
      },
      {
        path: "/admin/saps-reports/generate/:id",
        name: "AdminSapsReportDetail",
        component: SapsReportGenerate,
        meta: {
          pageTitle: "SAPS Report",
          allowed: ["admin"]
        }
      },
      {
        path: "/admin/saps-reports",
        name: "AdminSapsReportIndex",
        component: SapsReportIndex,
        meta: {
          pageTitle: "SAPS Reports",
          allowed: ["admin"]
        }
      },
      {
        path: "/admin/submission/:id",
        name: "AdminSubmission",
        component: Submission,
        meta: { pageTitle: "Inspection Submission", allowed: ["admin"] },
        children: [
          {
            path: "/admin/submission/:id/document/:document_id",
            name: "AdminDocumentMedia",
            component: DocumentMedia,
            meta: {
              pageTitle: "Inspection Submission",
              allowed: ["admin"],
              nestedTitle: "Submission Document Details"
            }
          },
          {
            path: "/admin/submission/:submission_id/messages",
            name: "AdminSubmissionMessageList",
            component: MessageList,
            meta: {
              pageTitle: "Inspection Submission",
              allowed: ["admin"],
              nestedTitle: "Submission Messages"
            }
          }
        ]
      }
    ]
  },

  /**
   * SAPS ROUTES
   */
  {
    path: "/saps",
    component: SAPSLayout,
    meta: { allowed: ["saps"] },
    children: [
      {
        path: "",
        name: "SAPSHome",
        component: SAPSHome,
        meta: { pageTitle: "SAPS Home", allowed: ["saps"] }
      },
      {
        path: "member/:profile_id",
        name: "SAPSMemberDetail",
        component: SAPSMemberDetail,
        meta: { pageTitle: "MRA Member Details", allowed: ["saps"] }
      },
      {
        path: "/saps/change-notifications",
        name: "SAPSChangeNotificationIndex",
        component: ChangeNotificationIndex,
        meta: {
          pageTitle: "Change notifications",
          allowed: ["saps"]
        }
      },
      {
        path: "/saps/change-notifications/:id",
        name: "SAPSChangeNotificationDetail",
        component: ChangeNotificationDetail,
        meta: {
          pageTitle: "Change notifications",
          allowed: ["saps"]
        }
      },
      {
        path: "/saps/submission/:id",
        name: "SAPSSubmission",
        component: Submission,
        meta: { pageTitle: "Inspection Submission", allowed: ["saps"] },
        children: [
          {
            path: "/saps/submission/:id/document/:document_id",
            name: "SAPSDocumentMedia",
            component: DocumentMedia,
            meta: {
              pageTitle: "Inspection Submission",
              allowed: ["saps"],
              nestedTitle: "Submission Document Details"
            }
          },
          {
            path: "/saps/submission/:submission_id/messages",
            name: "SAPSSubmissionMessageList",
            component: MessageList,
            meta: {
              pageTitle: "Inspection Submission",
              allowed: ["saps"],
              nestedTitle: "Submission Messages"
            }
          }
        ]
      }
    ]
  },

  {
    path: "/auth",
    name: "Auth",
    component: AuthLayout,
    meta: { allowed: ["guest"] },
    children: [
      {
        path: "/login",
        name: "Login",
        component: Login,
        meta: { pageTitle: "Login", allowed: ["guest"] }
      },
      {
        path: "/reset-password-request",
        name: "reset-password-request",
        component: ResetPasswordRequest,
        meta: { pageTitle: "Reset Password", allowed: ["guest"] }
      },
      {
        path: "/reset-password-confirm",
        name: "reset-password-confirm",
        component: ResetPasswordConfirm,
        meta: { pageTitle: "Reset Password", allowed: ["guest"] }
      }
    ]
  },
  {
    // Not found
    path: "*",
    name: "NotFound",
    component: NotFound,
    meta: { allowed: ["guest", "admin", "user"] }
  }
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes
});

/**
 * Handle router events
 */
router.beforeEach((to, from, next) => {
  const appName = process.env.VUE_APP_NAME;
  const user = store.state.auth.user;

  // Set Page title
  to.matched.some(record => {
    if (record.meta.nestedTitle) {
      store.commit(`theme/${SET_NESTED_TITLE}`, record.meta.nestedTitle);
    }

    if (record.meta.pageTitle) {
      document.title = `${record.meta.pageTitle} | ${appName}`;
      store.commit(`theme/${SET_PAGE_TITLE}`, record.meta.pageTitle);
    } else {
      document.title = `${appName}`;
      store.commit(`theme/${SET_PAGE_TITLE}`, "");
    }
  });

  // Handle route permissions
  to.matched.some(record => {
    if (!record.meta.allowed) {
      authEvent.$emit("logout");
      return;
    }

    if (!record.meta.allowed.includes("admin") && user.is_staff) {
      next({
        path: "/admin"
      });
    } else if (!record.meta.allowed.includes("saps") && user.is_saps) {
      next({
        path: "/saps"
      });
    } else if (
      !record.meta.allowed.includes("user") &&
      !user.anonymous &&
      !user.is_staff &&
      !user.is_saps
    ) {
      next({
        path: "/"
      });
    } else if (!record.meta.allowed.includes("guest") && user.anonymous) {
      next({
        path: "/login"
      });
    } else {
      next();
    }
  });

  //Redirect users with no profile
  to.matched.some(() => {
    if (
      !user.anonymous &&
      !user.profile &&
      !user.is_staff &&
      !user.is_saps &&
      to.path !== "/profile"
    ) {
      next({
        path: "/profile"
      });
    }
  });

  // Cancel any page loading state (if present)
  store.commit(`theme/${LOADED}`);
});

export default router;
