<template>
  <v-dialog v-model="isOpen" max-width="500px" persistent>
    <v-card tile>
      <v-card-title class="headline">
        <slot name="title">
          Confirmation required
        </slot>
      </v-card-title>
      <v-card-text>
        <slot>
          Are you sure ?
        </slot>
      </v-card-text>
      <v-card-actions>
        <v-btn color="error" text @click="isOpen = false">
          NO
        </v-btn>
        <v-btn color="info" text @click="confirm">
          YES
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ConfirmDialog",
  data() {
    return {
      isOpen: false
    };
  },
  methods: {
    open() {
      this.isOpen = true;
    },
    close() {
      this.isOpen = false;
    },
    confirm() {
      this.$emit("confirmed");
      this.close();
    }
  }
};
</script>

<style scoped>

</style>