<template>
  <v-navigation-drawer v-model="drawer" app>
    <template v-slot:prepend>
      <v-list-item>
        <v-list-item-avatar tile>
          <v-img
              :src="require('@/assets/logo.webp')"
              alt="mra"
          ></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>SAPS Dashboard</v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
        </v-list-item-action>
      </v-list-item>
    </template>

    <v-divider></v-divider>

    <v-list>
      <!-- MENU ITEM: HOME -->
      <v-list-item link to="/saps" color="primary">
        <v-list-item-icon>
          <v-icon>mdi-home</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Home</v-list-item-title>
      </v-list-item>

      <!-- MENU ITEM: CHANGE NOTIFICATIONS -->
      <v-list-item link to="/saps/change-notifications" color="primary">
        <v-list-item-icon>
          <v-icon>mdi-information-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Change notifications</v-list-item-title>
      </v-list-item>
    </v-list>


    <!-- MENU ITEM: SUBMISSION DETAIL -->
    <submission-menu v-if="submissionDetail" :submission="submissionDetail"/>

    <!-- MENU ITEM: MEMBER FILTERS-->
    <profile-filter v-if="$route.name === 'SAPSHome'" model-name="profile"/>

  </v-navigation-drawer>
</template>

<script>
import eventHub from "@/event-hub";
import {SET_DRAWER} from "@/store/mutation-types";
import SubmissionMenu from "@/components/submission/SubmissionMenu";
import ProfileFilter from "@/components/profile/ProfileFilter";


export default {
  name: "SAPSMenu",

  components: {ProfileFilter, SubmissionMenu},

  data() {
    return {
      submissionDetail: null
    };
  },

  created() {
    eventHub.$on("toggle_drawer", this.toggleDrawer);
    eventHub.$on("Menu.setSubmissionDetail", this.setSubmissionDetail);
  },

  beforeDestroy() {
    eventHub.$off("toggle_drawer");
    eventHub.$off("Menu.setSubmissionDetail");
  },

  computed: {
    mini() {
      return this.$store.state.theme.miniMenu;
    },

    user() {
      return this.$store.state.auth.user;
    },

    route() {
      return this.$route.path;
    },

    drawer: {
      get() {
        return this.$store.state.theme.drawer;
      },
      set(value) {
        this.$store.commit(`theme/${SET_DRAWER}`, value);
      }
    }
  },

  methods: {
    toggleDrawer() {
      this.$store.commit(`theme/${SET_DRAWER}`, !this.drawer);
    },
    setSubmissionDetail(submission) {
      this.submissionDetail = submission;
    }
  }
};
</script>

<style scoped></style>
