<template>
  <v-list>
    <v-divider/>
    <v-list-item>
      <v-list-item-title>
        INSPECTION REF: 00{{ submission.id }}
      </v-list-item-title>
    </v-list-item>
    <loading-dialog v-if="downloadBusy" message="Downloading file..."></loading-dialog>
    <v-list-item>
      <v-list-item-icon>
        <v-icon>mdi-calendar</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>
          Due date
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ submission.due_date }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <!-- MESSAGES -->
    <v-list-item :to="messagesRoute">
      <v-list-item-icon>
        <v-icon>mdi-email</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>
          Messages
        </v-list-item-title>
        <v-list-item-subtitle>
          <a>{{ submission.messages_received }} messages</a>
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <!-- STATUS -->
    <v-list-item color="white">
      <v-list-item-icon>
        <submission-status-icon :status="submission.status"/>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>
          Status
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ getStatusText(submission.status) }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <!-- DOWNLOAD PDF -->
    <v-list-item>
      <v-list-item-icon>
        <v-icon>mdi-download</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>
          Downloads
        </v-list-item-title>
        <v-list-item-subtitle>
          <a @click.prevent="downloadApplicationForm(submission)" target="_blank"
             style="text-decoration: none">Application Form</a>
        </v-list-item-subtitle>
        <v-list-item-subtitle v-if="submission.status === 'complete'">
          <a @click.prevent="downloadMembershipCertificate(submission)"
             target="_blank" style="text-decoration: none">Membership Certificate</a>
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
import downloadMixin from "@/mixins/download";
import routePrefixMixin from "@/mixins/routePrefix";
import SubmissionStatusIcon from "@/components/submission/SubmissionStatusIcon";
import statusMixin from "@/mixins/status";
import LoadingDialog from "@/components/LoadingDialog";

export default {
  name: "SubmissionMenu",

  props: {submission: {type: Object, default: null}},

  components: {
    SubmissionStatusIcon, LoadingDialog
  },

  mixins: [downloadMixin, routePrefixMixin, statusMixin],

  computed: {
    messagesRoute() {
      return `${this.routePrefix}/submission/${this.submission.id}/messages`;
    }
  }
};
</script>

<style scoped>

</style>