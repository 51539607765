import uuidv4 from "uuid/v4";
// Mutation types
import {
    ADD_MESSAGE,
    CLEAR_MESSAGES,
    LOADED,
    LOADING,
    REMOVE_MESSAGE,
    SET_CUSTOM_DOMAIN,
    SET_DRAWER, SET_NESTED_TITLE,
    SET_PAGE_TITLE,
    SET_PROGRESS,
    TOGGLE_MINI_MENU,
    TOGGLE_THEME
} from "@/store/mutation-types";

// ThemeState
const state = {
    dark: false,
    drawer: null,
    miniMenu: false,
    loading: false,
    messages: [],
    pageTitle: "",
    nestedTitle: "",
    lastMessageId: null,
    progress: 0,
    language: "en"
};

// Getters
const getters = {};

const mutations = {
    [TOGGLE_THEME](state) {
        state.dark = !state.dark;
    },
    [TOGGLE_MINI_MENU](state) {
        state.miniMenu = !state.miniMenu;
    },
    [SET_DRAWER](state, open = false) {
        state.drawer = open;
    },
    [LOADING](state) {
        state.loading = true;
    },
    [LOADED](state) {
        state.loading = false;
    },
    [SET_PROGRESS](state, progress) {
        state.progress = progress;
    },
    [SET_PAGE_TITLE](state, title) {
        state.pageTitle = title;
    },
    [SET_NESTED_TITLE](state, title) {
        state.nestedTitle = title;
    },
    [ADD_MESSAGE](state, message) {
        message.key = uuidv4();
        state.messages.push(message);
        state.lastMessageId = message.key;
    },
    [REMOVE_MESSAGE](state, key) {
        state.messages = state.messages.filter((obj) => obj.key !== key);
    },
    [CLEAR_MESSAGES](state) {
        state.messages = [];
    },
    [SET_CUSTOM_DOMAIN](state, customDomain) {
        state.customDomain = customDomain;
    }
};

// Actions
const actions = {
    toggle({commit}) {
        commit(TOGGLE_THEME);
    },
    toggleMiniMenu({commit}) {
        commit(TOGGLE_MINI_MENU);
    }
};

const index = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};

export default index;
