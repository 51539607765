<template>
  <form @submit.prevent="onFormSubmit()">
    <responsive-dialog :display-dialog="formOpened" :dialog-title="formTitle" @closed="closeForm">
      <non-field-errors :errors="formErrors"></non-field-errors>
      <v-row>
        <v-col cols="12">
          <v-file-input
            v-model="formItem.file"
            outlined
            :error-messages="formErrors.file"
            show-size
            label="Select File"
          ></v-file-input>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="formItem.description"
            outlined
            label="Description"
            :error-messages="formErrors.description"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <template slot="actions">
        <v-spacer />
        <v-btn type="submit" color="primary" text :loading="formLoading" x-large>
          <v-icon>mdi-upload</v-icon>
          UPLOAD
        </v-btn>
      </template>
    </responsive-dialog>
  </form>
</template>

<script>
import Vue from "vue";
import ResponsiveDialog from "@/components/ResponsiveDialog.vue";
import { SET_DETAIL } from "@/store/mutation-types";
import NonFieldErrors from "@/components/NonFieldErrors.vue";

export default Vue.extend({
  name: "InvoiceItemForm",

  components: {
    ResponsiveDialog,
    NonFieldErrors
  },

  data(){
    return {
      formTitle: "Media Upload",
      formOpened: false,
      formErrors: {},
      formItem: {}
    };
  },

  computed: {
    formLoading() {
      return this.$store.getters["media/loading"]("create") || this.$store.getters["media/loading"]("update");
    },
    formObject(){
      return this.$store.state.media.detail;
    },
    isItemUpdate() {
      // Returns true if we are trying to update an item. False if it's a creation
      return !!this.formItem.id;
    },
    formHasErrors() {
      return Object.keys(this.formErrors).length > 0;
    }
  },

  methods: {
    resetFormErrors() {
      this.formErrors = {};
    },

    open(value){
      this.formItem = { ...value };
      this.formOpened = !!value;
    },

    async onFormSubmit() {
      this.resetFormErrors();

      this.isItemUpdate ? await this.sendUpdate() : await this.sendCreate();

      if (this.formHasErrors) {
        return;
      }

      this.$emit("updated");
      this.closeForm();
    },

    closeForm() {
      this.$store.commit(`media/${SET_DETAIL}`, null);
      this.formOpened = false;
      this.$emit("closed");
    },

    async sendUpdate() {
      try {
        await this.$store.dispatch("media/update", { id: this.formItem.id, data: this.formItem });
      } catch (error) {
        this.formErrors = error.response.data;
      }
    },

    async sendCreate() {
      try {
        await this.$store.dispatch("media/create", this.formItem);
      } catch (error) {
        this.formErrors = error.response.data;
      }
    }
  }
});
</script>

<style scoped></style>
