<template>
  <v-dialog v-model="isOpen" @close="close" max-width="667px">
    <v-img contain :src="src" :max-height="maxHeight" @click="close" />
  </v-dialog>
</template>

<script>
export default {
  name: "LightBox",
  props: {maxHeight: {type: String}},
  data() {
    return {src: null, isOpen: false};
  },
  methods: {
    open(src) {
      this.src = src;
      this.isOpen = true;
    },
    close() {
      this.src = null;
      this.isOpen = false;
    }
  }
};
</script>

<style scoped>

</style>