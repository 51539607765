<template>
  <v-container>
    <one-column-row>
      <v-card tile>
        <v-card-title>
          Disciplinary Logs
        </v-card-title>
        <v-card-text>
          <v-data-table
            v-model="selected"
            class="pa-2"
            :headers="headers"
            :items="items"
            :loading="isLoading"
            :footer-props="{ 'items-per-page-options': itemsPerPageOptions }"
            :options.sync="tableFilters"
            @update:options="updateFilters"
            :server-items-length="totalItems"
            disable-filtering
            disable-sort
            item-key="id"
          >
            <!-- REFERENCE NO -->
            <template v-slot:item.reference_no="{ item }">
              <router-link :to="`${routePrefix}/disciplinary-logs/${item.id}`">
                {{ item.reference_no }}
              </router-link>
            </template>


          </v-data-table>
        </v-card-text>
      </v-card>
    </one-column-row>
  </v-container>
</template>

<script>
import { ADD_MESSAGE } from "@/store/mutation-types";
import OneColumnRow from "@/layouts/components/OneColumnRow";
import dataTableMixin from "@/mixins/dataTable";
import routePrefixMixin from "../../mixins/routePrefix";

export default {
  name: "DisciplinaryLogList",
  mixins: [dataTableMixin, routePrefixMixin],
  components: { OneColumnRow },
  data() {
    return {
      headers: [
        { text: "REF", value: "reference_no", sortable: false },
        { text: "Member", value: "user.profile.company", sortable: false },
        { text: "Created", value: "created_at", sortable: true },
      ],
      loading: false
    };
  },
  mounted() {
    this.fetchItems();
  },
  computed: {
    items() {
      return this.$store.state.disciplinaryLog.list;
    },
    isLoading() {
      return this.$store.getters["disciplinaryLog/loading"]("list") || this.loading;
    }
  },
  methods: {
    async fetchItems() {
      try {
        await this.$store.dispatch("disciplinaryLog/list");
      } catch (e) {
        this.$store.commit(`theme/${ADD_MESSAGE}`, {
          text: "Unable to load change notifications",
          type: "error"
        });
      }
    },

    viewItemDetails(item) {
      this.$router.push({
        name: "DisciplinaryLogList",
        params: { di: item.id }
      });
    }
  }
};
</script>
