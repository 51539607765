<template>
  <v-container>
    <v-card>
    <v-alert type="info" outlined border="left">
      Please review your membership details. If any details have since your last submission please submit any changes to
      the MRA. <v-btn type="link" :to="{name: 'ChangeNotificationIndex'}" color="info" outlined>Update Details</v-btn>
    </v-alert>
    </v-card>
    <right-flyout router-param="profile_id" />
    <!-- COMPANY SUMMARY -->
    <one-column-row>
      <v-card tile v-if="user.profile">
        <v-card-title>
          Company Profile | {{ user.profile.company }}
          <v-spacer />
          <switch-account />
        </v-card-title>
        <v-card-subtitle v-if="user.profile.is_main_branch">
          <span>Main Branch</span>
        </v-card-subtitle>
        <v-card-text>
          <profile-table />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text xs :to="`/profile/${user.profile.id}`">
            <v-icon left>mdi-pencil</v-icon>
            View or edit full company profile
          </v-btn>
        </v-card-actions>
      </v-card>
    </one-column-row>

    <!-- SUBMISSIONS -->
    <one-column-row>
      <v-card tile>
        <v-card-title>
          Inspections
          <v-spacer />
          <v-btn
            @click="$store.dispatch('submission/list')"
            text
            title="Refresh"
          >
            <v-icon left>mdi-refresh</v-icon>
            Refresh Table
          </v-btn>
        </v-card-title>
        <v-card-subtitle>
          Please select an inspection for more details
        </v-card-subtitle>
        <v-divider />
        <v-card-text>
          <SubmissionList model-name="submission" />
        </v-card-text>
      </v-card>
    </one-column-row>
  </v-container>
</template>

<script>
import SubmissionList from "@/components/submission/SubmissionList";
import OneColumnRow from "@/layouts/components/OneColumnRow";
import RightFlyout from "@/components/RightFlyout";
import ProfileTable from "@/components/profile/ProfileTable";
import SwitchAccount from "@/components/account/SwitchAccount";

export default {
  name: "Home",
  components: {
    SwitchAccount,
    ProfileTable,
    RightFlyout,
    OneColumnRow,
    SubmissionList
  },
  computed: {
    user() {
      return this.$store.state.auth.user;
    }
  },
  methods: {
    editProfile() {
      this.$router.push("/profile");
    }
  }
};
</script>
