<template>
  <v-container>
    <v-card>
      <disciplinary-log-form :log-id="$route.params.id"/>
    </v-card>
  </v-container>
</template>

<script>
import DisciplinaryLogForm from "../../components/disciplinary-log/DisciplinaryLogForm";

export default {
  name: "DisciplinaryLogDetail",
  components: {DisciplinaryLogForm},
};
</script>

<style scoped>

</style>
