<template>
  <div>
    <v-card-text v-if="!readOnly">
      <v-alert type="info" outlined border="left">
        Please note. This submission is for the purposes of making the MRA aware
        of a change in information. You still need to submit the 604 form to
        your local DSO as you normally would.
      </v-alert>
    </v-card-text>
    <v-form ref="profileForm" @submit.prevent="onFormSubmit()">
      <v-card-title>Step 1</v-card-title>
      <v-card-subtitle>
        Please download and complete both the checklist and 604 form. You need
        to physically submit this to you DSO.
      </v-card-subtitle>
      <v-card-text>
        <p>
          <v-btn
            text
            href="/pdf/Notification_of_Change_of_Information_Checklist.pdf"
            target="_blank"
            color="primary"
          >
            <v-icon left>mdi-download</v-icon>
            Notification of change checklist
          </v-btn>
        </p>
        <p>
          <v-btn text href="/pdf/604_form.pdf" target="_blank" color="primary">
            <v-icon left>mdi-download</v-icon>
            604 Form
          </v-btn>
        </p>
      </v-card-text>
      <v-card-title>Step 2</v-card-title>
      <v-card-subtitle>
        Attach the completed checklist and 604 form. Please also attach all
        required documentents mentioned in the checklist.
      </v-card-subtitle>
      <v-card-text>
        <v-progress-linear
          v-if="uploadPercentage > 0 || loading"
          :value="uploadPercentage"
          :indeterminate="uploadPercentage === 100 || loading"
        ></v-progress-linear>

        <media-field @updated="addMedia" @uploadPercentage="onUploadPercentage">
          <media-drop-zone class="" style="min-height: 200px;" v-if="!readOnly">
            <v-icon x-large>mdi-cloud-upload</v-icon>
            <p class="caption">Click here or drag a file to upload</p>
          </media-drop-zone>
          <v-list dense>
            <v-list-item v-if="loading">
              <v-progress-linear
                :value="uploadPercentage"
                :indeterminate="uploadPercentage === 100"
              ></v-progress-linear>
            </v-list-item>
            <v-list-item
              v-for="item in form.documents"
              @click.stop="openImage(item.file)"
              :key="item.id"
              target="_blank"
            >
              <v-list-item-avatar tile>
                <v-img
                  v-if="isImageFile(item.filename)"
                  size="50"
                  :src="item.file"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        width="3"
                        indeterminate
                        color="primary"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
                <v-icon v-else large>{{ getMediaIcon(item.filename) }}</v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title> {{ item.filename }}</v-list-item-title>
                <v-list-item-subtitle>
                  {{ item.size / 1000 }} KB
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action @click.stop>
                <v-btn
                  v-if="!readOnly"
                  @click.stop="removeDocument(item)"
                  text
                  title="Remove Document/Image"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </media-field>
        <a
          v-if="!readOnly"
          color="primary"
          @click.prevent="$refs.media_popup.open()"
        >
          Select a document from your 'shared documents'
        </a>

        <v-textarea
          class="mt-5"
          v-model="form.note"
          label="Notes"
          placeholder=""
          aria-required="true"
          :error-messages="errors.note"
          filled
          shaped
        ></v-textarea>

        <v-checkbox
          v-if="!readOnly"
          label="I confirm that the attached 604 form was submitted to my local DSO"
          v-model="wasSubmittedToDSO"
        >
        </v-checkbox>
      </v-card-text>

      <media-popup
        ref="media_popup"
        is-submission
        model-name="media"
        @selected="addMedia"
      />
    </v-form>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        v-if="!readOnly && !isApproved"
        color="info"
        block
        :loading="loading"
        :disabled="!wasSubmittedToDSO"
        x-large
        @click="onFormSubmit"
      >
        SUBMIT
      </v-btn>
      <v-btn
        v-if="user.is_saps && !isApproved"
        color="success"
        block
        :loading="loading"
        x-large
        @click="approve"
      >
        APPROVE
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import { ADD_MESSAGE, SET_LIST } from "@/store/mutation-types";
import { updateListItem } from "@/utils/list";
import MediaField from "../media/MediaField";
import MediaDropZone from "../media/MediaDropZone";
import MediaPopup from "@/components/media/MediaPopup.vue";
import downloadMixin from "../../mixins/download";
import mediaMixin from "../../mixins/media";
import listMixin from "../../mixins/list";
import eventHub from "../../event-hub";

export default {
  name: "ChangeNotificationForm",
  components: { MediaDropZone, MediaField, MediaPopup },
  props: ["notificationId"],
  mixins: [downloadMixin, mediaMixin, listMixin],
  data() {
    return {
      loading: false,
      wasSubmittedToDSO: false,
      form: {
        note: null,
        documents: []
      },
      uploadPercentage: 0,
      errors: {},
      branches: [],
      provinceChoices: require("@/data/provinces.json"),
      classificationChoices: require("@/data/classification.json")
    };
  },

  async mounted() {
    await this.fetchItem();
  },

  computed: {
    user() {
      return this.$store.state.auth.user;
    },
    url() {
      return `/account/change-notifications/${this.notificationId}/`;
    },
    documentUrl() {
      return `${this.url}documents/`;
    },
    readOnly() {
      return this.user.is_saps || this.user.is_staff;
    },
    isApproved() {
      return this.form.status === "complete";
    }
  },

  methods: {
    fetchItem() {
      this.loading = true;
      this.$http
        .get(this.url)
        .then(response => {
          this.form = response.data;
        })
        .catch(() => {
          this.$store.commit(`theme/${ADD_MESSAGE}`, {
            type: "error",
            text: "Unable to fetch data"
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },

    async onFormSubmit() {
      this.loading = true;
      this.form.status = "submitted";
      try {
        const response = await this.$http.put(this.url, this.form);
        this.$store.commit("auth/SET_USER", {
          ...this.user,
          profile: response.data
        });
        this.form = response.data;
        this.$store.commit(
          `profile/${SET_LIST}`,
          updateListItem([...this.$store.state.profile.list], this.form)
        );
        this.errors = [];
        this.$store.commit(`theme/${ADD_MESSAGE}`, {
          type: "success",
          text: "Notice of information change has been sent."
        });

        await this.$router.back();
      } catch (error) {
        this.errors = error.response.data;
        this.$store.commit(`theme/${ADD_MESSAGE}`, {
          type: "error",
          text: "Notice of information change has failed."
        });
      } finally {
        this.loading = false;
      }
    },

    async approve() {
      this.loading = true;
      try {
        const response = await this.$http.post(`${this.url}approve/`);
        this.form = response.data;
        this.$store.commit(`theme/${ADD_MESSAGE}`, {
          text: "Submission Approved.",
          type: "success"
        });
      } catch ($error) {
        const errorMessage =
          "There was an error, the submission could not be approved.";
        this.$store.commit(`theme/${ADD_MESSAGE}`, {
          text: errorMessage,
          type: "error"
        });
      } finally {
        this.loading = false;
      }
    },

    async saveDocuments() {
      eventHub.$emit("DocumentMedia.loading", true);
      this.uploadPercentage = 0;
      try {
        await this.$http.patch(this.documentUrl, this.form);
        eventHub.$emit("DocumentMedia.updated", this.documentMedia);
        this.$refs.media_popup.close();
      } catch (error) {
        const errorMessage = "Unable to upload the file at this time.";
        this.$store.commit(`theme/${ADD_MESSAGE}`, {
          text: errorMessage,
          type: "error"
        });
      } finally {
        eventHub.$emit("DocumentMedia.loading", false);
      }
    },

    addMedia(item) {
      this.addListItem(this.form.documents, item);
      this.saveDocuments();
    },

    onUploadPercentage(value) {
      this.uploadPercentage = value;
    },

    onClickDropArea() {
      eventHub.$emit("clickMediaUpload");
    },

    removeDocument(item) {
      this.deleteListItem(this.form.documents, item);
      this.saveDocuments();
    },

    openImage(url) {
      window.open(url);
    }
  }
};
</script>
