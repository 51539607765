const statusMixin = {

    data() {
        return {
            statusOptions: {
                pending: {text: "Incomplete", color: "warning", icon: "mdi-clock-outline"},
                reopened: {text: "Re-Opened", color: "warning", icon: "mdi-clock-outline"},
                submitted: {text: "Submitted", color: "info", icon: "mdi-check-circle-outline"},
                complete: {text: "Complete", color: "success", icon: "mdi-check-circle-outline"},
                draft: {text: "Draft", color: "blue", icon: "mdi-checkbox-marked-circle-outline"},
            }
        };
    },

    methods: {
        getStatusText(status) {
            return this.statusOptions[status].text;
        },

        getStatusColor(status) {
            return this.statusOptions[status].color;
        },

        getStatusIcon(status) {
            return this.statusOptions[status].icon;
        }
    }
};

export default statusMixin;
