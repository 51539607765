<template>
  <v-card tile>
    <submission-locked-alert :locked="readOnly" />
    <loading-dialog v-if="loading" :message="loadingMessage" />
    <v-card-title>
      CHECK LIST (member / prospective member entity)
    </v-card-title>
    <v-card-subtitle>
      * indicates required fields.
    </v-card-subtitle>
    <v-card-text v-if="items.length > 0">
      <!-- Question 1 -->
      <question :question="items[0]">
        <template slot-scope="props">
          <v-radio-group
            @change="props.updateErrorStatus"
            v-model="props.question.answer"
            row
            :disabled="formLocked"
          >
            <v-radio
              v-for="choice in props.question.choices"
              :key="choice.value"
              :label="choice.text"
              :value="choice.value"
            ></v-radio>
          </v-radio-group>
        </template>
      </question>

      <v-col cols="6">
        <question :question="items[41]" :show-question="false">
          <template slot-scope="props">
            <v-text-field
              v-show="isDealer"
              v-model="props.question.answer"
              @keyup="props.updateErrorStatus"
              filled
              shaped
              :error="props.isErrored"
              :placeholder="props.question.question"
              :disabled="formLocked"
              :messages="[
                `The full name of the RESPONSIBLE PERSON that appears on the certificate ${
                  props.question.required ? '(required)' : ''
                }`
              ]"
            />
          </template>
        </question>
      </v-col>

      <!-- Question 2 -->
      <question ref="question2" :question="items[1]">
        <template slot-scope="props">
          <v-radio-group
            v-model="props.question.answer"
            @change="props.updateErrorStatus"
            row
            :disabled="formLocked"
          >
            <v-radio
              v-for="choice in props.question.choices"
              :key="choice.value"
              :label="choice.text"
              :value="choice.value"
            ></v-radio>
          </v-radio-group>
        </template>
      </question>

      <v-col cols="6">
        <question :question="items[42]" :show-question="false">
          <template slot-scope="props">
            <v-text-field
              v-show="isRecycler"
              v-model="props.question.answer"
              @keyup="props.updateErrorStatus"
              filled
              shaped
              :error="props.isErrored"
              :placeholder="props.question.question"
              :disabled="formLocked"
              :messages="[
                `The full name of the RESPONSIBLE PERSON that appears on the certificate ${
                  props.question.required ? '(required)' : ''
                }`
              ]"
            />
          </template>
        </question>
      </v-col>

      <!-- Question 3 -->
      <question :question="items[2]">
        <template slot-scope="props">
          <v-radio-group
            v-model="hasVATNumber"
            row
            @change="handleVATQuestionChange"
            :disabled="formLocked"
          >
            <v-radio label="Yes" :value="true" />
            <v-radio label="No" :value="false" />
          </v-radio-group>
          <v-col cols="4">
            <v-text-field
              v-show="hasVATNumber"
              v-model="props.question.answer"
              filled
              shaped
              :rules="rules.vatNo"
              @update:error="setFieldErrors"
              placeholder="VAT Number"
              @change="props.updateErrorStatus"
              :disabled="formLocked"
            />
          </v-col>
        </template>
      </question>

      <!-- Question 4 -->
      <question :question="items[3]">
        <template slot-scope="props">
          <v-checkbox
            v-for="choice in props.question.choices"
            v-model="props.question.answer"
            :key="choice.value"
            :label="choice.text"
            :value="choice.value"
            @change="props.updateErrorStatus"
            multiple
            :disabled="formLocked"
          ></v-checkbox>
        </template>
      </question>

      <question :question="items[32]" :show-number="false">
        <template slot-scope="props">
          <v-col cols="4">
            <v-text-field
              v-model="props.question.answer"
              filled
              @change="props.updateErrorStatus"
              shaped
              :disabled="formLocked"
            />
          </v-col>
        </template>
      </question>

      <!-- Question 5 -->
      <question :question="items[4]">
        <template slot-scope="props">
          <v-radio-group
            v-model="props.question.answer"
            row
            @change="props.updateErrorStatus"
            :disabled="formLocked"
          >
            <v-radio
              v-for="choice in props.question.choices"
              :key="choice.value"
              :label="choice.text"
              :value="choice.value"
            ></v-radio>
          </v-radio-group>
        </template>
      </question>

      <template v-if="hasElectronicRegister">
        <v-col cols="6">
          <question :question="items[40]" :show-question="false">
            <template slot-scope="props">
              <v-text-field
                v-show="hasElectronicRegister"
                v-model="props.question.answer"
                @keyup="props.updateErrorStatus"
                filled
                shaped
                :error="props.isErrored"
                :placeholder="props.question.question"
                :disabled="formLocked"
                :messages="[
                  `e.g RecycleProx ${
                    props.question.required ? '(required)' : ''
                  }`
                ]"
              />
            </template>
          </question>
        </v-col>

        <question :question="items[38]" :show-number="false">
          <template slot-scope="props">
            <v-radio-group
              v-model="props.question.answer"
              row
              @change="props.updateErrorStatus"
              :disabled="formLocked"
            >
              <v-radio
                v-for="choice in props.question.choices"
                :key="choice.value"
                :label="choice.text"
                :value="choice.value"
              ></v-radio>
            </v-radio-group>
          </template>
        </question>

        <v-col cols="6">
          <question :question="items[39]" :show-question="false">
            <template slot-scope="props">
              <v-text-field
                v-show="hasOffSiteRegisterBackup"
                v-model="props.question.answer"
                @keyup="props.updateErrorStatus"
                filled
                shaped
                :error="props.isErrored"
                :placeholder="props.question.question"
                :disabled="formLocked"
                :messages="[
                  `eg: USB, Harddrive, Cloud ${
                    props.question.required ? '(required)' : ''
                  }`
                ]"
              />
            </template>
          </question>
        </v-col>
      </template>

<!--      <template v-if="hasElectronicRegister">-->
<!--        <v-col cols="6">-->
<!--          <v-text-field-->
<!--            v-model="items[40].answer"-->
<!--            filled-->
<!--            shaped-->
<!--            :placeholder="items[40].question"-->
<!--            :disabled="formLocked"-->
<!--            :messages="['eg: RecycleProx.']"-->
<!--          />-->
<!--        </v-col>-->

<!--        <question :question="items[38]" :show-number="false">-->
<!--          <template slot-scope="props">-->
<!--            <v-radio-group-->
<!--              v-model="props.question.answer"-->
<!--              row-->
<!--              @change="props.updateErrorStatus"-->
<!--              :disabled="formLocked"-->
<!--            >-->
<!--              <v-radio-->
<!--                v-for="choice in props.question.choices"-->
<!--                :key="choice.value"-->
<!--                :label="choice.text"-->
<!--                :value="choice.value"-->
<!--              ></v-radio>-->
<!--            </v-radio-group>-->
<!--          </template>-->
<!--        </question>-->

<!--        <v-col cols="6">-->
<!--          <v-text-field-->
<!--            v-if="hasOffSiteRegisterBackup"-->
<!--            v-model="items[39].answer"-->
<!--            filled-->
<!--            shaped-->
<!--            :placeholder="items[39].question"-->
<!--            :disabled="formLocked"-->
<!--            :messages="['eg: USB, Harddrive, Cloud']"-->
<!--          />-->
<!--        </v-col>-->
<!--      </template>-->

      <!-- Question 6 -->
      <question :question="items[5]">
        <template slot-scope="props">
          <i>*See document uploads section.</i>
          <v-radio-group
            v-show="false"
            v-model="props.question.answer"
            row
            @change="props.updateErrorStatus"
            :disabled="formLocked"
          >
            <v-radio
              v-for="choice in props.question.choices"
              :key="choice.value"
              :label="choice.text"
              :value="choice.value"
            ></v-radio>
          </v-radio-group>
        </template>
      </question>

      <!-- Question 7 -->
      <question :question="items[6]">
        <template slot-scope="props">
          <v-radio-group
            v-model="props.question.answer"
            row
            @change="props.updateErrorStatus"
            :disabled="formLocked"
          >
            <v-radio
              v-for="choice in props.question.choices"
              :key="choice.value"
              :label="choice.text"
              :value="choice.value"
            ></v-radio>
          </v-radio-group>
        </template>
      </question>

      <!-- Question 8 -->
      <question :question="items[7]">
        <template slot-scope="props">
          <v-row>
            <v-col cols="4">
              <v-text-field
                v-model="props.question.answer"
                filled
                shaped
                @change="props.updateErrorStatus"
                type="number"
                :disabled="formLocked"
              />
            </v-col>

            <v-col cols="4">
              <v-radio-group
                v-model="items[37].answer"
                row
                @change="props.updateErrorStatus"
                :disabled="formLocked"
              >
                <v-radio
                  v-for="choice in items[37].choices"
                  :key="choice.value"
                  :label="choice.text"
                  :value="choice.value"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </template>
      </question>

      <!-- Question 9 -->
      <question :question="items[8]">
        <template slot-scope="props">
          <v-radio-group
            v-model="props.question.answer"
            row
            @change="props.updateErrorStatus"
            :disabled="formLocked"
          >
            <v-radio
              v-for="choice in props.question.choices"
              :key="choice.value"
              :label="choice.text"
              :value="choice.value"
            ></v-radio>
          </v-radio-group>
        </template>
      </question>

      <!-- Question 10 -->
      <question :question="items[9]">
        <template slot-scope="props">
          <v-radio-group
            v-model="props.question.answer"
            row
            @change="props.updateErrorStatus"
            :disabled="formLocked"
          >
            <v-radio
              v-for="choice in props.question.choices"
              :key="choice.value"
              :label="choice.text"
              :value="choice.value"
            ></v-radio>
          </v-radio-group>
        </template>
      </question>

      <!-- Question 11 -->
      <question :question="items[10]">
        <template slot-scope="props">
          <v-radio-group
            v-model="props.question.answer"
            row
            @change="props.updateErrorStatus"
            :disabled="formLocked"
          >
            <v-radio
              v-for="choice in props.question.choices"
              :key="choice.value"
              :label="choice.text"
              :value="choice.value"
            ></v-radio>
          </v-radio-group>
        </template>
      </question>

      <!-- Question 12 -->
      <question :question="items[11]">
        <template slot-scope="props">
          <v-radio-group
            v-model="props.question.answer"
            row
            @change="props.updateErrorStatus"
            :disabled="formLocked"
          >
            <v-radio
              v-for="choice in props.question.choices"
              :key="choice.value"
              :label="choice.text"
              :value="choice.value"
            ></v-radio>
          </v-radio-group>
        </template>
      </question>

      <!-- Question 13 -->
      <question :question="items[12]">
        <template slot-scope="props">
          <v-checkbox
            v-for="choice in props.question.choices"
            v-model="props.question.answer"
            :key="choice.value"
            :label="choice.text"
            :value="choice.value"
            @change="props.updateErrorStatus"
            multiple
            :disabled="formLocked"
          ></v-checkbox>
        </template>
      </question>

      <!-- Question 14 -->
      <question :question="items[13]">
        <template slot-scope="props">
          <v-radio-group
            v-model="props.question.answer"
            row
            :disabled="formLocked"
            @change="props.updateErrorStatus"
          >
            <v-radio
              v-for="choice in props.question.choices"
              :key="choice.value"
              :label="choice.text"
              :value="choice.value"
            ></v-radio>
          </v-radio-group>
        </template>
      </question>

      <!-- Question 15 -->
      <question :question="items[14]">
        <template slot-scope="props">
          <v-radio-group
            v-model="props.question.answer"
            row
            @change="props.updateErrorStatus"
            :disabled="formLocked"
          >
            <v-radio
              v-for="choice in props.question.choices"
              :key="choice.value"
              :label="choice.text"
              :value="choice.value"
            ></v-radio>
          </v-radio-group>
        </template>
      </question>

      <question
        :question="items[33]"
        v-if="items[14].answer === 'na'"
        :show-number="false"
      >
        <template slot-scope="props">
          <v-col cols="4">
            <v-text-field
              v-model="props.question.answer"
              @change="props.updateErrorStatus"
              filled
              shaped
              :disabled="formLocked"
            />
          </v-col>
        </template>
      </question>

      <!-- Question 16 -->
      <question :question="items[15]">
        <template slot-scope="props">
          <v-radio-group
            v-model="props.question.answer"
            @change="props.updateErrorStatus"
            row
            disabled
          >
            <v-radio
              v-for="choice in props.question.choices"
              :key="choice.value"
              :label="choice.text"
              :value="choice.value"
            ></v-radio>
          </v-radio-group>
        </template>
      </question>

      <!-- Question 17 -->
      <question :question="items[16]">
        <template slot-scope="props">
          <v-checkbox
            v-for="choice in props.question.choices"
            v-model="props.question.answer"
            :key="choice.value"
            :label="choice.text"
            :value="choice.value"
            :multiple="true"
            @change="props.updateErrorStatus"
            :disabled="formLocked"
          ></v-checkbox>
        </template>
      </question>

      <question :question="items[35]" :show-number="false">
        <template slot-scope="props">
          <v-col cols="4">
            <v-text-field
              v-model="props.question.answer"
              filled
              shaped
              @change="props.updateErrorStatus"
              :disabled="formLocked"
            />
          </v-col>
        </template>
      </question>

      <!-- Question 18 -->
      <question :question="items[17]">
        <template slot-scope="props">
          <v-radio-group
            v-model="props.question.answer"
            row
            :disabled="formLocked"
            @change="props.updateErrorStatus"
          >
            <v-radio
              v-for="choice in props.question.choices"
              :key="choice.value"
              :label="choice.text"
              :value="choice.value"
            ></v-radio>
          </v-radio-group>
        </template>
      </question>

      <!-- Question 19 -->
      <question :question="items[18]">
        <template slot-scope="props">
          <v-radio-group
            v-model="props.question.answer"
            row
            :disabled="formLocked"
            @change="props.updateErrorStatus"
          >
            <v-radio
              v-for="choice in props.question.choices"
              :key="choice.value"
              :label="choice.text"
              :value="choice.value"
            ></v-radio>
          </v-radio-group>
        </template>
      </question>

      <!-- Question 20 -->
      <question :question="items[19]">
        <template slot-scope="props">
          <v-radio-group
            v-model="props.question.answer"
            row
            :disabled="formLocked"
            @change="props.updateErrorStatus"
          >
            <v-radio
              v-for="choice in props.question.choices"
              :key="choice.value"
              :label="choice.text"
              :value="choice.value"
            ></v-radio>
          </v-radio-group>
        </template>
      </question>

      <!-- Question 21 -->
      <question :question="items[20]">
        <template slot-scope="props">
          <v-radio-group
            v-model="props.question.answer"
            row
            :disabled="formLocked"
            @change="props.updateErrorStatus"
          >
            <v-radio
              v-for="choice in props.question.choices"
              :key="choice.value"
              :label="choice.text"
              :value="choice.value"
            ></v-radio>
          </v-radio-group>
        </template>
      </question>

      <!-- Question 22 -->
      <question :question="items[21]">
        <template slot-scope="props">
          <v-radio-group
            v-model="props.question.answer"
            row
            :disabled="formLocked"
            @change="props.updateErrorStatus"
          >
            <v-radio
              v-for="choice in props.question.choices"
              :key="choice.value"
              :label="choice.text"
              :value="choice.value"
            ></v-radio>
          </v-radio-group>
        </template>
      </question>

      <!-- Question 23 -->
      <question :question="items[22]">
        <template slot-scope="props">
          <v-checkbox
            v-for="choice in props.question.choices"
            v-model="props.question.answer"
            :key="choice.value"
            :label="choice.text"
            :value="choice.value"
            :multiple="true"
            @change="props.updateErrorStatus"
            :disabled="formLocked"
          ></v-checkbox>
        </template>
      </question>

      <question :question="items[34]" :show-number="false">
        <template slot-scope="props">
          <v-col cols="4">
            <v-text-field
              v-model="props.question.answer"
              filled
              shaped
              @change="props.updateErrorStatus"
              :disabled="formLocked"
            />
          </v-col>
        </template>
      </question>

      <!-- Question 24 -->

      <question :question="items[23]">
        <template slot-scope="props">
          <v-col cols="4">
            <v-text-field
              v-model="props.question.answer"
              filled
              shaped
              type="number"
              @change="props.updateErrorStatus"
              :disabled="formLocked"
            />
          </v-col>
        </template>
      </question>

      <v-col cols="4">
        <question :question="items[36]" :show-number="false">
          <template slot-scope="props">
            <v-text-field
              v-model="props.question.answer"
              filled
              shaped
              type="number"
              @change="props.updateErrorStatus"
              :disabled="formLocked"
            />
          </template>
        </question>
      </v-col>

      <!-- Question 25 -->
      <question :question="items[24]">
        <template slot-scope="props">
          <v-col cols="4">
            <v-text-field
              v-model="props.question.answer"
              filled
              shaped
              type="number"
              @change="props.updateErrorStatus"
              :disabled="formLocked"
            />
          </v-col>
        </template>
      </question>

      <!-- Question 26 -->
      <question :question="items[25]">
        <template slot-scope="props">
          <v-col cols="4">
            <!--            <v-text-field-->
            <!--                v-model="props.question.answer"-->
            <!--                filled-->
            <!--                shaped-->
            <!--                @change="props.updateErrorStatus"-->
            <!--            />-->
            <v-autocomplete
              v-model="props.question.answer"
              :items="unions"
              filled
              shaped
              multiple
              clearable
              @change="props.updateErrorStatus"
              :disabled="formLocked"
            ></v-autocomplete>
          </v-col>
        </template>
      </question>

      <!-- Question 27 -->
      <question :question="items[26]">
        <template slot-scope="props">
          <i>*See document uploads section.</i> |
          <a href="pdf/transnet_frequently_stolen.pdf" target="_blank" download
            >Transnet Catalog</a
          >
          |
          <a href="pdf/eskom_frequently_stolen.pdf" target="_blank" download
            >Eskom Catalog</a
          >
          |
          <a href="pdf/telkom_frequently_stolen.pdf" target="_blank" download
            >Telkom Poster</a
          >
          <v-radio-group
            v-show="false"
            v-model="props.question.answer"
            row
            :disabled="formLocked"
            @change="props.updateErrorStatus"
          >
            <v-radio
              v-for="choice in props.question.choices"
              :key="choice.value"
              :label="choice.text"
              :value="choice.value"
            ></v-radio>
          </v-radio-group>
        </template>
      </question>

      <!-- Question 28 -->
      <question :question="items[27]">
        <template slot-scope="props">
          <i>*See document uploads section.</i> |
          <a href="pdf/hazcom_posters.pdf" target="_blank" download
            >Download Posters</a
          >
          <v-radio-group
            v-show="false"
            v-model="props.question.answer"
            row
            :disabled="formLocked"
            @change="props.updateErrorStatus"
          >
            <v-radio
              v-for="choice in props.question.choices"
              :key="choice.value"
              :label="choice.text"
              :value="choice.value"
            ></v-radio>
          </v-radio-group>
        </template>
      </question>

      <!-- Question 29 -->
      <question :question="items[28]">
        <template slot-scope="props">
          <v-col cols="4">
            <v-text-field
              v-model="props.question.answer"
              filled
              shaped
              @change="props.updateErrorStatus"
              :disabled="formLocked"
            />
          </v-col>
        </template>
      </question>

      <!-- Question 30 -->
      <question :question="items[29]">
        <template slot-scope="props">
          <i>*See document uploads section.</i>
          <v-radio-group
            v-show="false"
            v-model="props.question.answer"
            row
            :disabled="formLocked"
            @change="props.updateErrorStatus"
          >
            <v-radio
              v-for="choice in props.question.choices"
              :key="choice.value"
              :label="choice.text"
              :value="choice.value"
            ></v-radio>
          </v-radio-group>
        </template>
      </question>

      <!-- Question 31 -->
      <question :question="items[30]">
        <template slot-scope="props">
          <v-radio-group
            v-model="hasISONumber"
            row
            @change="handleISOQuestionChange"
            :disabled="formLocked"
          >
            <v-radio label="Yes" :value="true" />
            <v-radio label="No" :value="false" />
          </v-radio-group>
          <v-col cols="4">
            <v-text-field
              v-show="hasISONumber"
              v-model="props.question.answer"
              filled
              shaped
              placeholder="ISO Accreditations"
              @change="props.updateErrorStatus"
              :disabled="formLocked"
            />
          </v-col>
        </template>
      </question>
    </v-card-text>
  </v-card>
</template>

<script>
import Question from "@/components/Question";
import LoadingDialog from "@/components/LoadingDialog";
import { ADD_MESSAGE } from "@/store/mutation-types";
import eventHub from "@/event-hub";
import Vue from "vue";
import SubmissionLockedAlert from "@/components/SubmissionLockedAlert";

const defaultLoadingMessage = "Loading Check List";

export default {
  name: "DealerRecyclerQuestionSheet",

  components: { SubmissionLockedAlert, Question, LoadingDialog },

  props: {
    submissionId: { type: Number, required: true },
    readOnly: { type: Boolean, default: false }
  },

  data() {
    return {
      items: [],
      loading: false,
      loadingMessage: defaultLoadingMessage,
      hasExportNumber: false,
      hasISONumber: false,
      hasVATNumber: false,
      unions: require("@/data/unions.json"),
      fieldErrors: false,
      rules: {
        vatNo: [
          value =>
            !this.hasVATNumber ||
            (!!value && value.length === 10 && value.charAt(0) === "4") ||
            "Please enter a valid VAT number"
        ]
      }
    };
  },

  computed: {
    url() {
      return `/inspection/submissions/${this.submissionId}/questions/`;
    },
    formLocked() {
      return this.readOnly || this.loading;
    },
    user() {
      return this.$store.state.auth.user;
    },
    isDealer() {
      return this.items[0].answer === "yes";
    },
    isRecycler() {
      return this.items[1].answer === "yes";
    },
    hasElectronicRegister() {
      return this.items[4].answer === "electronic";
    },
    hasOffSiteRegisterBackup() {
      return this.items[38].answer === "yes";
    }
  },

  async mounted() {
    await this.fetchItems();
    this.prePopulateAnswers();
    Vue.nextTick(() => {
      eventHub.$emit("QuestionSheet.ready");
    });
  },

  created() {
    eventHub.$on("Submission.saveQuestions", this.save);
  },

  beforeDestroy() {
    eventHub.$off("Submission.saveQuestions");
  },

  methods: {
    fetchItems() {
      this.loadingMessage = defaultLoadingMessage;
      this.loading = true;

      return this.$http
        .get(this.url)
        .then(response => {
          this.items = response.data;
          this.hasISONumber = !!this.items[30].answer;
          this.hasVATNumber = !!this.items[2].answer;
          return Promise.resolve(response);
        })
        .catch(() => {
          this.$store.commit(`theme/${ADD_MESSAGE}`, {
            type: "error",
            text: "There was an error loading this submission"
          });
          return Promise.reject();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    save() {
      if (this.fieldErrors) {
        this.$store.commit(`theme/${ADD_MESSAGE}`, {
          text: "The form could not be saved, please check for errors.",
          type: "error"
        });
        return;
      }
      this.loadingMessage = "Saving Check List...";
      this.loading = true;

      // Set default question answers
      // TODO these assumptions should be forced at a database level
      this.items[31].answer = "yes"; // Q 32
      this.items[29].answer = "yes"; // Q 30
      this.items[26].answer = "yes"; // Q 27
      this.items[27].answer = "yes"; // Q 38
      this.items[5].answer = "yes"; // Q 6

      this.$http
        .patch(this.url, this.items)
        .then(response => {
          this.items = response.data;
          this.$store.commit(`theme/${ADD_MESSAGE}`, {
            type: "success",
            text: "Submission saved."
          });
          eventHub.$emit("QuestionSheet.updated", this.items);
        })
        .catch(() => {
          this.$store.commit(`theme/${ADD_MESSAGE}`, {
            type: "error",
            text: "Unable to save your data."
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getQuestion(questionNumber) {
      if (this.item.answers) {
        return this.item.answers.filter(answer => {
          return answer.question_number === questionNumber;
        })[0];
      } else {
        return {};
      }
    },
    emitQuestionUpdate(item) {
      eventHub.$emit("QuestionSheet.questionUpdated", item);
    },
    handleISOQuestionChange(value) {
      if (!value) this.clearQuestion(33);
    },
    handleVATQuestionChange(value) {
      if (!value) this.clearQuestion(2);
    },
    setFieldErrors(value) {
      this.fieldErrors = value;
    },
    prePopulateAnswers() {
      const question9 = this.items[15];
      if (!question9.answer && this.user.profile) {
        question9.answer =
          this.user.profile.classification === "trader"
            ? "desk_trader"
            : "dealer_recycler";
      }
    },
    clearQuestion(questionNumber) {
      const question = this.items[questionNumber];
      switch (question.type) {
        case "text":
          question.answer = "";
          break;
        case "bool":
          question.answer = "no";
          break;
        default:
          question.answer = null;
      }
    }
  }
};
</script>
