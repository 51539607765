<template>
  <v-card class="login-card elevation-5" :loading="loading">

    <template slot="progress">
      <v-progress-linear
          color="primary"
          height="10"
          indeterminate
      ></v-progress-linear>
    </template>

    <v-form class="pa-4" @submit.prevent="login()">
      <non-field-errors :errors="errors" />
      <v-card-text>
        <v-img
            class="mt4"
            height="150"
            contain
            src="@/assets/log_full.webp"
        ></v-img>
        <v-container class="fields">
          <v-row>
            <v-col cols="12">
              <v-text-field
                  v-model="payload.username"
                  prepend-icon="mdi-account"
                  name="username"
                  label="Username or Email"
                  type="text"
                  placeholder=" "
                  :error-messages="errors.username"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                  id="password"
                  v-model="payload.password"
                  prepend-icon="mdi-lock"
                  name="password"
                  label="Password"
                  placeholder=" "
                  :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPass ? 'text' : 'password'"
                  :error-messages="errors.password"
                  @click:append="showPass = !showPass"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>

        <v-card-actions>
          <a href="/#/reset-password-request">
            Forgot password?
          </a>
          <v-spacer></v-spacer>
          <v-btn type="submit" color="primary" :disabled="loading" large>Login</v-btn>
        </v-card-actions>
      </v-card-text>
    </v-form>
  </v-card>
</template>

<script>
import loginMixin from "@/mixins/login";
import NonFieldErrors from "@/components/NonFieldErrors";

export default {
  name: "Login",
  components: {NonFieldErrors},
  mixins: [loginMixin],

  props: {
    propMessage: {type: String, default: ""}
  },

  data() {
    return {
      showPass: false,
      payload: {
        username: "",
        password: ""
      }
    };
  },

  computed: {
    loading() {
      return this.$store.state.auth.loading;
    },

    errors() {
      return this.$store.state.auth.errors;
    }
  },
  methods: {}
};
</script>

<style>
.login-card {
  margin-top: 24px;
}

.login-card .v-input__control {
  margin-right: 24px;
}

.login-card .v-input__append-inner {
  position: absolute;
  right: -30px;
}

.login-card .v-card__actions {
  padding-left: 36px;
  padding-right: 36px;
}

.login-card .v-btn.forgot-password {
  padding: 0;
}
</style>
