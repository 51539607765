<template>
  <v-dialog
      :value="isOpen"
      fullscreen
      scrollable
      persistent
      @click:outside="close"
  >
    <v-card>
      <v-card-title>
        {{ dialogTitle }} | <v-btn link text :href="url" download><v-icon left>mdi-download</v-icon> DOWNLOAD A COPY</v-btn>
        <v-spacer/>
        <slot name="app-bar-right">

        </slot>
        <v-btn icon @click.stop="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-divider/>

      <v-card-text :id="scrollElementId">
        <template v-if="src && !loading">
          <pdf
              v-for="i in numPages"
              :key="i"
              :src="src"
              :page="i"
          ></pdf>
        </template>
      </v-card-text>

      <v-divider class/>

      <v-card-actions>
        <v-checkbox
            @click="emitConfirmation"
            v-model="checkboxValue"
            label="I HAVE READ AND ACCEPT THESE GUIDELINES"
            :disabled="!!(!hasReadDocument & !checkboxValue)"
        />
        <v-spacer />
        <p v-if="!checkboxValue">
          <v-icon left>mdi-alert-circle-outline</v-icon>
          Please READ the document and scroll all the way to the bottom and click accept
        </p>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import pdf from "vue-pdf";
import Vue from "vue";
import eventHub from "@/event-hub";

export default {
  name: "PDFViewer",
  components: {
    pdf
  },
  props: {
    dialogTitle: {type: String, default: "Title"},
    url: {type: String, required: true},
    document: {type: String, required: true},
    value: {type: Boolean, required: false, default: false}
  },

  mounted() {
    this.loading = true;
    this.src = pdf.createLoadingTask(this.url);
    this.src.promise.then(pdf => {
      this.numPages = pdf.numPages;
    }).finally(() => {
      this.loading = false;
    });
  },

  beforeDestroy() {
    const scrollBody = document.getElementById(this.scrollElementId);

    if (scrollBody)
      scrollBody.removeEventListener("scroll", this.scroll);
  },

  data() {
    return {
      isOpen: false,
      src: null,
      numPages: undefined,
      loading: false,
      checkboxValue: false,
      hasReadDocument: false
    };
  },

  computed: {
    scrollElementId() {
      return `scroll_body_${this.document}`;
    }
  },

  methods: {

    open() {
      this.isOpen = true;
      this.checkboxValue = this.value;
      Vue.nextTick(() => {
        const scrollBody = document.getElementById(this.scrollElementId);
        scrollBody.addEventListener("scroll", this.scroll);
      });

    },

    close() {
      if (this.isOpen) {
        this.isOpen = false;
        const scrollBody = document.getElementById(this.scrollElementId);
        scrollBody.removeEventListener("scroll", this.scroll);
      }
    },

    scroll() {
      const scrollBody = document.getElementById(this.scrollElementId);
      if (scrollBody.scrollHeight <=
          scrollBody.scrollTop +
          window.innerHeight && !this.hasReadDocument) {
        this.hasReadDocument = true;
      }
    },

    emitConfirmation() {
      eventHub.$emit("PDFViewer.accepted", {[this.document]: this.checkboxValue});
      this.close();
    }

  }
};
</script>

<style scoped>
.v-card__actions {
  padding-left: 25px;
  padding-right: 25px;
}
</style>
