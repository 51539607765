<template>
  <v-tooltip v-if="statusIcon" top>
    <template v-slot:activator="{ on }">
      <v-icon left :title="statusText" :color="statusColor" v-on="on">{{ statusIcon }}</v-icon>
    </template>
    <span>{{ statusText }}</span>
  </v-tooltip>
</template>

<script>
import statusMixin from "../../mixins/status";

export default {
  name: "SubmissionStatusIcon",

  props: {
    size: {type: String, default: ""},
    status: {type: String, required: true},
    showStatusColor: {type: Boolean, default: false},
    dark: {type: Boolean, default: false}
  },

  mixins: [statusMixin],

  computed: {

    statusIcon() {
      return this.getStatusIcon(this.status);
    },

    statusText() {
      return this.getStatusText(this.status);
    },

    statusColor() {
      if (this.showStatusColor) {
        return this.getStatusColor(this.status);
      }
      return this.dark ? "white" : "gray" ;
    }

  }
};
</script>
