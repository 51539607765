<template>
  <div>
    <v-container v-if="!readOnly">
      <v-btn @click="$refs.newLog.open()" depressed color="info">New Disciplinary Action</v-btn>
    </v-container>
    <disciplinary-log-list model-name="changeNotification"/>
    <new-disciplinary-log-modal ref="newLog" />
  </div>
</template>

<script>
import routePrefix from "../../mixins/routePrefix";
import DisciplinaryLogList from "../../components/disciplinary-log/DisciplinaryLogList";
import NewDisciplinaryLogModal from "../../components/disciplinary-log/NewDisciplinaryLogModal";

export default {
  name: "DisciplinaryLogIndex",
  components: {DisciplinaryLogList, NewDisciplinaryLogModal},
  mixins: [routePrefix],
  data(){
    return {
      form:{}
    };
  },
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
    readOnly(){
      return !this.user.is_staff;
    }
  }
};
</script>

<style scoped>

</style>
