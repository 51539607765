<template>
  <div>
    <v-container v-if="!readOnly">
      <v-btn to="/admin/saps-reports/generate" depressed color="info">Generate Report</v-btn>
    </v-container>
    <saps-report-list model-name="report"/>
  </div>
</template>

<script>
import routePrefix from "../../mixins/routePrefix";
import SapsReportList from "../../components/saps-report/SapsReportList";

export default {
  name: "SapsReportIndex",
  components: {SapsReportList},
  mixins: [routePrefix],
  data(){
    return {
      form:{}
    };
  },
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
    readOnly(){
      return !this.user.is_staff;
    }
  }
};
</script>

<style scoped>

</style>
