<template>
  <div>
    <v-form ref="profileForm" @submit.prevent="onFormSubmit()">
      <v-card-title>
        Account Details
      </v-card-title>
      <v-card-text>
        <v-text-field
          v-model="form.email"
          label="Email"
          filled
          shaped
          :disabled="readOnly"
          :error-messages="errors.email"
        >
        </v-text-field>
        <v-text-field
          v-model="form.member_no"
          label="Member No"
          filled
          shaped
          :disabled="readOnly"
          :error-messages="errors.member_no"
        >
        </v-text-field>
        <v-checkbox
          v-if="!readOnly"
          label="Member is Active?"
          v-model="form.is_active"
          :error-messages="errors.is_active"
        />
        <v-checkbox
          v-if="!readOnly && !form.is_main_branch"
          label="Enable Email Communication"
          v-model="form.communication_enabled"
          :error-messages="errors.commuinication_enabled"
        />

        <!-- MEMBER CLASSIFICATION -->
        <label>Member Classification</label>
        <v-radio-group v-model="form.classification" :disabled="readOnly">
          <v-radio
            v-for="classification in classificationChoices"
            :key="classification.value"
            :label="classification.text"
            :value="classification.value"
          ></v-radio>
        </v-radio-group>

        <template v-if="branches.length > 0 && user.is_staff">
          Other Branches:
          <v-list-item
            v-for="branch in branches"
            link
            :key="branch.id"
            @click="openOtherBranch(branch.id)"
          >
            {{ branch.company }}
          </v-list-item>
        </template>
      </v-card-text>

      <v-card-title>
        Company Details
      </v-card-title>
      <v-card-text>
        <v-text-field
          v-model="form.company"
          label="Company Name *"
          aria-required="true"
          filled
          shaped
          :disabled="readOnly"
          :error-messages="errors.company"
        >
        </v-text-field>

        <v-text-field
          v-model="form.trading_name"
          label="Trading Name"
          filled
          shaped
          :disabled="readOnly"
          hint="If different from company name"
          persistent-hint
          :error-messages="errors.trading_name"
        >
        </v-text-field>

        <v-text-field
          v-model="form.no_of_branches"
          label="No of branches *"
          filled
          shaped
          :disabled="readOnly"
          type="number"
          :error-messages="errors.no_of_branches"
        >
        </v-text-field>
      </v-card-text>
      <v-card-title>
        Contact Details
      </v-card-title>
      <v-card-text>
        <v-text-field
          v-model="form.contact_name"
          label="Contact Name (Person responsible for submissions)*"
          filled
          shaped
          :disabled="readOnly"
          :error-messages="errors.contact_name"
        >
        </v-text-field>
        <v-text-field
          v-model="form.telephone_number"
          label="Tel/Cell No *"
          filled
          shaped
          :disabled="readOnly"
          :error-messages="errors.telephone_number"
        >
        </v-text-field>
      </v-card-text>

      <v-card-title>
        Address
      </v-card-title>
      <v-card-text>
        <v-text-field
          v-model="form.address_line_1"
          label="Address Line 1 *"
          placeholder=""
          aria-required="true"
          :error-messages="errors.address_line_1"
          filled
          shaped
          :disabled="readOnly"
        ></v-text-field>
        <v-text-field
          v-model="form.address_line_2"
          label="Address Line 2"
          placeholder=""
          aria-required="true"
          :error-messages="errors.address_line_2"
          filled
          shaped
          :disabled="readOnly"
        ></v-text-field>
        <v-text-field
          v-model="form.city"
          label="City/Town"
          placeholder=""
          aria-required="true"
          :error-messages="errors.city"
          filled
          shaped
          :disabled="readOnly"
        ></v-text-field>
        <v-select
          v-model="form.province"
          :items="provinceChoices"
          label="Province *"
          placeholder=""
          aria-required="true"
          :error-messages="errors.province"
          filled
          shaped
          :disabled="readOnly"
        ></v-select>
        <v-text-field
          v-model="form.code"
          label="Code"
          placeholder=""
          aria-required="true"
          :error-messages="errors.code"
          filled
          shaped
          :disabled="readOnly"
        ></v-text-field>
      </v-card-text>
      <input type="submit" hidden />
    </v-form>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        v-if="!readOnly"
        color="info"
        block
        :loading="loading"
        x-large
        @click="onFormSubmit"
      >
        SAVE
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import { ADD_MESSAGE, SET_LIST } from "@/store/mutation-types";
import { updateListItem } from "@/utils/list";

function newForm() {
  return {
    user: null,
    company: null,
    trading_name: null,
    address_line_1: null,
    address_line_2: null,
    city: null,
    province: null,
    code: null,
    no_of_branches: 1,
    contact_name: null,
    telephone_number: null,
    is_active: true,
    communication_enabled: true,
    classification: "dealer_recycler"
  };
}

export default {
  name: "ProfileForm",

  data() {
    return {
      loading: false,
      form: newForm(),
      errors: {},
      branches: [],
      provinceChoices: require("@/data/provinces.json"),
      classificationChoices: require("@/data/classification.json")
    };
  },

  async mounted() {
    if (!this.isNewItem) {
      await this.fetchItem();
      this.fetchBranches();
    }
  },

  computed: {
    user() {
      return this.$store.state.auth.user;
    },
    isNewItem() {
      return (
        !(this.form && this.form.id) && this.$route.params.profile_id === "new"
      );
    },
    urlPrefix() {
      return this.user.is_staff
        ? "/account/admin/profiles/"
        : "/account/profiles/";
    },
    readOnly() {
      return !this.user.is_staff;
    }
  },

  methods: {
    fetchItem() {
      if (this.$route.params.profile_id && this.user.is_staff) {
        this.loading = true;
        this.$http
          .get(`${this.urlPrefix}${this.$route.params.profile_id}/`)
          .then(response => {
            this.form = response.data;
          })
          .catch(() => {
            this.$store.commit(`theme/${ADD_MESSAGE}`, {
              type: "error",
              text: "Unable to fetch user profile"
            });
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.form = { ...this.user.profile } || newForm();
      }
    },

    fetchBranches() {
      if (this.$route.params.profile_id) {
        this.$http
          .get(`${this.urlPrefix}${this.$route.params.profile_id}/branches/`)
          .then(response => {
            this.branches = response.data;
          });
      }
    },

    fetchOptions() {
      this.$store.dispatch("profile/options");
    },

    async onFormSubmit() {
      this.loading = true;
      this.form.user =
        this.$route.params.profile_id && this.user.is_staff
          ? this.form.user
          : this.user.id;
      try {
        const response = this.isNewItem
          ? await this.$http.post(this.urlPrefix, this.form)
          : await this.$http.put(
              `${this.urlPrefix}${this.form.id}/`,
              this.form
            );
        this.$store.commit("auth/SET_USER", {
          ...this.user,
          profile: response.data
        });
        this.form = response.data;
        this.$store.commit(
          `profile/${SET_LIST}`,
          updateListItem([...this.$store.state.profile.list], this.form)
        );
        this.errors = [];
        this.$store.commit(`theme/${ADD_MESSAGE}`, {
          type: "success",
          text: "Profile saved."
        });

        await this.$router.back();
      } catch (error) {
        this.errors = error.response.data;
        this.$store.commit(`theme/${ADD_MESSAGE}`, {
          type: "error",
          text: "Profile save failed."
        });
      } finally {
        this.loading = false;
      }
    },

    async openOtherBranch(id) {
      this.loading = true;
      await this.$router.push("/admin/members/");
      await this.$router.push(`/admin/members/${id}`);
      this.loading = false;
    }
  }
};
</script>
