<template>
  <v-form @submit.prevent="updateSubmissionFilters">
    <v-list-item>
      <v-list-item-title>
        FILTERS
      </v-list-item-title>
    </v-list-item>
    <v-list-item>
      <v-text-field dense v-model="tableFilters.id" outlined label="Ref No" hide-details />
    </v-list-item>
    <v-list-item>
      <profile-select @change="updateProfileFilter" ref="profileSelect"/>
    </v-list-item>
    <v-list-item>
      <v-select
          dense
          :items="statusChoices"
          placeholder="Status"
          hide-details
          v-model="tableFilters.status"
          outlined
          clearable
      />
    </v-list-item>
    <v-list-item>
      <v-select
          dense
          :items="yearItems"
          placeholder="Year"
          hide-details
          v-model="tableFilters.year"
          outlined
          clearable
      />
    </v-list-item>
    <v-list-item>
      <v-btn color="info" :disabled="loading" small outlined @click="clearFilters">Clear</v-btn>
      <v-spacer/>
      <v-btn type="submit" :disabled="loading" color="primary" small outlined>Search</v-btn>
    </v-list-item>
  </v-form>
</template>

<script>
import dataTableMixin from "@/mixins/dataTable";
import {ADD_MESSAGE} from "@/store/mutation-types";
import ProfileSelect from "@/components/profile/ProfileSelect";

function initialFilters() {
  return {
    id: "",
    user: "",
    status: "",
    year: ""
  };
}

export default {
  name: "SubmissionFilter",
  components: {ProfileSelect},
  mixins: [dataTableMixin],
  data() {
    return {
      statusChoices: require("@/data/submission_status.json"),
      tableFilters: initialFilters()
    };
  },
  methods: {
    async fetchItems() {
      try {
        await this.$store.dispatch("submission/list");
      } catch (e) {
        this.$store.commit(`theme/${ADD_MESSAGE}`, {text: "Unable to load submissions", type: "error"});
      }
    },
    clearFilters() {
      this.$refs.profileSelect.clear();
      this.tableFilters = initialFilters();
      this.updateFilters();
    },
    updateProfileFilter(selection) {
      this.tableFilters.user = selection;
    },
    updateSubmissionFilters(){
      this.tableFilters.id = this.tableFilters.id.replace(/^0+/, "");
      this.updateFilters();
    }
  },
  computed: {
    loading() {
      return this.$store.getters["submission/loading"]("list");
    },
    currentYear() {
      return new Date().getFullYear();
    },
    yearItems() {
      let items = [];
      for (let startYear = 2021; startYear <= this.currentYear; startYear++) {
        items.push(startYear);
      }
      return items;
    }
  }
};
</script>

<style scoped>

</style>