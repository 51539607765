<template>
  <v-card class="reset-password-card elevation-5" :loading="sendingRequest">
    <template slot="progress">
      <v-progress-linear
          color="primary"
          height="10"
          indeterminate
      ></v-progress-linear>
    </template>
    <div class="pa-4">
      <v-card-title>
        Reset Password
      </v-card-title>
      <v-card-text>
        <v-text-field
            v-if="!showMessage"
            v-model="form.email"
            prepend-icon="mdi-account"
            name="email"
            label="Email"
            type="text"
            :error-messages="formErrors.email"
        ></v-text-field>
        <template v-if="showMessage">
          <p>We have sent you an email with a link to reset your password.</p>
          <p>
            Didn't receive the email yet? Please check your spam folder, or
            <span class="resend" @click="onResendClick">resend</span> the email.
          </p>
        </template>
      </v-card-text>
      <v-card-actions v-if="!showMessage">
        <v-btn to="/login" text color="accent">
          <v-icon left>mdi-arrow-left-bold</v-icon>
          Return to login
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
            color="primary"
            :disabled="sendingRequest"
            large
            @click.stop="onClickResetPasswordButton"
        >Reset Password
        </v-btn>
      </v-card-actions>
    </div>
  </v-card>
</template>

<script>
import axios from "axios";

export default {
  name: "ResetPasswordRequest",
  data() {
    return {
      sendingRequest: false,
      showMessage: false,

      form: {
        email: ""
      },

      formErrors: {}
    };
  },

  methods: {
    /*** Event handlers ***/
    onClickResetPasswordButton() {
      this.sendResetPasswordRequest();
    },

    onResendClick() {
      this.form.email = "";
      this.formErrors = {};
      this.showMessage = false;
    },
    /*** Helper functions ***/
    async sendResetPasswordRequest() {
      this.sendingRequest = true;

      try {
        await axios.post(process.env.VUE_APP_API_BASE_URL + "/account/users/reset-password-request/", this.form);
        this.showMessage = true;
      } catch (error) {
        this.formErrors = error.response.data;
      } finally {
        this.sendingRequest = false;
      }
    }
  }
};
</script>

<style scoped>
.reset-password-card {
  margin-top: 24px;
}

.resend {
  color: var(--v-primary-base);
  text-decoration: underline;
  cursor: pointer;
}
</style>
