<template>
  <main-layout>
    <template v-slot:menu-drawer>
      <SAPS-menu></SAPS-menu>
    </template>
    <router-view></router-view>
  </main-layout>
</template>

<script>
import MainLayout from "@/layouts/MainLayout";
import SAPSMenu from "@/components/saps/SAPSMenu";

export default {
  name: "SAPSLayout",
  components: {SAPSMenu, MainLayout}
};
</script>
