<template>
  <v-navigation-drawer v-model="drawer" app>
    <template v-slot:prepend>
      <v-list-item v-if="user && user.profile">
        <v-list-item-avatar tile>
          <v-img
              :src="require('@/assets/logo.webp')"
              alt="mra"
          ></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{ user.profile.company }}</v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
        </v-list-item-action>
      </v-list-item>
    </template>

    <v-divider></v-divider>

    <v-list>
      <!-- MENU ITEM: HOME -->
      <v-list-item link to="/" color="primary">
        <v-list-item-icon>
          <v-icon>mdi-home</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Home</v-list-item-title>
      </v-list-item>

      <!-- MENU ITEM: PROFILE -->
      <v-list-item link to="/profile" color="primary">
        <v-list-item-icon>
          <v-icon>mdi-account-multiple</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Profile</v-list-item-title>
      </v-list-item>

      <!-- MENU ITEM: DOCUMENT LIBRARY -->
      <v-list-item link @click="$refs.media_popup.open()" color="primary">
        <v-list-item-icon>
          <v-icon>mdi-file-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Shared Documents</v-list-item-title>
      </v-list-item>

      <!-- MENU ITEM: CHANGE NOTIFICATIONS -->
      <v-list-item link to="/change-notifications" color="primary">
        <v-list-item-icon>
          <v-icon>mdi-information-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Update Information</v-list-item-title>
      </v-list-item>

    <!-- MENU ITEM: DOCUMENT LIBRARY -->
    <v-list-item link href="https://adminout.gitlab.io/mra-inspection/" color="primary" target="_blank">
      <v-list-item-icon>
        <v-icon>mdi-help-circle-outline</v-icon>
      </v-list-item-icon>
      <v-list-item-title>Help</v-list-item-title>
    </v-list-item>
    </v-list>

    <!-- MENU ITEM: SUBMISSION DETAIL -->
    <submission-menu v-if="submissionDetail" :submission="submissionDetail"/>

    <media-popup ref="media_popup" model-name="media"/>
  </v-navigation-drawer>
</template>

<script>
import eventHub from "@/event-hub";
import {SET_DRAWER} from "@/store/mutation-types";
import MediaPopup from "@/components/media/MediaPopup";
import SubmissionMenu from "@/components/submission/SubmissionMenu";

export default {
  name: "MainMenu",

  components: {MediaPopup, SubmissionMenu},

  data() {
    return {
      submissionDetail: null
    };
  },

  created() {
    eventHub.$on("toggle_drawer", this.toggleDrawer);
    eventHub.$on("Menu.setSubmissionDetail", this.setSubmissionDetail);
  },

  beforeDestroy() {
    eventHub.$off("toggle_drawer");
    eventHub.$off("Menu.setSubmissionDetail");
  },

  computed: {
    mini() {
      return this.$store.state.theme.miniMenu;
    },

    user() {
      return this.$store.state.auth.user;
    },

    drawer: {
      get() {
        return this.$store.state.theme.drawer;
      },
      set(value) {
        this.$store.commit(`theme/${SET_DRAWER}`, value);
      }
    }
  },

  methods: {
    toggleDrawer() {
      this.$store.commit(`theme/${SET_DRAWER}`, !this.drawer);
    },
    setSubmissionDetail(submission) {
      this.submissionDetail = submission;
    }
  }
};
</script>

<style scoped></style>
