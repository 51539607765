<template>
  <v-list two-line>

    <loading-dialog v-if="downloadBusy" message="Downloading file..."></loading-dialog>
    <v-data-iterator
        :items="items"
        :loading="isLoading"
        :footer-props="{
          'items-per-page-options': itemsPerPageOptions
        }"
        :server-items-length="totalItems"
        disable-filtering
        disable-sort
        class="pa-2"
        :options.sync="tableFilters"
        @update:options="updateFilters"
    >

      <v-list-item v-for="item in items" :key="item.id" @click="openSubmission(item)">
        <v-list-item-content>
          <v-list-item-title>
            {{ item.company }} {{ new Date(item.due_date).getFullYear() }}
          </v-list-item-title>
          <v-list-item-subtitle>
            Due: {{ item.due_date }} <b>REF: {{ item.reference_no }}</b> |
            <submission-status-badge :status="item.status" :show-status-color="true"/>
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-content>
          <v-stepper v-model="step" flat class="mini-stepper">
            <v-stepper-header :elevation="0">
              <v-stepper-step :step="1" :editable="false" :complete="item.questions_completed >= 1">
                Check List
                <small>{{ formatPercentage(item.questions_completed * 100) }} %</small>
              </v-stepper-step>
              <v-divider/>
              <v-stepper-step :step="2" :editable="false" :complete="item.documents_uploaded >= 1">
                Documents
                <small>{{
                    formatPercentage(item.documents_uploaded * 100)
                  }} %</small>
              </v-stepper-step>
              <v-divider/>
              <v-stepper-step :step="3" :editable="false" :complete="item.regulation_requirements_completed >= 1">
                Regulatory
                <small>{{ formatPercentage(item.regulation_requirements_completed * 100) }} %</small>
              </v-stepper-step>
            </v-stepper-header>
          </v-stepper>
        </v-list-item-content>
        <v-list-item-action>
          <!-- ITEM MENU -->
          <v-menu bottom left>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on" @click.native.stop>
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item link @click="openSubmission(item)">
                <v-list-item-title>
                  <v-icon left>mdi-pencil</v-icon>
                  View
                </v-list-item-title>
              </v-list-item>
              <v-list-item v-if="item.status === 'complete'" link
                           @click.prevent="downloadMembershipCertificate(item)"
                           target="_blank">
                <v-list-item-title>
                  <v-icon left>mdi-file-pdf</v-icon>
                  Download Membership certificate
                </v-list-item-title>
              </v-list-item>

              <v-list-item link @click.prevent="downloadApplicationForm(item)">
                <v-list-item-title>
                  <v-icon left>mdi-file-pdf</v-icon>
                  Download Application Form
                </v-list-item-title>
              </v-list-item>
              <!-- STAFF OPTIONS -->
              <template v-if="user.is_staff">
                <v-list-item link @click.prevent="sendReminder(item)">
                  <v-list-item-title>
                    <v-icon left>mdi-send</v-icon>
                    Send Reminder
                  </v-list-item-title>
                </v-list-item>
                <v-list-item link @click.prevent="deleteSubmission(item)">
                  <v-list-item-title>
                    <v-icon left>mdi-delete</v-icon>
                    Delete
                  </v-list-item-title>
                </v-list-item>
              </template>
            </v-list>
          </v-menu>
        </v-list-item-action>
      </v-list-item>
    </v-data-iterator>

  </v-list>
</template>

<script>
import routePrefixMixin from "@/mixins/routePrefix";
import statusMixin from "@/mixins/status";
import downloadMixin from "@/mixins/download";
import LoadingDialog from "@/components/LoadingDialog";
import numberMixin from "@/mixins/numbers";
import {ADD_MESSAGE, SET_LIST} from "@/store/mutation-types";
import dataTableMixin from "@/mixins/dataTable";
import listMixin from "@/mixins/list";
import SubmissionStatusBadge from "@/components/submission/SubmissionStatusBadge";

export default {
  name: "Submissions",
  components: {SubmissionStatusBadge, LoadingDialog},
  mixins: [routePrefixMixin, statusMixin, downloadMixin, numberMixin, dataTableMixin, listMixin],

  data() {
    return {
      step: 0
    };
  },

  computed: {

    isLoading() {
      return this.$store.getters["submission/loading"]("list");
    },

    items() {
      return this.$store.state.submission.list;
    },

    user() {
      return this.$store.state.auth.user;
    }
  },

  methods: {
    fetchItems() {
      return this.$store.dispatch("submission/list");
    },

    openSubmission(item) {
      this.$router.push(`${this.routePrefix}/submission/${item.id}`);
    },

    sendReminder(item) {
      this.$http.post(`/inspection/submissions/${item.id}/email-reminder/`).then(() => {
        this.$store.commit(`theme/${ADD_MESSAGE}`, {text: "Email Reminder Sent.", type: "success"});
      }).catch(() => {
        this.$store.commit(`theme/${ADD_MESSAGE}`, {text: "Email Reminder Failed.", type: "error"});
      });
    },

    deleteSubmission(item) {
      this.$store.dispatch("submission/delete", item.id).then(() => {
        const newList = [...this.$store.state.submission.list];
        this.deleteListItem(newList, item);
        this.$store.commit(`submission/${SET_LIST}`, newList);
        this.$store.commit(`theme/${ADD_MESSAGE}`, {text: "Submission deleted", type: "success"});
      }).catch(() => {
        this.$store.commit(`theme/${ADD_MESSAGE}`, {text: "Submission was not deleted", type: "error"});
      });
    }

  }
};
</script>

<style scoped>


.v-stepper {
  box-shadow: none;
  background: none;
}

.v-stepper__header {
  height: auto;

}

.v-stepper__step {
  padding-top: 0;
  padding-bottom: 0;
}

.theme--light.v-stepper {
  background: none;
}

.v-list--two-line .v-list-item .v-list-item__icon, .v-list-item--two-line .v-list-item__icon {
  margin-bottom: 2%;
}

</style>
