<template>
  <v-container>
    <!-- COMPANY SUMMARY -->
    <one-column-row>
      <v-card tile v-if="profile.company">
        <v-card-title>
          Company Profile | {{ profile.company }} <span v-if="!profile.is_active" style="color: red"> (INACTIVE)</span>
          <v-spacer/>
        </v-card-title>
        <v-card-subtitle v-if="profile.is_main_branch">
          <span>Main Branch</span>
        </v-card-subtitle>
        <v-card-text>
          <profile-table :profile="profile"/>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn text xs :to="{name: 'AdminMemberEdit', params: {profile_id: profile.id}}">
            <v-icon left>mdi-pencil</v-icon>
            View or edit full company profile
          </v-btn>
        </v-card-actions>
      </v-card>
    </one-column-row>

    <!-- SUBMISSIONS -->
    <one-column-row>
      <v-card tile>
        <v-card-title>
          Inspections
          <v-spacer/>
          <v-btn @click="$store.dispatch('submission/list')" text title="Refresh">
            <v-icon left>mdi-refresh</v-icon>
            Refresh Table
          </v-btn>
        </v-card-title>
        <v-card-subtitle>
          Please select an inspection for more details
        </v-card-subtitle>
        <v-divider/>
        <v-card-text>
          <SubmissionList model-name="submission"/>
        </v-card-text>
      </v-card>
    </one-column-row>
  </v-container>
</template>

<script>
import SubmissionList from "@/components/submission/SubmissionList";
import OneColumnRow from "@/layouts/components/OneColumnRow";
import ProfileTable from "@/components/profile/ProfileTable";
import {ADD_FILTERS, ADD_MESSAGE, SET_LIST} from "@/store/mutation-types";

export default {
  name: "AdminMemberDetail",
  components: {
    ProfileTable,
    OneColumnRow,
    SubmissionList
  },

  data() {
    return {
      profile: {},
      branches: []
    };
  },

  async created() {
    this.$store.commit(`submission/${SET_LIST}`, []);
    await this.fetchItem();
  },

  mounted() {

    this.fetchBranches();
  },

  beforeDestroy() {
    this.$store.commit(`submission/${ADD_FILTERS}`, {user: "" });
  },

  computed: {
    urlPrefix() {
      return "/account/admin/profiles/";
    },
  },

  methods: {

    fetchItem() {
      if (this.$route.params.profile_id) {
        this.loading = true;
        this.$http.get(`${this.urlPrefix}${this.$route.params.profile_id}/`).then((response) => {
          this.profile = response.data;
          this.$store.commit(`submission/${ADD_FILTERS}`, {user: this.profile.user });
        }).catch(() => {
          this.$store.commit(`theme/${ADD_MESSAGE}`, {type: "error", text: "Unable to fetch user profile"});
        }).finally(() => {
          this.loading = false;
        });
      }
    },

    fetchBranches() {
      if (this.$route.params.profile_id) {
        this.$http.get(`${this.urlPrefix}${this.$route.params.profile_id}/branches/`).then((response) => {
          this.branches = response.data;
        });
      }
    },
    
  }
};
</script>
