<template>
  <v-container>
    <one-column-row>
      <v-card tile :loading="isLoading">
        <v-card-text>
          <!-- SUBMISSIONS LIST -->
          <SubmissionList model-name="submission"/>
        </v-card-text>
      </v-card>
    </one-column-row>
  </v-container>
</template>

<script>
import SubmissionList from "@/components/submission/SubmissionList";
import OneColumnRow from "@/layouts/components/OneColumnRow";

export default {
  name: "Submissions",
  components: {
    OneColumnRow,
    SubmissionList
  },
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
    isLoading() {
      return this.$store.getters["submission/loading"]("list");
    }
  },
  methods: {
    editProfile() {
      this.$router.push("/profile");
    }
  }
};
</script>
