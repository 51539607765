<template>
  <v-row justify="start">
    <v-col cols="12">
        <slot/>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "OneColumnRow",
};
</script>

<style scoped>

</style>
