<template>
  <v-container>
    <one-column-row>
      <v-card tile>
        <v-card-text>
          <v-btn
            :style="{
              visibility: selected.length > 0 ? 'visible' : 'hidden'
            }"
            small
            :disabled="isLoading"
            @click="emailComplianceNotifications"
          >
            Email Compliance Notifications
          </v-btn>
          <v-data-table
            v-model="selected"
            class="pa-2"
            :headers="headers"
            :items="items"
            :loading="isLoading"
            :footer-props="{ 'items-per-page-options': itemsPerPageOptions }"
            :options.sync="tableFilters"
            @update:options="updateFilters"
            :server-items-length="totalItems"
            show-select
            disable-filtering
            disable-sort
            item-key="id"
          >
            <!-- NAME -->
            <template v-slot:item.company="{ item }">
              <router-link
                :to="{
                  name: 'AdminMemberDetail',
                  params: { profile_id: item.id }
                }"
              >
                {{ item.company }}
              </router-link>
            </template>

            <!-- MAIN BRANCH -->

            <!-- COMPLIANCE -->
            <template v-slot:item.is_compliant="{ item }">
              <v-icon
                v-if="item.is_compliant"
                color="green"
                small
                title="Compliant"
                >mdi-check-circle-outline</v-icon
              >
              <v-icon v-else small color="red" title="Non-Compliant"
                >mdi-alert-circle-outline</v-icon
              >
            </template>

            <!-- IS ACTIVE -->
            <template v-slot:item.is_active="{ item }">
              <v-icon
                v-if="item.is_active"
                color="green"
                small
                title="Compliant"
                >mdi-check-circle-outline</v-icon
              >
              <v-icon v-else small color="red" title="Non-Compliant"
                >mdi-close-circle-outline</v-icon
              >
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </one-column-row>
  </v-container>
</template>

<script>
import { ADD_MESSAGE } from "@/store/mutation-types";
import OneColumnRow from "@/layouts/components/OneColumnRow";
import dataTableMixin from "@/mixins/dataTable";

export default {
  name: "Members",
  mixins: [dataTableMixin],
  components: { OneColumnRow },
  data() {
    return {
      headers: [
        { text: "No", value: "member_no", sortable: false },
        { text: "Name", value: "company", sortable: true },
        { text: "Branches", value: "no_of_branches", sortable: false },
        { text: "Compliant", value: "is_compliant", sortable: false },
        { text: "Active", value: "is_active", sortable: false },
      ],
      loading: false
    };
  },
  mounted() {
    this.fetchItems();
  },
  computed: {
    items() {
      return this.$store.state.profile.list;
    },
    isLoading() {
      return this.$store.getters["profile/loading"]("list") || this.loading;
    }
  },
  methods: {
    async fetchItems() {
      try {
        await this.$store.dispatch("profile/list");
      } catch (e) {
        this.$store.commit(`theme/${ADD_MESSAGE}`, {
          text: "Unable to load members",
          type: "error"
        });
      }
    },

    viewMemberDetails(item) {
      this.$router.push({
        name: "AdminMemberDetail",
        params: { profile_id: item.id }
      });
    },

    getSelectedIds() {
      return this.selected.reduce(function(previous, current) {
        previous.push(current.id);
        return previous;
      }, []);
    },

    async emailComplianceNotifications() {
      this.loading = true;
      try {
        await this.$store.dispatch(
          "profile/emailComplianceNotifications",
          this.getSelectedIds(this.selected)
        );
        this.selected = [];
        this.$store.commit(`theme/${ADD_MESSAGE}`, {
          text: "Non-Compliance emails have been sent",
          type: "success"
        });
      } catch (e) {
        this.$store.commit(`theme/${ADD_MESSAGE}`, {
          text: "Unable to email compliance notices",
          type: "error"
        });
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
