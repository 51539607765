var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('one-column-row',[_c('v-card',{attrs:{"tile":""}},[_c('v-card-text',[_c('v-btn',{style:({
            visibility: _vm.selected.length > 0 ? 'visible' : 'hidden'
          }),attrs:{"small":"","disabled":_vm.isLoading},on:{"click":_vm.emailComplianceNotifications}},[_vm._v(" Email Compliance Notifications ")]),_c('v-data-table',{staticClass:"pa-2",attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.isLoading,"footer-props":{ 'items-per-page-options': _vm.itemsPerPageOptions },"options":_vm.tableFilters,"server-items-length":_vm.totalItems,"show-select":"","disable-filtering":"","disable-sort":"","item-key":"id"},on:{"update:options":[function($event){_vm.tableFilters=$event},_vm.updateFilters]},scopedSlots:_vm._u([{key:"item.company",fn:function(ref){
          var item = ref.item;
return [_c('router-link',{attrs:{"to":{
                name: 'AdminMemberDetail',
                params: { profile_id: item.id }
              }}},[_vm._v(" "+_vm._s(item.company)+" ")])]}},{key:"item.is_compliant",fn:function(ref){
              var item = ref.item;
return [(item.is_compliant)?_c('v-icon',{attrs:{"color":"green","small":"","title":"Compliant"}},[_vm._v("mdi-check-circle-outline")]):_c('v-icon',{attrs:{"small":"","color":"red","title":"Non-Compliant"}},[_vm._v("mdi-alert-circle-outline")])]}},{key:"item.is_active",fn:function(ref){
              var item = ref.item;
return [(item.is_active)?_c('v-icon',{attrs:{"color":"green","small":"","title":"Compliant"}},[_vm._v("mdi-check-circle-outline")]):_c('v-icon',{attrs:{"small":"","color":"red","title":"Non-Compliant"}},[_vm._v("mdi-close-circle-outline")])]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }