<template>
  <v-row v-if="errors.non_field_errors || errors.detail">
    <v-col cols="12">
      <v-alert type="error" class="non-field-errors" outlined border="left">
        <ul>
          <li v-for="(error, index) in errors.non_field_errors" :key="index" style="list-style-type:none;">
            {{ error }}
          </li>
          <li v-if="errors.detail" style="list-style-type:none;">
            {{ errors.detail }}
          </li>
        </ul>
      </v-alert>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import Vue from "vue";
const defaultErrors = () => {
  return { non_field_errors: null, detail: null };
};
export default Vue.extend({
  name: "NonFieldErrors",
  props: { errors: { type: Object, default: defaultErrors() } }
});
</script>
