const mediaMixin = {
    methods: {
        getFileExtension(filename) {
            return (filename && filename.split(".").pop()) || "";
        },
        isImageFile(filename) {
            const fileExtension = this.getFileExtension(filename);
            const imageTypes = ["jpg", "jpeg", "tif", "tiff", "png", "gif"];
            return imageTypes.includes(fileExtension);
        },
        getMediaIcon(filename) {
            const fileExtension = this.getFileExtension(filename);

            if (["pdf"].includes(fileExtension)) {
                return "mdi-file-pdf-outline";
            }
            if (["xls", "xlsx"].includes(fileExtension)) {
                return "mdi-microsoft-excel";
            }
            if (["doc", "docx"].includes(fileExtension)) {
                return "mdi-microsoft-word";
            }

            return "mdi-file-outline";
        }
    }
};

export default mediaMixin;
