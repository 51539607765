<template>
  <v-card tile>
    <light-box ref="lightbox" max-height="500px"/>
    <submission-locked-alert :locked="readOnly"/>
    <document-privacy-alert :submission="submission"/>
    <document-media ref="document_media" :submission="submission" :read-only="readOnly" @closed="emitUpdate"/>
    <loading-dialog v-if="loading" :message="loadingMessage"></loading-dialog>
    <template v-if="documentCategories.length > 0">
      <template v-for="category in documentCategories">
        <template v-if="items.hasOwnProperty(category.value)">
          <v-card-title :key="`${category.value}_title`">
            {{ category.text }}
          </v-card-title>
          <v-card-text :key="`${category.value}_text`">
            <v-list>
              <v-list-item v-for="item in items[category.value]" :key="item.id"
                           @click="$refs.document_media.open(item)">

                <v-list-item-action>

                  <boolean-icon v-if="item.status !== 'rejected'" :value="itemHasMedia(item)"/>
                  <v-icon right v-else color="error">mdi-alert-circle</v-icon>

                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.description }}
                  </v-list-item-title>
                  <v-list-item-subtitle v-if="item.question_number <= maximumQuestionNo">
                    Question number {{ item.question_number }} <template v-if="item.status === 'rejected'">| <span class="error--text">REJECTED</span> (click for more details)</template>
                  </v-list-item-subtitle>

                </v-list-item-content>
                <v-list-item-action>
                  <v-btn v-if="getExampleDoc(item.description)" text
                         @click.stop="$refs.lightbox.open(getExampleDoc(item.description))">
                    <v-icon left>mdi-help-circle-outline</v-icon>
                    Example
                  </v-btn>
                </v-list-item-action>

              </v-list-item>
            </v-list>
          </v-card-text>
        </template>
      </template>
    </template>
  </v-card>
</template>

<script>
import LoadingDialog from "@/components/LoadingDialog";
import {ADD_MESSAGE} from "@/store/mutation-types";
import downloadMixin from "@/mixins/download";
import DocumentMedia from "@/components/submission/DocumentMedia";
import eventHub from "@/event-hub";
import {updateListItem} from "@/utils/list";
import BooleanIcon from "@/components/BooleanIcon";
import SubmissionLockedAlert from "@/components/SubmissionLockedAlert";
import exampleDocumentsMixin from "@/mixins/exampleDocuments";
import LightBox from "@/components/LightBox";
import DocumentPrivacyAlert from "@/components/document/DocumentPrivacyAlert";

const defaultLoadingMessage = "Loading Documents";

export default {
  name: "DocumentSheet",

  components: {DocumentPrivacyAlert, LightBox, SubmissionLockedAlert, BooleanIcon, DocumentMedia, LoadingDialog},

  props: {submission: {type: Object, required: true}, readOnly: {type: Boolean, required: false, default: false}},

  mixins: [downloadMixin, exampleDocumentsMixin],

  data() {
    return {
      items: {},
      loading: false,
      loadingMessage: defaultLoadingMessage,
      documentCategories: [],
      maximumQuestionNo: 31,
      documentsWereUpdated: false
    };
  },

  computed: {
    url() {
      return `/inspection/submissions/${this.submission.id}/documents/`;
    }
  },

  created() {
    eventHub.$on("DocumentMedia.updated", this.handleDocumentUpdate);
    eventHub.$on("DocumentMedia.loading", this.handleDocumentLoading);
  },

  beforeDestroy() {
    eventHub.$off("DocumentMedia.updated");
    eventHub.$off("DocumentMedia.loading");
  },

  mounted() {
    this.fetchItems();
  },

  methods: {
    fetchItems() {
      this.loadingMessage = defaultLoadingMessage;
      this.loading = true;

      this.$http.get(this.url).then((response) => {
        this.items = this.categoriseDocuments(response.data.results);
        this.documentCategories = response.data.category_choices;
      }).catch(() => {
        this.$store.commit(
            `theme/${ADD_MESSAGE}`,
            {type: "error", text: "There was an error loading this submission"}
        );
      }).finally(() => {
        this.loading = false;
      });
    },

    uploadPercentage(data) {
      console.log(data);
    },
    handleDocumentUpdate(item) {
      this.items = {...this.items, [item.category]: updateListItem(this.items[item.category], item)};
      this.$store.commit(`theme/${ADD_MESSAGE}`, {type: "success", text: "Submission saved."});
      this.documentsWereUpdated = true;
    },
    handleDocumentLoading(status) {
      this.loading = status;
    },
    itemHasMedia(item) {
      return !!item.attachments.length;
    },
    emitUpdate() {
      if (this.documentsWereUpdated) eventHub.$emit("DocumentSheet.updated", this.items);
      this.documentsWereUpdated = false;
    },
    categoriseDocuments(documents) {
      return documents.reduce(function (accumulator, currentValue) {
        if (!accumulator[currentValue.category]) {
          accumulator[currentValue.category] = [];
        }
        accumulator[currentValue.category].push(currentValue);
        return accumulator;
      }, {});
    }
  }
};
</script>
