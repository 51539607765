<template>
  <v-container>
    <one-column-row>
      <v-data-iterator
          :items="items"
          :loading="isLoading"
          :footer-props="{
          'items-per-page-options': itemsPerPageOptions
        }"
          :server-items-length="totalItems"
          disable-filtering
          disable-sort
          class="pa-2"
          :options.sync="tableFilters"
          @update:options="updateFilters"
      >
        <v-list two-line>
          <v-list-item v-for="item in items" :key="item.id"
                       :to="{name: 'SAPSMemberDetail', params: {profile_id: item.id}}">
            <v-list-item-avatar tile>
              <v-icon large v-text="getIcon(item)"></v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-card-title>
                {{ item.company }}
              </v-card-title>
              <v-card-subtitle>
                {{ item.member_no }}
                <small v-if="item.no_of_branches > 1" class="blue--text">{{ item.no_of_branches }} BRANCHES</small>
                <small v-if="!item.is_active" class="red--text">(INACTIVE)</small>
              </v-card-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-chip v-if="item.is_compliant" color="green" outlined label small>
                <v-icon small left>mdi-check-circle-outline</v-icon>
                Compliant
              </v-chip>
              <v-chip color="red" v-else outlined label small>
                <v-icon small left>mdi-alert-circle-outline</v-icon>
                Non-Compliant
              </v-chip>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-data-iterator>
    </one-column-row>
  </v-container>
</template>

<script>
import {ADD_MESSAGE} from "@/store/mutation-types";
import OneColumnRow from "@/layouts/components/OneColumnRow";
import dataTableMixin from "@/mixins/dataTable";

export default {
  name: "SAPSMembers",
  mixins: [dataTableMixin],
  components: {OneColumnRow},
  computed: {
    items() {
      return this.$store.state.profile.list;
    },
    isLoading() {
      return this.$store.getters["profile/loading"]("list");
    }
  },
  methods: {
    async fetchItems() {
      try {
        await this.$store.dispatch("profile/list");
      } catch (e) {
        this.$store.commit(`theme/${ADD_MESSAGE}`, {text: "Unable to load members", type: "error"});
      }
    },
    getIcon(item) {
      if (item.is_staff) {
        return "mdi-account";
      }
      return "mdi-home-city";
    }
  }
};
</script>
