var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('one-column-row',[_c('v-card',{attrs:{"tile":""}},[_c('v-card-title',[_vm._v(" Annual SAPS Reports ")]),_c('v-card-text',[_c('v-data-table',{staticClass:"pa-2",attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.isLoading,"footer-props":{ 'items-per-page-options': _vm.itemsPerPageOptions },"options":_vm.tableFilters,"server-items-length":_vm.totalItems,"disable-filtering":"","disable-sort":"","item-key":"id"},on:{"update:options":[function($event){_vm.tableFilters=$event},_vm.updateFilters]},scopedSlots:_vm._u([{key:"item.reference_no",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":(_vm.routePrefix + "/saps-reports/generate/" + (item.id))}},[_vm._v(" "+_vm._s(item.reference_no)+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('submission-status-badge',{attrs:{"status":item.status,"show-status-color":true}})]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.created_at))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},nativeOn:{"click":function($event){$event.stopPropagation();}}},on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){$event.preventDefault();return _vm.deleteItem(item)}}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-delete")]),_vm._v(" Delete ")],1)],1)],1)],1)]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }