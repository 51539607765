<template>
  <div :class="{ 'mt-5': showQuestion }">
    <h3
      :class="{ '.v-card__subtitle': !isErrored, 'error--text': isErrored }"
      v-if="showQuestion"
    >
      <span v-show="showNumber">{{ question.number }}.</span>
      {{ question.question }} <span v-if="question.required"> *</span>
    </h3>
    <slot
      :question="question"
      :update-error-status="updateErrorStatus"
      :is-errored="isErrored"
    />
  </div>
</template>

<script>
import eventHub from "@/event-hub";

export default {
  name: "Question",

  props: {
    question: { type: Object },
    showNumber: { type: Boolean, default: true },
    showQuestion: { type: Boolean, default: true }
  },

  created() {
    eventHub.$on("Submission.updated", this.updateSubmissionErrorStatus);
    eventHub.$on(
      "Submission.updateQuestionErrors",
      this.updateSubmissionErrorStatus
    );
  },

  beforeDestroy() {
    eventHub.$off("Submission.updated");
    eventHub.$off("Submission.updateQuestionErrors");
  },
  data() {
    return {
      isErrored: false
    };
  },

  methods: {
    clearError() {
      this.isErrored = false;
    },
    showError() {
      this.isErrored = true;
    },
    updateSubmissionErrorStatus(submission) {
      const blank = Array.isArray(this.question.answer)
        ? this.question.answer.length < 1
        : !this.question.answer;

      const questionsCompleted = Number(submission.questions_completed);
      if (
        1 > questionsCompleted &&
        questionsCompleted > 0 &&
        this.question.required && blank
      ) {
        this.showError();
      } else {
        this.clearError();
      }
    },
    updateErrorStatus() {
      if (this.question.required && !this.question.answer) {
        this.showError();
      } else {
        this.clearError();
      }
    }
  }
};
</script>

<style scoped></style>
