import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);


export default new Vuetify({
    theme: {
        options: {
            customProperties: true
        },
        themes: {
            light: {
                primary: "#7eb826",
                secondary: "#0f88ca",
                accent: "#7499cd",
                error: "#bc0523",
                info: "#2196F3",
                success: "#4CAF50",
                warning: "#ff6600"
            }
        }
    },
    icons: {
        iconfont: "mdi"
    }
});
