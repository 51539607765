<template>
  <v-card tile>
    <submission-locked-alert :locked="readOnly" />
    <v-card-title>SAPS / HEALTH & SAFETY REGULATORY REQUIREMENTS</v-card-title>
    <v-card-subtitle>Please click each button below and read through and accept the guidelines</v-card-subtitle>
    <v-card-text>
      <!-- SAPS REGULATION -->
      <v-row>
        <v-col cols="3">
          <PDFViewer
              ref="saps_pdf" url="pdf/MRA_REFERENCE_GUIDE_ON_FREQUENTLY_STOLEN_MATERIAL_B.pdf"
              document="read_guide_on_frequently_stolen_materials"
              :value="data.read_guide_on_frequently_stolen_materials"
              dialog-title="Reference Guide on frequently stolen materials"
          />
          <v-btn block outlined @click="$refs.saps_pdf.open()" :disabled="formLocked">FREQUENTLY STOLEN MATERIALS
            <v-spacer/>
            <boolean-icon :value="data.read_guide_on_frequently_stolen_materials"/>
          </v-btn>
          <small>
            Click here to read and acknowledge the SAPS regulation
          </small>
        </v-col>
      </v-row>
      <!-- HEALTH AND SAFETY REGULATION-->
      <v-row>
        <v-col cols="3">
          <PDFViewer
              ref="safety_pdf" url="pdf/hazcom_posters.pdf"
              document="read_guide_on_hazardous_materials"
              :value="data.read_guide_on_hazardous_materials"
              dialog-title="Reference guide on hazardous materials"
          />
          <v-btn block outlined @click="$refs.safety_pdf.open()" :disabled="formLocked">HAZARDOUS MATERIALS
            <v-spacer/>
            <boolean-icon :value="data.read_guide_on_hazardous_materials"/>
          </v-btn>
          <small>
            Click here to read and acknowledge the HEALTH & SAFETY regulation
          </small>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-title>DECLARATION</v-card-title>
    <v-card-text>

      <p>
        As authorized signatory of the applicant, I hereby declare the following in terms of Section 14 of the Second
        Hand
        Goods
        Act 2009 and Section 12(2)(b) of the SAPS Regulations for Accreditation of Second-Hand Goods Dealers’
        Associations:
      </p>
      <ol type="a">
        <li>
          None of the applicant’s shareholders or day to day management are disqualified from registering as a dealer;
        </li>
        <li>
          The inspected premises comply with all relevant legal and municipal by-law requirements pertaining to
          premises used for dealing in and recycling scrap metal;
        </li>
        <li>
          All information provided on this checklist is true and correct.
        </li>
        <template v-if="data.classification === 'dealer_recycler'">
          <li>
            SAPS 601(a) DEALER responsible person: <b>{{ questions[41].answer}}</b>
          </li>
          <li v-if="questions[1].answer === 'yes'">
            SAPS 601(b) RECYCLER responsible person: <b>{{ questions[42].answer}}</b>
          </li>
          <li>
            The person/s listed above is currently still employed by the applicant.
          </li>
          <li>
            The person/s listed is aware of and in agreement of this submission.
          </li>
        </template>
      </ol>
      <v-text-field
          v-model="data.declaration_signed_by"
          label="Full Name"
          filled
          shaped
          :disabled="formLocked"
      />
      <v-checkbox :disabled="formLocked || !Boolean(data.declaration_signed_by)" label="I Agree" v-model="data.declaration_signed"
                  @change="update"></v-checkbox>
      <p class="text-muted text-sm-body-1">
        (Signed by: {{ data.declaration_signed_by }})
      </p>
    </v-card-text>
  </v-card>

</template>

<script>
import eventHub from "@/event-hub";
import PDFViewer from "@/components/PDFViewer";
import BooleanIcon from "@/components/BooleanIcon";
import SubmissionLockedAlert from "@/components/SubmissionLockedAlert";

export default {
  name: "DeclarationSheet",
  props: {submission: {type: Object, required: true}, readOnly: {type: Boolean, required: false, default: false}},
  components: {SubmissionLockedAlert, PDFViewer, BooleanIcon},
  data() {
    return {
      questions: [],
      data: {
        declaration_signed: false,
        read_guide_on_hazardous_materials: false,
        read_guide_on_frequently_stolen_materials: false
      }
    };
  },

  computed: {
    user() {
      return this.$store.state.auth.user;
    },
    formLocked() {
      return this.readOnly || this.loading || this.user.is_staff;
    },
    questionUrl() {
      return `/inspection/submissions/${this.submission.id}/questions/`;
    },
  },

  created() {
    eventHub.$on("PDFViewer.accepted", this.update);
  },

  beforeDestroy() {
    eventHub.$off("PDFViewer.accepted");
  },

  mounted() {
    this.data = {...this.submission};
    this.fetchQuestions();
  },

  methods: {
    update(data = null) {
      if (data) this.data = {...this.data, ...data};
      eventHub.$emit("DeclarationSheet.updated", this.data);
    },
    fetchQuestions() {
      return this.$http.get(this.questionUrl).then((response) => {
        this.questions = response.data;
      });
    },
  }
};
</script>
