<template>
  <div>
    <v-container v-if="!readOnly">
      <v-card>
        <v-alert
            outlined
            border="left"
            color="primary"
            type="info"
            class="mt-3"
        >
          All requests to update information should be accompanied by a 604 form and relevant documentation. <a href @click.prevent="createNewNotification">Open a new update request</a>

        </v-alert>
      </v-card>
    </v-container>
    <change-notification-list model-name="changeNotification"/>
  </div>
</template>

<script>
import ChangeNotificationList from "../../components/change-notification/ChangeNotificationList";
import {ADD_MESSAGE, SET_LIST} from "../../store/mutation-types";
import {updateListItem} from "../../utils/list";
import routePrefix from "../../mixins/routePrefix";

export default {
  name: "ChangeNotificationIndex",
  components: {ChangeNotificationList},
  mixins: [routePrefix],
  data(){
    return {
      form:{}
    };
  },
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
    url() {
      return "account/change-notifications/";
    },
    readOnly(){
      return this.user.is_saps || this.user.is_staff;
    }
  },
  methods: {
    async createNewNotification() {

      this.loading = true;
      this.form.user_id = this.user.id;
      this.form.status = "pending";
      try {

        const response = await this.$http.post(this.url, this.form);
        this.$store.commit("auth/SET_USER", {...this.user, profile: response.data});
        this.$store.commit(`profile/${SET_LIST}`, updateListItem([...this.$store.state.changeNotification.list], this.form));
        this.errors = [];
        this.$store.commit(`theme/${ADD_MESSAGE}`, {type: "success", text: "New change request opened."});
        await this.$router.push(`${this.routePrefix}/change-notifications/${response.data.id}`);
      } catch (error) {
        this.errors = error.response.data;
        this.$store.commit(`theme/${ADD_MESSAGE}`, {type: "error", text: "Could not open new change request."});
      } finally {
        this.loading = false;
      }
    },
  }
};
</script>

<style scoped>

</style>
