<template>
  <v-chip v-if="statusIcon" :color="statusColor" x-small  outlined>
    <v-icon x-small left :title="statusText">{{ statusIcon }}</v-icon>
    {{ statusText }}
  </v-chip>
</template>

<script>
import statusMixin from "../../mixins/status";

export default {
  name: "SubmissionStatusBadge",

  props: {
    size: {type: String, default: ""},
    status: {type: String, required: true},
    showStatusColor: {type: Boolean, default: false},
    dark: {type: Boolean, default: false}
  },

  mixins: [statusMixin],

  computed: {

    statusIcon() {
      return this.getStatusIcon(this.status);
    },

    statusText() {
      return this.getStatusText(this.status);
    },

    statusColor() {
      if (this.showStatusColor) {
        return this.getStatusColor(this.status);
      }
      return this.dark ? "white" : "gray" ;
    }

  }
};
</script>
