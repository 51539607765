const routePrefixMixin = {
    computed: {
        /**
         * Add the admin route prefix for staff members
         * e.g route = this.routePrefix + "my/route/"
         * @return {string}
         */
        routePrefix() {
            const user = this.$store.state.auth.user;
            if (user.is_saps){
                return "/saps";
            }
            return user.is_staff ? "/admin" : "";
        }
    }
};

export default routePrefixMixin;
