<template>
  <v-dialog
      :value="displayDialog"
      :fullscreen="formFullscreen"
      :width="dialogWidth"
      :content-class="contentClass"
      max-width="1000px"
      scrollable
      persistent
      @click:outside="close"
  >
    <v-card>
      <v-card-title>
        {{ dialogTitle }}
        <slot name="app-bar-left"></slot>
        <v-spacer/>
        <slot name="app-bar-right"></slot>
        <v-btn icon @click.stop="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-divider/>

      <v-card-text>
        <slot>
          <router-view v-if="routeParam" />
        </slot>
      </v-card-text>

      <v-divider class/>

      <v-card-actions v-if="showActions">
        <slot name="actions"></slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: "ResponsiveDialog",
  props: {
    showActions: {type: Boolean, default: true},
    dialogTitle: {type: String, default: "Title"},
    dialogWidth: {type: Number, default: NaN},
    contentClass: {type: String, default: ""},
    routeParam: {type: String, required: false}
  },

  data() {
    return {
      isOpen: false
    };
  },

  computed: {
    formFullscreen() {
      // Calculates if the form should be shown in fullscreen mode or not depending on the display size
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return true;
        case "lg":
          return false;
        case "xl":
          return false;
      }

      return false;
    },
    displayDialog: {
      get() {
        return this.isOpen || !!(this.routeParam && this.$route.params[this.routeParam]);
      },
      set(value) {
        this.isOpen = value;
      }
    }
  },


  methods: {

    open() {
      this.isOpen = true;
      this.$emit("opened");
    },

    close() {
      if (this.routeParam && this.$route.params[this.routeParam]) {
        this.$router.back();
      }
      if (this.isOpen) {
        this.isOpen = false;
      }
      this.$emit("closed");
    }
  }
};
</script>

<style scoped>
.v-card__actions {
  padding-left: 25px;
  padding-right: 25px;
}
</style>
