<template>
  <v-alert
      v-if="showAlert"
      border="left"
      color="info"
      type="info"
      icon="mdi-shield-lock-outline"
      outlined
      prominent
  >
    <span class="text-sm-body-2">{{ this.lockedMessage }} </span>
  </v-alert>
</template>

<script>

export default {
  name: "DocumentPrivacyAlert",

  props: {submission: {type: Object, required: true}},

  computed: {
    user() {
      return this.$store.state.auth.user;
    },
    showAlert() {
      return  !["complete", "submitted"].includes(this.submission.status) && !this.user.is_staff;
    },
    lockedMessage() {
      return "All your documents are encrypted during storage and transfer and remain secure and private. Only the secretary and SAPS have access.";
    }
  }
};
</script>

<style scoped>

</style>