// Mutation types
import {SET_ACCOUNTS} from "@/store/mutation-types";
import {http} from "@admin100/djvue";
// ThemeState
const state = {
    accounts: null,
};


const mutations = {
    [SET_ACCOUNTS](state, accounts) {
        state.accounts = accounts;
    },
};

const actions = {
    getUserAccounts({commit, rootState}) {
        return http.get(`/account/users/${rootState.auth.user.id}/accounts/`).then((response) => {
            commit(`${SET_ACCOUNTS}`, response.data);
            return Promise.resolve(response);
        }).catch(e => {
            return Promise.reject(e);
        });
    }
};


const account = {
    namespaced: true,
    state,
    actions,
    mutations
};

export default account;
