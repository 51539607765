<template>
  <responsive-dialog ref="responsive_dialog" :dialog-title="dialogTitle" :show-actions="false">
      <div>
    <v-form @submit.prevent="onFormSubmit()">
      <v-card-text>
        <profile-select @change="setUser" ref="profileSelect"/>
      </v-card-text>
      <input type="submit" hidden/>
    </v-form>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="info" block :loading="loading" x-large @click="onFormSubmit">
        CREATE
      </v-btn>
    </v-card-actions>
  </div>
  </responsive-dialog>
</template>

<script>
import ResponsiveDialog from "../ResponsiveDialog";
import ProfileSelect from "../profile/ProfileSelect";

export default {
  name: "NewDisciplinaryLogModal",

  components: {ProfileSelect, ResponsiveDialog},

  data() {
    return {
      loading: false,
      user: {},
      form: {},
      errors: {}
    };
  },

  computed: {
    dialogTitle() {
      return "New Disciplinary Action";
    }
  },

  methods: {
    open() {
      this.form = {};
      this.$refs.responsive_dialog.open();
    },
    close() {
      this.$refs.responsive_dialog.close();
      this.form = {};
    },
    onFormSubmit(){
      this.loading = true;
      this.$store.dispatch("disciplinaryLog/create", this.form).then((response)=>{
        this.$router.push(`/admin/disciplinary-logs/${response.data.id}`);
      }).catch(() => {

      }).finally(()=>{
        this.loading = false;
      });

    },
    setUser(value){
      this.form.user_id = value;
    }
  }

};
</script>

<style scoped></style>
