// Common
export const LOADING = "LOADING";
export const LOADED = "LOADED";

// Authentication
export const LOGOUT = "LOGOUT";
export const SET_TOKEN = "SET_TOKEN";
export const SET_REFRESH_TOKEN = "SET_REFRESH_TOKEN";
export const REFRESHING_TOKEN = "REFRESHING_TOKEN";
export const REFRESHED_TOKEN = "REFRESHED_TOKEN";
export const SET_REFRESHING_CALL = "SET_REFRESHING_CALL";
export const SET_ENTITY = "SET_ENTITY";
export const SET_ACCOUNTS = "SET_ACCOUNTS";

// Models
export const SET_LIST = "SET_LIST";
export const SET_DETAIL = "SET_DETAIL";
export const SET_META = "SET_META";
export const ADD_FILTERS = "ADD_FILTERS";
export const CLEAR_FILTERS = "CLEAR_FILTERS";
export const ADD_REQUEST = "ADD_REQUEST";
export const REMOVE_REQUEST = "REMOVE_REQUEST";

// Theme
export const TOGGLE_THEME = "TOGGLE_THEME";
export const SET_THEME = "SET_THEME";
export const SET_DRAWER = "SET_DRAWER";
export const TOGGLE_MINI_MENU = "TOGGLE_MINI_MENU";
export const SET_PROGRESS = "SET_PROGRESS";
export const ADD_MESSAGE = "ADD_MESSAGE";
export const REMOVE_MESSAGE = "REMOVE_MESSAGE";
export const CLEAR_MESSAGES = "CLEAR_MESSAGES";
export const SET_PAGE_TITLE = "SET_PAGE_TITLE";
export const SET_CUSTOM_DOMAIN = "SET_CUSTOM_DOMAIN";
export const SET_NESTED_TITLE = "SET_NESTED_TITLE";

// Dialogs
export const OPEN_DIALOG = "OPEN_DIALOG";
export const CLOSE_DIALOG = "CLOSE_DIALOG";
export const CLEAR_DIALOGS = "CLEAR_DIALOGS";

// Errors
export const ADD_ERRORS = "ADD_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
