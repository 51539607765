<template>
  <v-form @submit.prevent="updateFilters">
    <v-divider />
    <v-list-item>
      <v-list-item-title>
        FILTERS
      </v-list-item-title>
    </v-list-item>
    <v-list-item>
      <v-text-field
        dense
        placeholder="Search Members"
        prepend-inner-icon="mdi-magnify"
        v-model="tableFilters.search"
        outlined
        clearable
        hint="Name/Member No/ Email"
        persistent-hint
      />
    </v-list-item>
    <v-list-item>
      <v-select
        dense
        :items="provinceChoices"
        placeholder="Province"
        hide-details
        v-model="tableFilters.province_in"
        outlined
        clearable
        multiple
      />
    </v-list-item>
    <v-list-item>
      <v-select
        dense
        placeholder="Is Compliant"
        v-model="tableFilters.is_compliant"
        outlined
        clearable
        :items="boolOptions"
        hide-details
      />
    </v-list-item>
    <v-list-item>
      <v-select
        dense
        placeholder="Is Active"
        v-model="tableFilters.user__is_active"
        outlined
        clearable
        :items="boolOptions"
      />
    </v-list-item>
    <v-list-item>
      <v-btn
        color="info"
        :disabled="loading"
        small
        outlined
        @click="clearFilters"
        >Clear</v-btn
      >
      <v-spacer />
      <v-btn type="submit" :disabled="loading" color="primary" small outlined
        >Search</v-btn
      >
    </v-list-item>
  </v-form>
</template>

<script>
import dataTableMixin from "@/mixins/dataTable";
import { ADD_MESSAGE } from "@/store/mutation-types";

function initialFilters() {
  return {
    province_in: [],
    is_compliant: "",
    search: "",
    user__is_active: ""
  };
}

export default {
  name: "ProfileFilter",
  mixins: [dataTableMixin],
  data() {
    return {
      provinceChoices: require("@/data/provinces.json"),
      tableFilters: initialFilters(),
      boolOptions: [
        { text: "Yes", value: 1 },
        { text: "No", value: 0 }
      ]
    };
  },
  methods: {
    async fetchItems() {
      try {
        await this.$store.dispatch("profile/list");
      } catch (e) {
        this.$store.commit(`theme/${ADD_MESSAGE}`, {
          text: "Unable to load members",
          type: "error"
        });
      }
    },
    clearFilters() {
      this.tableFilters = initialFilters();
      this.updateFilters();
    }
  },
  computed: {
    loading() {
      return this.$store.getters["profile/loading"]("list");
    }
  }
};
</script>

<style scoped></style>
