<template>
  <main-layout>
    <template v-slot:menu-drawer>
      <admin-menu></admin-menu>
    </template>
    <router-view></router-view>
  </main-layout>
</template>

<script>
import MainLayout from "@/layouts/MainLayout";
import AdminMenu from "@/components/AdminMenu";

export default {
  name: "Index",
  components: {AdminMenu, MainLayout}
};
</script>
