<template>
  <v-select
      v-model="selected"
      :label="label"
      :name="name"
      dense
      :loading="isLoading"
      :items="items"
      :error-messages="errorMessages"
      :disabled="disabled"
      :background-color="backgroundColor"
      @change="onChange"
      outlined
      hide-details
  />
</template>

<script>
import {ADD_MESSAGE} from "@/store/mutation-types";

export default {
  name: "ProfileSelect",

  props: {
    name: {type: String, default: ""},
    label: {type: String, default: "Member"},
    backgroundColor: {type: String, default: undefined},
    disabled: {type: Boolean, default: false},
    initialValue: {type: String, default: ""},
  },

  data() {
    return {
      items: [],
      selected: "",
      isLoading: false,
      errorMessages: []
    };
  },

  watch: {
    initialValue(value) {
      this.selected = value;
    }
  },

  mounted() {
    if (this.items.length < 1) {
      this.fetchItems();
    }
  },

  methods: {
    fetchItems() {
      return this.$http.get("account/admin/profiles/choices/")
          .then(response => {
            this.items = response.data;
            this.selected = this.initialValue;
          })
          .catch(() => {
            this.errorMessages = ["Unable to fetch members"];
          });
    },

    onChange() {
      this.$emit("change", this.selected);
    },

    select(value) {
      this.selected = value;
    },

    clear(){
      this.selected = "";
    },

    displayMessage(message) {
      this.$store.commit(`theme/${ADD_MESSAGE}`, message);
      return this;
    }
  }
};
</script>
