import Vue from "vue";
import Vuex from "vuex";
import theme from "@/store/theme";
import { auth } from "@admin100/djvue";
import submission from "@/store/submission";
import media from "@/store/media";
import profile from "@/store/profile";
import proofOfPayment from "@/store/proof-of-payment";
import account from "@/store/account";
import changeNotification from "@/store/change-notification";
import disciplinaryLog from "@/store/disciplinary-log";
import report from "@/store/report";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        theme,
        auth,
        account,
        submission,
        media,
        profile,
        proofOfPayment,
        changeNotification,
        disciplinaryLog,
        report
    }
});
