import Vue from "vue";
import {ADD_MESSAGE} from "@/store/mutation-types";
import {http} from "@admin100/djvue";

const downloadMixin = Vue.extend({
    data() {
        return {
            downloadBusy: false
        };
    },
    methods: {
        /**
         * Javascript forces a browser download from a blob
         * Current blob size limit is around 500MB for browsers
         * @param blob
         * @param filename
         */
        forceDownload(blob, filename) {
            const a = document.createElement("a");
            a.download = filename;
            a.href = blob;
            document.body.appendChild(a);
            a.click();
            a.remove();
        },
        /**
         * Pass a url and filename to have the browser download that resource
         * @param url
         * @param filename
         */
        downloadResource(url, filename) {
            this.downloadBusy = true;
            http
                .get(url, {
                    responseType: "blob"
                })
                .then(response => response.data)
                .then(blob => {
                    let blobUrl = window.URL.createObjectURL(blob);
                    this.forceDownload(blobUrl, filename);
                })
                .catch(() => this.$store.commit(`theme/${ADD_MESSAGE}`, {
                    text: "Unable to download file",
                    type: "error"
                }))
                .finally(()=>{
                    this.downloadBusy = false;
                })
            ;
        },
        downloadApplicationForm(submission) {
            this.downloadResource(
                `/inspection/submissions/${submission.id}/pdf/`, `MRA Application ${submission.company} ${new Date(submission.due_date).getFullYear()}`
            );
        },

        downloadMembershipCertificate(submission) {
            this.downloadResource(
                `/inspection/submissions/${submission.id}/certificate/`, `MRA Certificate ${submission.company} ${new Date(submission.due_date).getFullYear()}`
            );
        }
    }
});
export default downloadMixin;
