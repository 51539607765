<template>
  <div>
    <v-form ref="submissionForm" @submit.prevent="onFormSubmit()">
      <v-card-text>
        <non-field-errors :errors="errors" />

        <profile-select @change="setUser" ref="profileSelect"/>

        <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="true"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="form.due_date"
                label="Due Date"
                prepend-inner-icon="mdi-calendar"
                readonly
                class="mt-2"
                outlined
                dense
                :error-messages="errors.due_date"
                v-bind="attrs"
                v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
              v-model="form.due_date"
              no-title
              scrollable
          >
            <v-spacer></v-spacer>
            <v-btn
                text
                color="primary"
                @click="menu = false"
            >
              Cancel
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-card-text>
      <input type="submit" hidden>
    </v-form>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="info" :loading="loading"  @click="onFormSubmit" outlined large>
        SAVE
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import {ADD_MESSAGE, SET_LIST} from "@/store/mutation-types";
import ProfileSelect from "@/components/profile/ProfileSelect";
import NonFieldErrors from "@/components/NonFieldErrors";
import {updateListItem} from "@/utils/list";

function newForm() {
  return {
    user: null,
    due_date: null
  };
}

export default {
  name: "SubmissionForm",
  components: {NonFieldErrors, ProfileSelect},
  props: {submission: {type: Object, required: false, default: null}},
  data() {
    return {
      loading: false,
      form: newForm(),
      errors: {},
      menu: false
    };
  },

  computed: {
    user() {
      return this.$store.state.auth.user;
    },
    isNewItem() {
      return !(this.form && this.form.id);
    },
    urlPrefix() {
      return "/inspection/admin/submissions/";
    },
  },

  mounted() {
    if (this.submission) {
      this.form = {
        user: this.submission.user,
        due_date: this.submission.due_date
      };
    }
  },

  methods: {

    clearForm() {
      this.$refs.profileSelect.clear();
      this.form = newForm();
    },

    clearErrors(){
      this.errors = {};
    },

    setUser(value) {
      this.form.user = value;
    },

    async onFormSubmit() {
      this.clearErrors();
      this.loading = true;
      try {
        const response = this.isNewItem ?
            await this.$http.post(this.urlPrefix, this.form) :
            await this.$http.patch(`${this.urlPrefix}${this.submission.id}/`, this.form);
        this.form = response.data;
        this.errors = [];
        this.$store.commit(`submission/${SET_LIST}`, updateListItem([...this.$store.state.submission.list], this.form));
        this.$store.commit(`theme/${ADD_MESSAGE}`, {type: "success", text: "Inspection saved."});

        await this.$emit("updated");

      } catch (error) {
        this.errors = error.response.data;
        console.log(this.errors);
        this.$store.commit(`theme/${ADD_MESSAGE}`, {type: "error", text: "Inspection save failed."});
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
