<template>
  <v-navigation-drawer v-model="drawer" right temporary app :width="flyoutWidth" @input="handleTransition">
    <v-card tile elevation="0">
      <v-card-title>
        <v-btn @click="close" text x-large>
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-spacer/>
        <h3>{{ nestedTitle }}</h3>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <slot>
          <router-view v-if="routerParam"/>
        </slot>
      </v-card-text>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import {SET_NESTED_TITLE} from "@/store/mutation-types";

export default {
  name: "RightFlyout",

  props: {routerParam: {type: String, required: false}},

  data() {
    return {
      isOpen: false
    };
  },

  computed: {
    drawer: {
      get() {
        return this.isOpen || (!!this.$route.params[this.routerParam]);
      },
      set(value) {
        this.isOpen = value;
      }
    },
    nestedTitle() {
      return this.$store.state.theme.nestedTitle;
    },
    flyoutWidth() {
      // Calculates if the form should be shown in fullscreen mode or not depending on the display size
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "100%";
        case "sm":
          return "100%";
        case "md":
          return "100%";
        case "lg":
          return "50%";
        case "xl":
          return "50%";
      }

      return false;
    },
  },

  beforeDestroy() {
    this.$store.commit(`theme/${SET_NESTED_TITLE}`, "");
  },

  methods: {
    open() {
      this.isOpen = true;
    },
    close() {
      if (this.$route.params[this.routerParam]) {
        this.$router.back();
      }
      if (this.isOpen) {
        this.isOpen = false;
      }
    },
    handleTransition(isOpen) {
      if (this.$route.params[this.routerParam] && !isOpen) {
        this.$router.back();
      }
    },
  }
};
</script>

<style scoped>

</style>
