export const updateListItem = (itemList, updatedItem) => {
  let itemIndex = -1;

  const found = itemList.some((item, index) => {
    itemIndex = index;
    return item.id === updatedItem.id;
  });

  if (found) {
    const updatedList = itemList.slice(0);
    updatedList[itemIndex] = updatedItem;
    return updatedList;
  }
  itemList.unshift(updatedItem);
  return itemList;
};
