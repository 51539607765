<template>
  <base-layout>
    <v-main id="auth-canvas">
      <v-container fill-height >
        <v-layout justify-center align-center>
          <v-flex xs12 sm12 lg6 xl4>
            <router-view/>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </base-layout>
</template>

<script>
import BaseLayout from "./BaseLayout.vue";

export default {
  name: "AuthLayout",
  components: {BaseLayout},
};
</script>

<style scoped>
#auth-canvas {
  background-color: #f1f1f1;
}
</style>
