<template>
  <v-dialog v-model="isOpen" ref="warning">
    <v-card>
      <v-alert type="warning" outlined border="left" dismissible>
        You are using a device with a small screen. For optimal results please rather use a large tablet, notebook or desktop device.
        <template v-slot:close >
            <v-btn icon @click="close" color="warning"><v-icon>mdi-close-circle-outline</v-icon></v-btn>
        </template>
      </v-alert>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "SmallScreenWarning",
  data() {
    return {
      isOpen: false
    };
  },
  mounted() {
    if (this.screenTooSmall) {
      this.isOpen = true;
    }
  },
  computed: {
    screenTooSmall() {
      return ["xs", "sm"].includes(this.$vuetify.breakpoint.name);
    }
  },
  methods: {
    close() {
      this.isOpen = false;
    }
  }
};
</script>

<style scoped>

</style>