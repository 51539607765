<template>
  <router-view/>
</template>

<script>
import {authEvent} from "@admin100/djvue";

export default {
  name: "App",

  methods: {
    redirectToLogin() {
      const loginPath = "/login";
      if (this.$route.path !== loginPath) {
        this.$router.push(loginPath);
        this.$router.go(); // Clears state by reloading the page TODO: (find a more elegant way to do this)
      }
    },
  },

  async created() {
    authEvent.$on("logout", this.redirectToLogin);
  },

  beforeDestroy() {
    authEvent.$off("logout");
  },

  data: () => ({
    //
  }),
};
</script>
