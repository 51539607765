import {ADD_MESSAGE, LOADED, LOADING} from "@/store/mutation-types";

/**
 * Injects the settings for the current organization into the component
 */
const loginMixin = {
    methods: {
        async postLogin() {
            this.$store.commit(`auth/${LOADING}`);

            //Logout super users
            if (this.$store.state.auth.user.is_superuser) {
                await this.$store.dispatch("auth/logout");
                this.$store.commit(`auth/${LOADED}`);
                this.$store.commit(`theme/${ADD_MESSAGE}`, {
                    text: "Superusers may not access this UI, please use the Admin Panel",
                    type: "error"
                });
            }
        },
        async login() {
            try {

                await this.$store.dispatch("auth/login", this.payload);
                await this.postLogin();
                const user = this.$store.state.auth.user;
                let path = user.is_staff ? "/admin" : "/";

                if  (user.is_saps){
                    path = "/saps";
                }

                const redirect = this.$route.query.redirect || path;
                await this.$router.push(redirect);

            } catch (e) {
                if (e)
                    // aborted route cause undefined error, which is OK: https://github.com/vuejs/vue-router/issues/2873#issuecomment-519274203
                    this.$store.commit(`theme/${ADD_MESSAGE}`, {
                        text: "Login failed",
                        type: "error"
                    });
            } finally {
                this.$store.commit(`auth/${LOADED}`);
            }
        }
    }
};

export default loginMixin;
