<template>
  <v-app id="inspire">
    <slot v-if="!loading" />
    <page-load/>
    <v-snackbar
        v-for="message in messages"
        ref="message-snackbar"
        :key="message.key"
        v-model="showMessages"
        :timeout="-1"
    >
      <v-icon :color="message.type || 'white'" left>{{ `mdi-${getIcon(message.type)}` }}</v-icon>
      {{ message.text }}
      <template v-slot:action="{ attrs }">
        <v-btn
            color="white"
            text
            v-bind="attrs"
            @click="removeMessage(message.key)"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <small-screen-warning />
  </v-app>
</template>

<script>
import {CLEAR_MESSAGES, REMOVE_MESSAGE} from "@/store/mutation-types";
import PageLoad from "@/components/PageLoad.vue";
import Vue from "vue";
import SmallScreenWarning from "@/components/SmallScreenWarning";

export default Vue.extend({
  name: "BaseLayout",
  components: {
    SmallScreenWarning,
    PageLoad
  },
  // Data
  data() {
    return {showMessages: true};
  },
  // Computed
  computed: {
    lastMessageId() {
      return this.$store.state.theme.lastMessageId;
    },
    messages() {
      return this.$store.state.theme.messages;
    },
    loading() {
      return this.$store.state.theme.loading;
    }
  },
  watch: {
    lastMessageId() {
      this.timeOutMessage();
    }
  },
  methods: {
    removeMessage(key) {
      this.$store.commit(`theme/${REMOVE_MESSAGE}`, key);
    },
    getIcon(type) {
      switch (type) {
        case "success":
          return "check-circle-outline";
        case "error":
          return "alert-circle-outline";
        case "warning":
          return "alert-outline";
        default:
          return "information-outline";
      }
    },
    timeOutMessage() {
      setTimeout(() => {
        this.$store.commit(`theme/${CLEAR_MESSAGES}`);
      }, 4000);
    }
  }
});
</script>
