<template>
  <v-overlay :value="overlay" :opacity="0">
    <v-progress-circular indeterminate size="64" color="primary"></v-progress-circular>
  </v-overlay>
</template>

<script>

export default {
  name: "PageLoad",
  props: {loading: {type: Boolean, required: false, default: false}},
  computed: {
    overlay() {
      return this.$store.state.theme.loading || this.loading;
    }
  }
};
</script>

<style scoped></style>
