import {
  getState,
  getActions,
  getMutations,
  getGetters
} from "@admin100/djvue";

const actions = getActions("account/change-notifications");
const state = getState();
const getters = getGetters();
const mutations = getMutations();


const changeNotification = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};



export default changeNotification;
