const listMixin = {
    methods: {
        deleteListItem(list, item) {
            list.splice(list.indexOf(item), 1);
        },
        addListItem(list, item) {
            list.push(item);
        },
        updateListItem(list, item){
          return list.map(obj => item.id === obj.id ? item : obj);
        }
    }
};

export default listMixin;