<template>
  <!-- Wrap this in the MediaField tags for a dropzone -->
    <v-card class="media-drop d-flex flex-column flex-wrap justify-center align-center" elevation="0">
        <slot/>
    </v-card>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "MediaDropZone"
});
</script>

<style scoped>
.media-drop {
  min-height: 100px;
  height: 100px;
  border: 2px dashed #eeeeee;
}
</style>
