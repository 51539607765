import {
  getActions,
  getGetters,
  getMutations,
  getState
} from "@admin100/djvue";

const actions = getActions("inspection/admin/reports");
const state = getState();
const getters = getGetters();
const mutations = getMutations();


const report = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};



export default report;
