const numberMixin = {
    methods: {
        formatPercentage(value) {
            const formatter = new Intl.NumberFormat("en-GB", {
                maximumFractionDigits: 2
            });
            return formatter.format(value);
        }
    }
};

export default numberMixin;