<template>
  <div>
    Comments: <a href="" @click.prevent="addComment">Add a comment</a>
    <slot></slot>
    <v-list dense>
      <template v-for="comment in comments">
        <div :key="comment.id">
          <v-divider></v-divider>
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title>sent by {{ comment.sent_by }}</v-list-item-title>
              <v-list-item-subtitle>{{ formatDate(comment.created_at) }}</v-list-item-subtitle>
              {{ comment.comment }}
            </v-list-item-content>
            <v-list-item-action>
              <v-list-item-action-text v-if="comment.status === 'rejected'" class="error--text">
                REJECTED
              </v-list-item-action-text>
              <v-list-item-action-text v-if="comment.status === 'approved'" class="info--text">
                APPROVED
              </v-list-item-action-text>
<!--              <v-btn v-if="user.email === comment.sent_by" text @click="deleteComment(comment.id)">-->
<!--                <v-icon>mdi-delete</v-icon>-->
<!--              </v-btn>-->
            </v-list-item-action>
          </v-list-item>
        </div>
      </template>
    </v-list>
  </div>
</template>

<script>
import dateMixin from "@/mixins/date";

const defaultComments = () => {
  return [];
};
export default {
  name: "DocumentStatus",

  props: {comments: {type: Array, default: defaultComments()}},

  mixins: [dateMixin],

  computed: {
    user() {
      return this.$store.state.auth.user;
    }
  },


  methods: {
    addComment() {
      this.$emit("add");
    },
    deleteComment(commentId) {
      this.$emit("deleted", commentId);
    }
  }
};
</script>

<style scoped>

</style>