import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "@/plugins/vuetify";
import Djvue, {authEvent} from "@admin100/djvue";

Vue.config.productionTip = false;
Vue.use(Djvue, {store});


authEvent.$on("session_started", mountApp);

function mountApp() {

    const app = new Vue({
        router,
        store,
        vuetify,
        render: h => h(App)
    });

    app.$mount("#app");

    authEvent.$off("session_started");
}

