<template>
  <v-container>
    <one-column-row>
      <v-card tile :loading="isLoading">
        <v-card-title>
          MRA Members List
        </v-card-title>
        <v-card-text>
          <SAPSMembers model-name="profile"/>
        </v-card-text>
      </v-card>
    </one-column-row>
  </v-container>
</template>

<script>
import OneColumnRow from "@/layouts/components/OneColumnRow";
import SAPSMembers from "@/views/saps/SAPSMembers";

export default {
  name: "SAPSHome",
  components: {
    OneColumnRow,
    SAPSMembers
  },
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
    isLoading() {
      return this.$store.getters["profile/loading"]("list");
    }
  },
  methods: {
    editProfile() {
      this.$router.push("/profile");
    }
  }
};
</script>
