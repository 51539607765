<template>
  <responsive-dialog ref="responsive_dialog" :dialog-title="dialogTitle" :show-actions="false"
                     @closed="closeCommentForm && $emit('closed');">
    <template v-slot:app-bar-left v-if="getExampleDoc(documentMedia.description)">
      <v-btn small text @click="$refs.lightbox.open(getExampleDoc(documentMedia.description))">
        <v-icon left>mdi-help-circle-outline</v-icon>
        Example
      </v-btn>
      <light-box ref="lightbox" max-height="500px"/>
    </template>
    <v-card-text>
      <v-progress-linear
          v-if="uploadPercentage > 0 || loading"
          :value="uploadPercentage"
          :indeterminate="uploadPercentage === 100 || loading"
      ></v-progress-linear>
      <v-alert
          v-if="documentList"
          border="left"
          colored-border
          color="info"
          elevation="2"
      >
        <b>The following document substantiations should be uploaded here:</b> {{ documentList }}
      </v-alert>

      <media-field @updated="onMediaUpdate" @uploadPercentage="onUploadPercentage">
        <media-drop-zone class="" style="min-height: 200px;" v-if="!readOnly">

          <v-icon x-large>mdi-cloud-upload</v-icon>
          <p class="caption">Click here or drag a file to upload</p>

        </media-drop-zone>
        <v-list dense>
          <v-list-item v-if="loading">
            <v-progress-linear :value="uploadPercentage" :indeterminate="uploadPercentage === 100"></v-progress-linear>
          </v-list-item>
          <v-list-item v-for="item in documentMedia.attachments" @click.stop="openImage(item.file)" :key="item.id"
                       target="_blank">
            <v-list-item-avatar tile>
              <v-img v-if="isImageFile(item.filename)" size="50" :src="item.file">
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular width="3" indeterminate color="primary"></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
              <v-icon v-else large>{{ getMediaIcon(item.filename) }}</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title> {{ item.filename }}</v-list-item-title>
              <v-list-item-subtitle> {{ item.size / 1000 }} KB</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action @click.stop>
              <v-btn v-if="!readOnly" @click.stop="remove(item)" text title="Remove Document/Image">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-list-item-action>


          </v-list-item>
        </v-list>
      </media-field>

      <document-status :comments="documentComments" @add="openCommentForm" @deleted="deleteComment">
        <template v-if="showCommentForm">
          <v-textarea
              v-model="documentComment.comment"
              filled shaped
              :label="documentCommentPlaceholder"
          />
          <div class="mb-3" v-if="documentComment.status === 'rejected'">
            <small>
              <v-icon left color="primary">mdi-alert-circle</v-icon>
              PLEASE NOTE: If you reject an upload this inspection will be automatically re-opened and the reason will
              be
              emailed to the member
            </small>
          </div>
          <v-btn class="mr-5" color="info" outlined small @click.prevent="closeCommentForm">Cancel</v-btn>
          <v-btn :loading="commentLoading" outlined small @click.prevent="addComment" color="primary">Save</v-btn>
        </template>
      </document-status>
    </v-card-text>
    <v-card-actions>
      <!--      <v-btn v-if="documentMedia.media.file" color="error" @click.prevent="remove()" text>-->
      <!--        <v-icon left>mdi-delete</v-icon>-->
      <!--        Remove file-->
      <!--      </v-btn>-->
      <template v-if="canSetApprovalStatus">
        <v-btn v-if="documentMedia.status !== 'rejected'" color="error" @click.prevent="openRejectComment" text>
          Reject
        </v-btn>
        <v-btn v-if="documentMedia.status === 'rejected'" color="info" @click.prevent="openApproveComment" text
               :loading="commentLoading">
          Approve
        </v-btn>
      </template>
      <a v-if="!readOnly" color="primary" @click.prevent="$refs.media_popup.open()">
        OR Choose a document from your shared documents
      </a>
      <v-spacer/>
      <v-btn color="primary" v-if="documentMedia.attachments && documentMedia.attachments.length > 0" text
             @click="close">
        Continue
      </v-btn>
    </v-card-actions>
    <media-popup ref="media_popup" is-submission model-name="media" @selected="onMediaUpdate"/>
  </responsive-dialog>
</template>

<script>
import {ADD_MESSAGE} from "@/store/mutation-types";
import MediaDropZone from "@/components/media/MediaDropZone.vue";
import MediaPopup from "@/components/media/MediaPopup.vue";
import MediaField from "@/components/media/MediaField.vue";
import eventHub from "@/event-hub";
import downloadMixin from "@/mixins/download";
import ResponsiveDialog from "@/components/ResponsiveDialog";
import mediaMixin from "@/mixins/media";
import listMixin from "@/mixins/list";
import exampleDocumentsMixin from "@/mixins/exampleDocuments";
import LightBox from "@/components/LightBox";
import DocumentStatus from "@/components/document/DocumentStatus";
import {updateListItem} from "@/utils/list";

export default {
  name: "DocumentMedia",

  components: {DocumentStatus, LightBox, ResponsiveDialog, MediaPopup, MediaField, MediaDropZone},

  mixins: [downloadMixin, mediaMixin, listMixin, exampleDocumentsMixin],

  props: {submission: {type: Object, required: true}, readOnly: {type: Boolean, default: false}},

  data() {
    return {
      loading: false,
      idDeleteItem: -1,
      deleteConfirmOpen: false,
      documentMedia: {
        file: null
      },
      formObject: null,
      uploadPercentage: 0,
      documentList: null,
      documentComment: {
        comment: ""
      },
      documentComments: [],
      showCommentForm: false,
      commentLoading: false,
      documentCommentUrl: "/inspection/document-status/",
      documentCommentPlaceholder: "Comments"
    };
  },

  computed: {
    url() {
      return `inspection/submissions/${this.submission.id}/documents/`;
    },
    user() {
      return this.$store.state.auth.user;
    },
    dialogTitle() {
      return `${this.documentMedia.description}`;
    },
    canSetApprovalStatus() {
      return this.user.is_staff && this.submission && this.submission.status !== "complete" && !this.showCommentForm;
    }
  },

  methods: {

    resetCommentForm() {
      this.documentCommentPlaceholder = "Comments";
      this.documentComment.status = "submitted";
      this.documentComment.comment = "";
    },

    openRejectComment() {
      this.documentComment.status = "rejected";
      this.documentCommentPlaceholder = "Reject Reasons";
      this.openCommentForm();
    },

    openApproveComment() {
      this.documentComment.status = "approved";
      this.addComment();
    },

    async addComment() {
      try {
        this.commentLoading = true;
        const response = await this.$http.post(this.documentCommentUrl, this.documentComment);
        this.documentComments = updateListItem(this.documentComments, response.data);
        this.$store.commit(`theme/${ADD_MESSAGE}`, {text: "Comment saved", type: "success"});

        if (["approved", "rejected"].includes(response.data.status)) {
          this.documentMedia.status = response.data.status;
          eventHub.$emit("DocumentSheet.updated");
        }

        this.closeCommentForm();

      } catch (_) {
        this.$store.commit(`theme/${ADD_MESSAGE}`, {text: "Unable to add a comment", type: "error"});
      } finally {
        this.commentLoading = false;
      }
    },

    async deleteComment(commentId) {
      try {
        this.commentLoading = true;
        await this.$http.delete(`${this.documentCommentUrl}${commentId}/`);
        this.deleteListItem(this.documentComments, {id: commentId});
      } catch {
        this.$store.commit(`theme/${ADD_MESSAGE}`, {text: "Unable to delete this comment", type: "error"});
      } finally {
        this.commentLoading = false;
      }
    },

    onClickDeleteButton(item) {
      this.deleteConfirmOpen = true;
      if (typeof item.id == "number") {
        this.idDeleteItem = item.id;
      }
    },

    onClickDownloadButton(item) {
      //TODO allow cross origin headers on cloudFront
      this.downloadResource(item.media.file || "", item.media.filename || "");
    },

    remove(item) {

      eventHub.$emit("DocumentMedia.loading", true);
      this.deleteListItem(this.documentMedia.attachments, item);

      this.$http.patch(this.url, [
        this.documentMedia
      ]).then(() => {

        eventHub.$emit("DocumentMedia.updated", this.documentMedia);

      }).catch(() => {

        const errorMessage = "Unable to remove image";
        eventHub.$emit("DocumentMedia.error", errorMessage);
        this.$store.commit(`theme/${ADD_MESSAGE}`, {type: "error", text: errorMessage});

      }).finally(() => {

        eventHub.$emit("DocumentMedia.loading", false);

      });
    },

    async fetchComments() {
      const response = await this.$http.get(`${this.documentCommentUrl}?submission=${this.submission.id}&document_required=${this.documentMedia.id}`);
      this.documentComments = response.data.results;
    },

    openCommentForm() {
      this.showCommentForm = true;
    },

    closeCommentForm() {
      this.showCommentForm = false;
      this.resetCommentForm();
    },

    async onMediaUpdate(item) {
      eventHub.$emit("DocumentMedia.loading", true);
      this.uploadPercentage = 0;

      this.addListItem(this.documentMedia.attachments, item);

      const data = {
        attachments: this.documentMedia.attachments,
        id: this.documentMedia.id,
        question_number: this.documentMedia.question_number
      };

      try {

        await this.$http.patch(this.url, [data]);
        this.documentMedia.media = item;
        eventHub.$emit("DocumentMedia.updated", this.documentMedia);
        this.$refs.media_popup.close();

      } catch (error) {
        const errorMessage = "Unable to upload the file at this time.";
        this.$store.commit(`theme/${ADD_MESSAGE}`, {text: errorMessage, type: "error"});

      } finally {

        eventHub.$emit("DocumentMedia.loading", false);

      }
    },

    onUploadPercentage(value) {
      this.uploadPercentage = value;
    },

    onClickDropArea() {
      eventHub.$emit("clickMediaUpload");
    },

    showSnackbarSuccess() {
      this.$store.commit(`theme/${ADD_MESSAGE}`, {type: "success", text: "Success"});
    },

    async open(documentMedia) {
      this.documentMedia = documentMedia;
      this.documentList = this.parseWasteActComplianceDocs({...documentMedia});
      await this.fetchComments();
      this.documentComment.submission = this.submission.id;
      this.documentComment.document_required = documentMedia.id;
      this.$refs.responsive_dialog.open();
    },

    openImage(url) {
      window.open(url);
    },

    close() {
      this.$refs.responsive_dialog.close();
      this.documentComment = {comment: ""};
      this.documentMedia = {file: null};
      this.closeCommentForm();
      this.$emit("closed");
    },

    parseWasteActComplianceDocs(item) {
      // TODO: This is a hack.
      const wasteActComplianceLabels = {
        sawis_registration: "SAWIC/SAWIS Registration",
        nemwa_storage_norms_and_standards: "NEMWA storage Norms & Standards",
        nemwa_processing_scrap: "NEMWA scrap processing Norms & Standards",
        nemwa_scrapping_of_motor_vehicles: "NEMWA scrapping of motor vehicles"
      };

      if (item.question_number === 4) {
        const documents = item.answer.split(",");
        return documents.reduce((accumulator, currentValue) => {
          const complianceLabel = wasteActComplianceLabels[currentValue] || currentValue || "";
          return accumulator + `${complianceLabel}, `;
        }, "");
      }

      if (item.question_number === 37) {
        return item.answer;
      }

      return null;
    }
  }

};
</script>

<style scoped></style>
