import {getState, getActions, getMutations, getGetters} from "@admin100/djvue";

const actions = getActions("inspection/proofs-of-payment");
const state = getState();
const getters = getGetters();
const mutations = getMutations();

const proofOfPayment = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};

export default proofOfPayment;
