<template>
  <v-container>
    <!-- SELECT REPORT RANGE -->
    <template v-if="isNew">
      <v-container>
        <v-card>
          <v-card-title>Select Period</v-card-title>
          <v-card-subtitle
            >Specify the start and the end date for the period.</v-card-subtitle
          >
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-menu
                  ref="start_date_menu"
                  v-model="start_date_menu"
                  :close-on-content-click="false"
                  :return-value.sync="start_date"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="start_date"
                      label="Start Date"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="start_date" no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="start_date_menu = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.start_date_menu.save(start_date)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="12" sm="6" md="4">
                <v-menu
                  ref="end_date_menu"
                  v-model="end_date_menu"
                  :close-on-content-click="false"
                  :return-value.sync="end_date"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="end_date"
                      label="End Date"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="end_date" no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="end_date_menu = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.end_date_menu.save(end_date)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="12" sm="6" md="4">
                <v-btn
                  class="mt-3"
                  color="info"
                  depressed
                  :loading="isLoading"
                  @click="createItem"
                  >Generate Report</v-btn
                >
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-container>
    </template>

    <template v-else>
      <!-- REPORT SUMMARY -->
      <one-column-row>
        <v-card tile>
          <v-card-title>
            Report Reference #{{ item.reference_no }}
            <v-spacer />
          </v-card-title>
          <v-card-subtitle>
            Period: {{ item.title }}
          </v-card-subtitle>
          <v-card-text>
            <!-- SUMMARY TABLE -->
            <v-simple-table v-if="item.data">
              <tbody>
                <tr>
                  <td><b>Virtual Inspections Conducted</b></td>
                  <td>{{ item.data.virtual_inspections_due }}</td>
                </tr>

                <tr>
                  <td><b>Compliant members</b></td>
                  <td>{{ item.data.compliant_members }}</td>
                </tr>

                <tr>
                  <td><b>Non-Compliant members</b></td>
                  <td>{{ item.data.non_compliant_members }}</td>
                </tr>

                <tr>
                  <td><b>New Applications Received</b></td>
                  <td>0</td>
                </tr>

                <tr>
                  <td><b>New Applications Approved</b></td>
                  <td>{{ item.data.applications_approved }}</td>
                </tr>

                <tr>
                  <td><b>Members Disciplined</b></td>
                  <td>{{ item.data.members_disciplined }}</td>
                </tr>

                <tr>
                  <td><b>Members De-Registered</b></td>
                  <td>{{ item.data.members_deregistered }}</td>
                </tr>

                <tr>
                  <td><b>Members updated (604 Changed Notifications)</b></td>
                  <td>{{ item.data.members_updated }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="info" text xs :to="{ name: 'AdminSapsReportIndex' }">
              <v-icon left>mdi-send</v-icon>
              Submit Report
            </v-btn>
          </v-card-actions>
        </v-card>
      </one-column-row>

      <!-- SUPPORTING DOCUMENTATION -->
      <one-column-row>
        <v-card tile>
          <v-card-title>
            Supporting Documentation
            <v-spacer />
            <v-btn @click="$refs.media_popup.open()" text title="Upload">
              <v-icon left>mdi-upload</v-icon>
              Upload
            </v-btn>
          </v-card-title>
          <v-divider />
          <v-card-text>
            <v-simple-table>
              <tbody>
                <tr v-for="document in documents" :key="document.id">
                  <td>
                    <b>{{ document.filename }}</b>
                  </td>
                  <td>
                    <v-btn
                      :href="document.file"
                      text
                      title="Download"
                    >
                      <v-icon left>mdi-download</v-icon>
                      Download
                    </v-btn>
                  </td>
                  <td>
                    <v-btn v-if="document.visible" text color="error" @click.prevent="deleteMedia(document)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </td>

                </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </one-column-row>

      <media-popup
        ref="media_popup"
        is-submission
        model-name="media"
        @selected="addMedia"
      />
    </template>
  </v-container>
</template>

<script>
import OneColumnRow from "@/layouts/components/OneColumnRow";
import MediaPopup from "../../components/media/MediaPopup";
import {ADD_MESSAGE} from "../../store/mutation-types";
import listMixin from "../../mixins/list";

export default {
  name: "SapsReportGenerate",
  components: {
    OneColumnRow,
    MediaPopup
  },

  mixins: [listMixin],

  data() {
    return {
      start_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      start_date_menu: false,
      end_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      end_date_menu: false,
      documents: [],
      formErrors:{},
      loading: false
    };
  },

  mounted() {
    if (this.reportID){
      this.fetchItem();
    }
  },

  computed: {
    isNew() {
      return !this.reportID;
    },
    reportID() {
      return this.$route.params.id;
    },
    item(){
      return this.$store.state.report.detail || {};
    },
    attachmentList(){
      return [...this.documents].reduce((previousValue, currentValue) => {
        previousValue.push(currentValue.id);
        return previousValue;
      }, []);
    },
    isLoading(){
      return this.$store.getters["report/loading"]("create") || this.loading;
    }
  },

  methods: {

    async addMedia(item) {
      this.addListItem(this.documents, item);
      try {
        await this.saveMedia();
      } catch (error) {
        this.deleteListItem(this.documents, item);
        this.formErrors = error.response.data;
        this.$store.commit(`theme/${ADD_MESSAGE}`, {type: "error", text: "Unable to upload document."});
      } finally {
        this.$refs.media_popup.close();
      }
    },

    async saveMedia(){
      const response = await this.$store.dispatch("report/update", {id: this.reportID, data: {attachments: this.attachmentList}});
      this.documents = response.data.attachments;
    },

    async fetchItem(){
      await this.$store.dispatch("report/detail", this.reportID);
      this.documents = this.item.attachments;
    },

    async createItem() {
      this.loading = true;
      this.errors = [];

      try {
        const response = await this.$store.dispatch("report/create", {
          "start_date": this.start_date,
          "end_date": this.end_date
        });
        await this.$router.push(`/admin/saps-reports/generate/${response.data.id}`);
        this.documents = this.item.attachments;

      } catch (error) {
        this.formErrors = error.response.data;
        console.log(error.response.data);
      }

    },

    async deleteMedia(item){
      this.deleteListItem(this.documents, item);
      try {
        await this.saveMedia();
      } catch (error) {
        this.addListItem(this.documents, item);
        this.formErrors = error.response.data;
        this.$store.commit(`theme/${ADD_MESSAGE}`, {type: "error", text: "Unable to delete document."});
      }

    }

  }
};
</script>
