<template>
  <base-layout>
    <slot name="menu-drawer">
      <main-menu></main-menu>
    </slot>
    <v-app-bar app flat>

      <v-app-bar-nav-icon @click="toggleDrawer"></v-app-bar-nav-icon>

      <v-toolbar-title>{{ pageTitle }}</v-toolbar-title>

      <div class="flex-grow-1"></div>
      <slot name="app-bar-right"></slot>

      <v-btn icon @click="logoutDialogue = !logoutDialogue">
        <v-icon>mdi-power</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main id="main-canvas">
      <router-view/>
    </v-main>


    <!-- LOGOUT DIALOG -->
    <v-dialog v-model="logoutDialogue" max-width="290">
      <v-card>
        <v-card-title class="headline">Sign Out?</v-card-title>

        <v-card-text>
          Are you sure you want to Sign Out?
        </v-card-text>

        <v-card-actions>
          <div class="flex-grow-1"></div>

          <v-btn color="error" text @click="logoutDialogue = false">
            CANCEL
          </v-btn>

          <v-btn color="success" text @click="logout()">
            <v-icon>mdi-power</v-icon>
            YES
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </base-layout>
</template>

<script>
import Vue from "vue";
import MainMenu from "@/components/MainMenu.vue";
import BaseLayout from "./BaseLayout.vue";
import eventHub from "@/event-hub";

export default Vue.extend({

  components: {BaseLayout, MainMenu},

  props: {
    viewTitle: {type: String, default: ""}
  },

  data: () => ({
    logoutDialogue: false
  }),

  computed: {
    loading() {
      return this.$store.state.theme.loading;
    },
    appName() {
      return process.env.VUE_APP_NAME;
    },
    pageTitle() {
      return this.$store.state.theme.pageTitle;
    }
  },
  methods: {
    logout() {
      this.logoutDialogue = false;
      this.$store.dispatch("auth/logout");
    },
    toggleDrawer() {
      eventHub.$emit("toggle_drawer");
    }
  }
});
</script>

<style scoped>
#main-canvas {
  background-color: #f1f1f1;
}
</style>
