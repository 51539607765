<template>
  <v-simple-table v-if="item.profile">
    <tbody>
    <tr>
      <td><b>Member No</b></td>
      <td>{{ item.profile.member_no }}</td>
    </tr>

    <tr>
      <td><b>Company Name</b></td>
      <td>{{ item.profile.company }}</td>
    </tr>

    <tr>
      <td><b>Contact person</b></td>
      <td>{{ item.profile.contact_name }}</td>
    </tr>

    <tr>
      <td><b>Contact number</b></td>
      <td>{{ item.profile.telephone_number }}</td>
    </tr>

    <tr>
      <td><b>Email</b></td>
      <td><a :href="`mailto:${email}`">{{ email }}</a></td>
    </tr>
    </tbody>
  </v-simple-table>
</template>

<script>
export default {
  name: "ProfileTable",
  props: {profile: {type: Object, default:null, required: false}},
  computed: {
    item() {
      return this.profile ? {profile: this.profile} : this.$store.state.auth.user;
    },

    email(){
      return this.item.email || this.item.profile.email;
    }
  }
};
</script>

<style scoped>

</style>