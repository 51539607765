import {format, formatDistance, isPast, parseISO} from "date-fns";

export default {
    methods: {
        getDateDistance(date) {
            const parsedDate = parseISO(date);
            let dateDistance = formatDistance(parsedDate, new Date());
            if (isPast(parsedDate)) {
                dateDistance += " ago";
            }
            return dateDistance;
        },
        formatDate(date) {
            return date ? format(parseISO(date), "dd MMM, yyyy") : "";
        },
        toDjangoDateField(date) {
            return format(date, "yyyy-MM-dd");
        }
    }
};
