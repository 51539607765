<template>
  <v-container>
    <one-column-row>
      <v-card tile>
        <v-card-title>
          Annual SAPS Reports
        </v-card-title>
        <v-card-text>
          <v-data-table
            v-model="selected"
            class="pa-2"
            :headers="headers"
            :items="items"
            :loading="isLoading"
            :footer-props="{ 'items-per-page-options': itemsPerPageOptions }"
            :options.sync="tableFilters"
            @update:options="updateFilters"
            :server-items-length="totalItems"
            disable-filtering
            disable-sort
            item-key="id"
          >
            <!-- REFERENCE NO -->
            <template v-slot:item.reference_no="{ item }">
              <router-link :to="`${routePrefix}/saps-reports/generate/${item.id}`">
                {{ item.reference_no }}
              </router-link>
            </template>

            <!-- STATUS -->
            <template v-slot:item.status="{ item }">
              <submission-status-badge :status="item.status" :show-status-color="true"/>
            </template>

            <!-- DATE -->
            <template v-slot:item.created_at="{ item }">
              {{ formatDate(item.created_at)}}
            </template>

            <!-- ACTIONS -->
            <template v-slot:item.action="{ item }">
            <v-menu bottom left>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on" @click.native.stop>
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
            <!-- STAFF OPTIONS -->
              <v-list-item link @click.prevent="deleteItem(item)">
                <v-list-item-title>
                  <v-icon left>mdi-delete</v-icon>
                  Delete
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </one-column-row>
  </v-container>
</template>

<script>
import OneColumnRow from "@/layouts/components/OneColumnRow";
import dataTableMixin from "@/mixins/dataTable";
import routePrefixMixin from "../../mixins/routePrefix";
import SubmissionStatusBadge from "../submission/SubmissionStatusBadge";
import {ADD_MESSAGE, SET_LIST} from "../../store/mutation-types";
import listMixin from "../../mixins/list";
import date from "@/mixins/date";

export default {
  name: "DisciplinaryLogList",
  mixins: [dataTableMixin, routePrefixMixin, listMixin, date],
  components: { OneColumnRow, SubmissionStatusBadge },
  data() {
    return {
      headers: [
        { text: "REF", value: "reference_no", sortable: false },
        { text: "Association", value: "association", sortable: false },
        { text: "Period", value: "title", sortable: false },
        { text: "Created by", value: "created_by", sortable: false },
        { text: "Date", value: "created_at", sortable: true },
        { text: "Status", value: "status", sortable: true },
        { text: "", value: "action", sortable: false },
      ],
      loading: false
    };
  },
  mounted() {
    this.fetchItems();
  },
  computed: {
    items() {
      return this.$store.state.report.list;
    },
    isLoading() {
      return this.$store.getters["report/loading"]("list") || this.$store.getters["report/loading"]("delete") || this.loading;
    }
  },
  methods: {
    async fetchItems() {
      try {
        await this.$store.dispatch("report/list");
      } catch (e) {
        this.$store.commit(`theme/${ADD_MESSAGE}`, {
          text: "Unable to load reports",
          type: "error"
        });
      }
    },

    viewItemDetails(item) {
      this.$router.push({
        name: "SapsReportGenerate",
        params: { id: item.id }
      });
    },

    deleteItem(item) {
      this.$store.dispatch("report/delete", item.id).then(() => {
        const newList = [...this.$store.state.report.list];
        this.deleteListItem(newList, item);
        this.$store.commit(`report/${SET_LIST}`, newList);
        this.$store.commit(`theme/${ADD_MESSAGE}`, {text: "Report deleted", type: "success"});
      }).catch(() => {
        this.$store.commit(`theme/${ADD_MESSAGE}`, {text: "Report was not deleted", type: "error"});
      });
    }
  }
};
</script>
