<template>
  <v-alert
      v-if="formLocked"
      border="left"
      color="primary"
      type="info"
      outlined
  >
    <span class="text-sm-body-2">{{ this.lockedMessage }} </span>
  </v-alert>
</template>

<script>
export default {
  name: "SubmissionLockedAlert",

  props: {locked: {type: Boolean, default: false}},

  computed: {
    user() {
      return this.$store.state.auth.user;
    },
    formLocked() {
      return this.locked || this.user.is_staff || this.user.is_saps;
    },
    lockedMessage() {
      if (this.user.is_staff || this.user.is_saps) {
        return "Only the member may edit this submission. If changes need to be made please inform the member and re-open the inspection if needed.";
      }
      return "This submission is currently locked. You need to request that an administrator re-open this inspection should you wish to edit it.";
    }
  }
};
</script>

<style scoped>

</style>