<template>
  <v-card tile>
    <v-alert
        v-if="form.locked"
        border="left"
        color="primary"
        type="info"
        outlined
    >
      {{ lockedMessage }}
    </v-alert>
    <v-card-title>
      MEMBERSHIP FEES
    </v-card-title>
    <v-card-text>
      <p>
        I hereby affirm that there are no membership fees outstanding for <b>{{ submission.company }}</b>
      </p>
      <v-checkbox :disabled="readOnly" label="Member fees up to date" v-model="form.fees_up_to_date" @change="updateItem"></v-checkbox>
      <proof-of-payment :submission-id="submission.id" :read-only="!user.is_staff"/>
    </v-card-text>
    <v-card-title>
      FINAL APPROVAL
    </v-card-title>
    <v-card-text>
      <p>
        I hereby approve the completeness of this application with regards to all,
        aspects of the Act.
      </p>
      <v-row>
        <v-col cols="3">
          <v-checkbox label="Approved" v-model="form.approved" @change="updateItem" :disabled="readOnly"></v-checkbox>
        </v-col>
        <v-col cols="3">
          <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="true"
              transition="scale-transition"
              offset-y
              min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-model="form.date"
                  label="Date Approved"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :disabled="readOnly"
              ></v-text-field>
            </template>
            <v-date-picker
                v-model="form.date"
                no-title
                scrollable
                @change="updateItem"
                :disabled="readOnly"
            >
              <v-spacer></v-spacer>
              <v-btn
                  text
                  color="primary"
                  @click="menu = false"
              >
                Cancel
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <p class="text-muted text-sm-body-1">
        <i>Authorised Signatory of the MRA</i>
      </p>
    </v-card-text>
  </v-card>

</template>

<script>

import {ADD_MESSAGE} from "@/store/mutation-types";
import eventHub from "@/event-hub";
import ProofOfPayment from "@/components/ProofOfPayment";
import dateMixin from "@/mixins/date/";

export default {
  name: "OfficeUseSheet",
  components: {ProofOfPayment},
  props: {submission: {type: Object, required: true}},
  mixins: [dateMixin],

  data() {
    return {
      menu: false,
      loading: false,
      form: {},
      errors: {},
      yesOrNo: [
        {text: "Yes", value: true, id: 1},
        {text: "No", value: false, id: 2}
      ]
    };
  },

  computed: {
    url() {
      return `/inspection/submissions/${this.submission.id}/office/`;
    },
    user() {
      return this.$store.state.auth.user;
    },
    readOnly(){
      return this.form.locked || this.loading || !this.user.is_staff;
    },
    lockedMessage() {
      if (this.submission.status === "complete"){
        return "The office use section has been locked because this submission is now complete.";
      } else {
        return "The office use section has been locked until the user submits the inspection.";
      }
    }
  },

  created() {
    eventHub.$on("Submission.save_office_use", this.updateItem);
  },
  beforeDestroy() {
    eventHub.$off("Submission.save_office_use");
  },

  mounted() {
    this.fetchItem();
  },

  methods: {
    fetchItem() {
      this.loading = true;
      this.$http.get(this.url).then((response) => {
        this.form = response.data;
      }).catch(() => {
        this.$store.commit(`theme/${ADD_MESSAGE}`, {type: "error", text: "Unable open 'Office Use' section"});
      }).finally(() => {
        this.loading = false;
        this.prePopulateForm();
      });
    },

    async updateItem() {
      this.loading = true;
      try {
        const response = await this.$http.patch(this.url, this.form);
        this.form = response.data;
        this.errors = [];
        eventHub.$emit("OfficeUseSheet.updated", response.data);
        this.$store.commit(`theme/${ADD_MESSAGE}`, {type: "success", text: "Office use section saved."});
      } catch (error) {
        this.errors = error.response.data;
        this.$store.commit(`theme/${ADD_MESSAGE}`, {type: "error", text: "Update failed"});
      } finally {
        this.loading = false;
      }
    },
    prePopulateForm(){
      if (!this.form.date){
        this.form.date = this.toDjangoDateField(new Date());
      }
    }
  },
};
</script>