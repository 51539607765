<template>
  <v-card class="elevation-12" :loading="sendingRequest">
    <template slot="progress">
      <v-progress-linear
          color="primary"
          height="10"
          indeterminate
      ></v-progress-linear>
    </template>
    <v-card-title>
      Reset password
    </v-card-title>
    <div class="pa-4">
      <v-alert v-if="formErrors.token" type="error">
        <li v-for="(errorMessage, index) in formErrors.token" :key="index">
          {{ errorMessage }}
        </li>
      </v-alert>
      <v-card-text>
        <v-text-field
            v-model="form.password"
            prepend-icon="mdi-lock"
            name="password"
            label="New Password"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword ? 'text' : 'password'"
            :error-messages="formErrors.password"
            :error-count="5"
            @click:append="showPassword = !showPassword"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="primary"
            :disabled="sendingRequest"
            large
            @click.stop="onClickResetPasswordButton"
        >Reset Password
        </v-btn>
      </v-card-actions>
    </div>
  </v-card>
</template>

<script>
import axios from "axios";
import {ADD_MESSAGE} from "@/store/mutation-types";

export default {
  name: "ResetPasswordConfirm",

  data() {
    return {
      showPassword: false,
      sendingRequest: false,
      token: this.$route.query.token,
      form: {
        password: ""
      },
      formErrors: {}
    };
  },
  computed: {},
  methods: {
    /*** Event handlers ***/
    onClickResetPasswordButton() {
      this.sendResetPasswordConfirm();
    },

    async sendResetPasswordConfirm() {
      this.sendingRequest = true;
      const payload = {
        token: this.token,
        password: this.form.password
      };
      try {
        await axios.post(process.env.VUE_APP_API_BASE_URL + "/account/users/reset-password-confirm/", payload);
        await this.$router.push("/login");
        this.$store.commit(`theme/${ADD_MESSAGE}`, {type: "success", text: "Your password has now been reset."});
      } catch (error) {
        this.formErrors = error.response.data;
      } finally {
        this.sendingRequest = false;
      }
    }
  }
};
</script>

<style scoped></style>
