<template elevation="0">
  <v-list class="justify-start align-content-start">
    <v-list-item>
      <v-list-item-content>
        <v-form @submit.prevent="">
          <v-textarea v-model="form.message" label="Message" placeholder="" aria-required="true"
                        :error-messages="errors.message" append-outer-icon="mdi-send" @click:append-outer="createItem"
                        :loading="loading" filled shaped></v-textarea>
        </v-form>
      </v-list-item-content>
    </v-list-item>
    <v-list-item v-for="item in items" :key="item.id" >
      <v-list-item-icon>
        <v-icon>mdi-email</v-icon>
      </v-list-item-icon>
      <v-list-item-content>

        <v-list-item-title>
          {{ item.sent_by }}
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ getDateDistance(item.created_at) }}
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-content>
        {{ item.message }}
      </v-list-item-content>
      <v-list-item-action>
        <v-btn text color="error" v-if="user.id === item.created_by" @click="deleteItem(item)" :disabled="loading">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
  </v-list>
</template>

<script>
import {updateListItem} from "@/utils/list";
import {ADD_MESSAGE} from "@/store/mutation-types";
import eventHub from "@/event-hub";
import date from "@/mixins/date";

const getForm = () => {
  return {
    message: null
  };
};

export default {
  name: "MessageList",

  mixins: [date],

  data() {
    return {
      items: [],
      loading: false,
      form: getForm(),
      errors: {}
    };
  },

  computed: {
    url() {
      return this.submissionId ? `/inspection/submissions/${this.submissionId}/messages/` : "inspection/messages/";
    },
    user() {
      return this.$store.state.auth.user;
    },
    submissionId() {
      return Number(this.$route.params.submission_id) || null;
    }
  },

  mounted() {
    this.fetchItems();
  },

  methods: {

    fetchItems() {
      this.loading = true;
      this.$http.get(this.url).then((response) => {
        this.items = this.submissionId ? response.data : response.data.results;
        eventHub.$emit("MessageList.updated", this.items);
      }).catch(() => {
        this.$store.commit(`theme/${ADD_MESSAGE}`, {text: "Unable to get messages", type: "error"});
      }).finally(() => {
        this.loading = false;
      });
    },

    deleteItem(item) {
      this.loading = true;
      this.$http.delete(`inspection/messages/${item.id}/`).then(() => {
        this.fetchItems();
        this.$store.commit(`theme/${ADD_MESSAGE}`, {text: "Message Deleted", type: "success"});
        eventHub.$emit("MessageList.updated", this.items);
      }).catch(() => {
        this.$store.commit(`theme/${ADD_MESSAGE}`, {text: "Unable to delete message", type: "error"});
      }).finally(() => {
        this.loading = false;
      });
    },

    createItem() {
      this.loading = true;
      this.errors = [];

      this.$http.post(this.url, this.form).then((response) => {
        this.items = updateListItem(this.items, response.data);
        this.form = getForm();
        this.$store.commit(`theme/${ADD_MESSAGE}`, {text: "Message sent", type: "success"});
        eventHub.$emit("MessageList.updated", this.items);
      }).catch((error) => {
        this.errors = error.response.data;
        this.$store.commit(`theme/${ADD_MESSAGE}`, {text: "Unable to get messages", type: "error"});
      }).finally(() => {
        this.loading = false;
      });
    }

  }
};
</script>

<style scoped>

</style>
