<template>
  <v-menu
      v-if="isVisible"
      rounded="0"
      offset-y
  >
    <template v-slot:activator="{ attrs, on }">
      <v-btn
          text
          v-bind="attrs"
          v-on="on"
          :loading="loading"
      >
        Switch Branch
      </v-btn>
    </template>

    <v-list>
      <v-list-item
          v-for="item in items"
          :key="item.id"
          :href="`${baseUrl}/account/switch/${item.id}/`"
          link
      >
        <v-list-item-title>
          {{ getItemName(item) }}
          <v-list-item-subtitle v-if="getIsMainBranch(item)">
            Main Branch
          </v-list-item-subtitle>
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: "SwitchAccount",

  mounted() {
    if (!this.items) {
      this.loading = true;
      this.$store.dispatch("account/getUserAccounts").finally(() => {
        this.loading = false;
      });
    }
  },

  data() {
    return {
      loading: false,
    };
  },
  computed: {
    items() {
      return this.$store.state.account.accounts;
    },
    baseUrl() {
      return process.env.NODE_ENV !== "production" ? process.env.VUE_APP_DEV_SERVER_PROXY + process.env.VUE_APP_API_BASE_URL : process.env.VUE_APP_API_BASE_URL;
    },
    isVisible() {
      return this.items && this.items.length > 0;
    }
  },

  methods: {
    getItemName(item) {
      return item.profile ? item.profile.company || item.email : item.email;
    },
    getIsMainBranch(item) {
      return item.profile ? item.profile.is_main_branch : false;
    }
  }
};
</script>

<style scoped>

</style>