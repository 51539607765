<template>
  <v-container>

      <v-card>
        <v-alert
            outlined
            border="left"
            color="primary"
            type="info"
            class="mt-3"
        >
          Please keep your information up to date. You must inform the MRA of any changes to your information.
          Some changes also require that you inform your local DSO. <router-link :to="{name: 'ChangeNotificationIndex'}">Update my details</router-link>
        </v-alert>
      </v-card>

    <one-column-row>
      <v-card tile>
        <profile-form ref="profile_form"/>
      </v-card>
    </one-column-row>
  </v-container>
</template>

<script>
import OneColumnRow from "@/layouts/components/OneColumnRow";
import ProfileForm from "@/components/profile/ProfileForm";


export default {
  name: "Profile",
  components: {
    ProfileForm,
    OneColumnRow
  }
};
</script>
